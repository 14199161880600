import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';
import { ReactComponent as IconPlay } from 'assets/icons/play.svg';


interface Props {
  index: string;
  name: string;
  position: string;
  url: string;
}

export function VideoReviewItem({
  index, name, position, url,
}: Props) {
  return (
    <Container>
      <PictureContainer>
        <img src={url} alt={name} />
        <HashTag>{index}</HashTag>
        <StyledIconPlay />
      </PictureContainer>
      <TextWrapper>
        <StyledHeading level={4}>{name}</StyledHeading>
        <Position>{position}</Position>
      </TextWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 15px;
  border-radius: 8px;
  background-color: var(--bg-primary);
  overflow: hidden;
  .pictureWrapper {
  }
`;

const PictureContainer = styled.div`
  position: relative;
  > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const HashTag = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: var(--brand-primary);
  padding: 2px 12px;
  border-radius: 12px;
  font-size: 16px;
  color: var(--bg-secondary);
`;

const StyledIconPlay = styled(IconPlay)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TextWrapper = styled.div`
  padding: 18px 32px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
  color: var(--text-primary);
`;

const Position = styled.div`
  color: var(--text-gray);
  line-height: 20px;
`;
