import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { store } from 'stores/store';
import colors from 'styles/colors.module.css';
import typography from 'styles/typography.module.css';
import { Activity } from 'views/Activity';
import { ActivityAffiche } from 'views/ActivityAffiche';
import { Article } from 'views/Article';
import { ArticlesArchive } from 'views/ArticlesArchive';
import { Companies } from 'views/Companies';
import { Home } from 'views/Home';
/* import { KnowledgeBase } from 'views/KnowledgeBase'; */
import { KnowledgeBase } from 'views/KnowledgeBase';
import { Support } from 'views/Support';


import 'antd/dist/antd.min.css';


function App() {
  const className = `${colors.root} ${typography['main-text']}`;


  return (
    <Provider store={store}>
      <div className={className}>
        <BrowserRouter>
          <Suspense fallback="Загрузка...">
            <Routes>
              <Route path="/forbusiness" element={<Companies />} />
              <Route path="/news/:id" element={<Article />} />
              <Route path="/news" element={<ArticlesArchive />} />
              <Route path="/activity/:id" element={<Activity />} />
              <Route path="/activity" element={<ActivityAffiche />} />
              <Route path="/support" element={<Support />} />
              <Route path="/" element={<Home />} />
              <Route index element={<Home />} />
              <Route path="/base" element={<KnowledgeBase />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
