import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import { ActivitiesBlock } from './ActivitiesBlock';
import type { ActivitiesBlockProps as Block } from './ActivitiesBlock';
import { ActivitiesBlockOpen } from './ActivitiesBlockOpen';


function ActivitiesList({ list }: { list?: Block[] }) {
  return (
    <BlockList>
      <ActivitiesBlockOpen />
      {
        Array.isArray(list) && (
          list.map((item) => (
            <ActivitiesBlock {...item} key={item.title} />
          ))
        )
      }
    </BlockList>
  );
}

const BlockList = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
  @media (min-width: 1200px) and (max-width: 1535px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  @media (min-width: 1650px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 360px));
  }
  @media (min-width: 1921px) {
    grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
  }
`;

export { ActivitiesList };
