import React from 'react';
import {
  CalendarFilled,
  ClockCircleFilled,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
import DOMPurify from 'dompurify';

import { Tag } from 'components/common/Tag';
import { CeltStar } from 'components/glyphs/shapes/CeltStar';
import { StarBig } from 'components/glyphs/shapes/StarBig';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';
import { Fg } from 'utils/glyph';
import { toHumanDate, toHumanTime } from 'utils/to-human-date';


type NearestActivitiesHeaderProps = {
  activity: IActivity;
};

export function NearestActivitiesHeader(props: NearestActivitiesHeaderProps) {
  const { activity } = props;
  const purifiedDescription = DOMPurify.sanitize(activity.short_description);

  return (
    <Link href={`/activity/${activity._id}`} target="_blank">
      <Container>
        <Banner>
          <Fg
            top="-116px"
            left="164px"
          >
            <StarBig />
          </Fg>
          <Fg
            bottom="30px"
            left="20px"
          >
            <CeltStar />
          </Fg>
          <Image>
            <img
              src={activity.desktop_cover}
              alt=""
            />
          </Image>
        </Banner>
        <Info>
          {activity.tags[0].length > 0
          && (
            <Tag
              text={activity.tags[0]}
              color="var(--status-error)"
            />
          )}
          <Title
            level=""
            desktopFont="w800 s30 h40"
            color="white"
          >
            {activity.title}
          </Title>
          <Description
            level=""
            desktopFont="w500 s18 h24"
            color="white"
          >
            {/* eslint-disable-next-line react/no-danger,@typescript-eslint/naming-convention */}
            <div dangerouslySetInnerHTML={{ __html: purifiedDescription }} />
          </Description>
          <Footer>
            <Item>
              <CalendarFilled />
              {toHumanDate(activity.start_date)}
            </Item>
            <Item>
              <ClockCircleFilled />
              {toHumanTime(activity.start_date)} - {toHumanTime(activity.end_date)}
            </Item>
            <Item>
              <EnvironmentOutlined />
              {activity.place}
            </Item>
          </Footer>
        </Info>
      </Container>
    </Link>
  );
}

const Link = styled.a`
  cursor: pointer;
`;

const Image = styled.div`
  padding: 30px 0 16px 50px;
  position: relative;
  z-index: 2;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  gap: 24px;
`;

const Banner = styled.div`
  position: relative;
`;

const Title = styled(TitleC)`
  padding: 16px 0;
`;

const Description = styled(TitleC)`
  margin-bottom: 32px;
  opacity: 0.7;
  white-space: pre-line;
`;

const Info = styled.div`
  padding: 96px 0 0 0;
`;

const Container = styled.div`
  padding: 32px;
  border-radius: 24px;
  background-color: var(--text-primary);
  color: white;
  display: flex;
  gap: 60px;
  overflow: hidden;
`;
