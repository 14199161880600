import React from 'react';
import {
  ArrowRightOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';

import {
  Article, ArticleProps,
} from 'components/articles/ArticleTopData';
import { Title as TitleC } from 'components/Title';
// import { device } from 'styles/devices';
import { media } from 'styles/devices';


type Props = {
  list: (ArticleProps & { _id: string })[]
};

export function ArticleTop({
  list,
}: Props) {
  return (
    <Container>
      <Header>
        <Title
          // color='white'
          level=""
          desktopFont="w800 s24 h30"
        >
          Главные новости
        </Title>
      </Header>
      <List>
        {
          Array.isArray(list)
          && list.map(({ date, title, _id }) => (
            <Article
              date={date}
              title={title}
              _id={_id}
              key={_id}
            />
          ))
        }
      </List>
      <Footer>
        <ArchiveLink href="/news">
          Перейти в архив новостей
        </ArchiveLink>
        <ArrowRightOutlined
          style={{ color: 'var(--brand-primary)' }}
        />
      </Footer>
    </Container>
  );
}

const Title = styled(TitleC)``;

const Header = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ArchiveLink = styled.a``;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  max-width: 480px;
  min-width: 480px;
  flex-direction: column;
  height: fit-content;
  gap: 24px;
  background-color: white;
  padding: 40px;
  border-radius: 24px;

  ${media.mobileAndTablet} {
    max-width: none;
    min-width: auto;
    margin-bottom: 30px;
  }
`;
