import React from 'react';
import { styled } from '@linaria/react';

// import { device } from 'styles/devices';

import { Dropdown, DDItem } from 'components/common/Dropdown';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';

import { NearestActivitiesGrid } from './NearestActivitiesGrid';
import { NearestActivitiesHeader } from './NearestActivitiesHeader';
import { NearestActivityNotFound } from './NearestActivityNotFound';


const items: DDItem[] = [
  { key: '0', label: 'Текущий месяц' },
  { key: '1', label: 'Январь' },
  { key: '2', label: 'Февраль' },
  { key: '3', label: 'Март' },
  { key: '4', label: 'Апрель' },
  { key: '5', label: 'Май' },
  { key: '6', label: 'Июнь' },
  { key: '7', label: 'Июль' },
  { key: '8', label: 'Август' },
  { key: '9', label: 'Сентябрь' },
  { key: '10', label: 'Октябрь' },
  { key: '11', label: 'Ноябрь' },
  { key: '12', label: 'Декабрь' },
];

type NearestActivitiesProps = {
  filteredData: IActivity[];
  selectedMonth: string;
  onMonthChange: (value: string) => void;
  initialData: IActivity[];
};

export function NearestActivities(props: NearestActivitiesProps) {
  const {
    filteredData, selectedMonth, onMonthChange, initialData,
  } = props;
  // const [selectLabel, setSelectLabel] = useState<string>(selectedMonth || 'Текущий месяц');
  
  function onSelect({ key }: { key: string }) {
    const found = items.find((i) => i.key === key) as DDItem;
    // setSelectLabel(found.label);
    onMonthChange(found.label);
  }

  return (
    <Container>
      <TitleBar>
        <Title
          level=""
          desktopFont="w800 s40 h48"
        >
          Ближайшие события
        </Title>
        <Dropdown
          label={selectedMonth || 'Текущий месяц'}
          items={items}
          onClick={(prop) => onSelect(prop)}
        />
      </TitleBar>
      {filteredData.length > 0
        ? (
          <>
            <NearestActivitiesHeader activity={filteredData[0]} />
            <NearestActivitiesGrid list={filteredData.slice(1)} />
          </>
        )
        : (
          <>
            <NearestActivityNotFound />
            <NearestActivitiesGrid list={initialData.slice(0, 6)} />
          </>
        )}
    </Container>
  );
}

const Title = styled(TitleC)``;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
