import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import { EventsBlock, EventsBlockProps } from './EventsBlock';


type Props = {
  list: Array<EventsBlockProps & { id: number }>
  initPosY?: number
};

function EventsList({ list, initPosY = 0 }: Props) {
  return (
    <List initPosition={initPosY}>
      {
        Array.isArray(list) && (
          list.map((item) => (
            <EventsBlock {...item} key={item.id} />
          ))
        )
      }
    </List>
  );
}

const List = styled.div<{ initPosition: number }>`
  position: relative;
  right: ${(props) => props.initPosition}px;
  transition: right 300ms ease-in-out;

  @media ${device.mobile}, ${device.minMobile} {
    display: flex;
    gap: 16px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column dense;
    row-gap: 24px;
    column-gap: 60px;
  }

  @media ${device.desktopS} {
    display: flex;
    gap: 32px;
  }
`;

export { EventsList };
