import React from 'react';
import { styled } from '@linaria/react';

import { Button as ButtonC } from 'components/common/Button';
import { Tag } from 'components/common/Tag';
import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';


const JOIN_LINK = 'https://auth.unionepro.ru/login';

function ViewHeader() {
  return (
    <Container>
      <Tag
        text="для образования и бизнеса"
        color="var(--el-lines)"
        textColor="secondary"
        desktopFontLevel=""
        tabletFontLevel=""
        mobileFontLevel=""
        level=""
        desktopFont="w500 s18 h24"
        tabletFont="w500 s18 h24"
        mobileFont="w500 s14 h24"
      />
      <HeaderTitle
        level=""
        desktopFont="w800 s60 h78"
        tabletFont="w800 h52"
        mobileFont="w800 s26 h32"
      >
        {`Единая образовательная
          платформа Университета
          Иннополис`}
      </HeaderTitle>
      <Button type="primary" href={JOIN_LINK}>
        <ButtonTitle
          level=""
          desktopFont="w600 s20 h24"
          tabletFont="w600 s20 h24"
          mobileFont="w500 s18 h24"
          color="white"
        >
          Присоединиться
        </ButtonTitle>
      </Button>
    </Container>
  );
}

const HeaderTitle = styled(TitleC)`
  @media ${device.mobile}, ${device.minMobile} {
    margin: 20px 0 32px;
    font-size: 35px
  } 

  @media screen and (min-width: 376px) and (max-width: 410px) {
    font-size: 35px;
  }

  @media ${device.tablet} {
    margin: 20px 0 34px;
    font-size: 40px;
  }

  @media ${device.desktopS} {
    max-width: 80%;
  }

  @media ${device.desktopM} {
    margin: 36px 0 48px;
    white-space: pre-line;
  }
`;

const ButtonTitle = styled(TitleC)``;

const Button = styled(ButtonC)`
  &.ant-btn {
    border-radius: 16px;

    @media ${device.mobile}, ${device.minMobile} {
      padding: 12px 0 !important;
      width: 100%;
      justify-content: center;
    }

    @media ${device.tablet} {
      padding: 20px 36px !important;
      width: initial;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export { ViewHeader };
