import React from 'react';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';


type Props = {
  iconUrl: string
  qrUrl?: string
  title: string
  detail: string
  link?: string
};

function Block({
  iconUrl, qrUrl, title, detail, link,
}: Props) {
  return (
    <BlockA
      href={link}
      target={(link && link.includes('http')) ? '_blank' : '_self'}
    >
      <HeaderC>
        <Icon>
          <img src={iconUrl} alt="icon" />
        </Icon>
        { qrUrl && (
          <QrIcon>
            <img src={qrUrl} alt="qr" />
          </QrIcon>
        )}
      </HeaderC>
      <ContentC>
        <Title
          level=""
          desktopFont="w400 s16 h20"
          color="thirdly"
        >
          {title}
        </Title>
        <Detail
          level=""
          desktopFont="w600 s24 h28"
        >
          {detail}
        </Detail>
      </ContentC>
    </BlockA>
  );
}

const Icon = styled.div`
  width: 72px;

  img {
    width: inherit;
  }
`;

const QrIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const HeaderC = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Title = styled(TitleC)`
  font-family: var(--font-content);
`;

const Detail = styled(TitleC)`
  margin-top: 12px;
`;

const ContentC = styled.div``;

const BlockA = styled.a`
  padding: 28px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  gap: 24px;
  flex: 1;
`;

type PropsHelpBlock = {
  title?: string
};

export function HelpBlock({ title }: PropsHelpBlock) {
  return (
    <Container>
      <Header
        level=""
        desktopFont="w800 s60 h78"
      >
        {title || 'Поможем найти ответ\n на любой вопрос'}
      </Header>
      <List>
        <Block
          iconUrl="/icons/email.svg"
          title="Почта поддержки"
          detail="unionepro.support@innopolis.ru"
          link="mailto:unionepro.support@innopolis.ru"
        />
        <Block
          iconUrl="/icons/social/tg.svg"
          title="Телеграм-бот"
          detail="t.me/unionepro_bot"
          link="https://t.me/unionepro_bot"
        />
        <Block
          iconUrl="/icons/phone.svg"
          title="Звонок по России бесплатный"
          detail="8 800 550 31 71"
          link="tel:8 800 550 31 71"
        />
      </List>
    </Container>
  );
}


const Header = styled(TitleC)`
  white-space: pre-line;
`;

const List = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 32px;
`;


const Container = styled.div``;
