import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';

import { ActivityAfficheInfo } from 'components/activity-affice-header/ActivityAfficheInfo';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Layout } from 'components/layout/Layout';
import { ActivitySearchResults } from 'components/nearest-activities/ActivitySearchResults';
import { NearestActivities } from 'components/nearest-activities/NearestActivities';
import { News } from 'components/news/News';
import { IActivity } from 'models/IActivity';
import { activityService } from 'stores/activity/activityService';
import { device } from 'styles/devices';
// import {
//   ActivityAfficheHeader
// } from 'components/activity-affice-header/ActivityAfficheHeader';
// import { UsefulMaterials } from 'components/useful-materials/UsefulMaterials';
import { getMonthByDate, months } from 'utils/month-by-date';


const sortActivityByDate = (data: IActivity[]): IActivity[] => [...data]
  .sort((a, b) => Number(a.start_date) - Number(b.start_date));

export function ActivityAffiche() {
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [filteredActivities, setFilteredActivities] = useState<IActivity[]>([]);

  const { data: activities = [], isLoading } = activityService.useFetchAllActivityQuery({
    limit: 20,
    offset: 0,
    title: search,
  });

  useEffect(() => {
    if (activities.length < 1) {
      return;
    }

    if (!currentMonth || currentMonth === 'Текущий месяц') {
      const sortedActivities = sortActivityByDate(activities);
      setFilteredActivities(sortedActivities);
    }

    if (months.includes(currentMonth.toLowerCase())) {
      const filteredData = activities.filter(
        (item) => getMonthByDate(item.start_date) === currentMonth.toLocaleLowerCase(),
      );
      setFilteredActivities(sortActivityByDate(filteredData));
    }
  }, [currentMonth, activities]);

  if (isLoading) {
    return null;
  }

  const sortedInitData = sortActivityByDate(activities);

  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['События']}
        />
        <ActivityAfficheInfo searchChange={setSearch} />
        {/* <EventsAfficeHeader /> */}
        {search ? (
          <ActivitySearchResults data={sortedInitData} />
        ) : (
          <NearestActivities
            filteredData={filteredActivities}
            initialData={sortedInitData}
            selectedMonth={currentMonth}
            onMonthChange={setCurrentMonth}
          />
        )}
        {/* <UsefulMaterials /> */}
        <News />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
  }

  @media ${device.tablet} {
  }
  
  @media ${device.desktopS} {
    display: flex;
    flex-direction: column;
    gap: 136px;
    padding: 0 190px 148px;
  }
`;
