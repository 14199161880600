/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Boxes() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(62, 82, 3);
    if (isTablet) return glyphSizes(84, 110, 2);
    if (isDesktop) return glyphSizes(140, 184);

    return glyphSizes(140, 184);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M140 0H0V184H140V0Z" fill="white" />
      <path d="M125.471 59.1966H106.98V77.613H125.471V59.1966Z" fill="#F7B055" />
      <path d="M125.471 132.534H106.98V150.95H125.471V132.534Z" fill="#F7B055" />
      <path d="M125.471 95.8647H106.98V114.281H125.471V95.8647Z" fill="#F7B055" />
      <path d="M88.4906 59.1966H70V77.613H88.4906V59.1966Z" fill="#F7B055" />
      <path d="M88.4906 132.534H70V150.95H88.4906V132.534Z" fill="#F7B055" />
      <path d="M88.4906 95.8647H70V114.281H88.4906V95.8647Z" fill="#F7B055" />
      <path d="M51.6742 59.1966H33.1836V77.613H51.6742V59.1966Z" fill="#F7B055" />
      <path d="M51.6742 132.534H33.1836V150.95H51.6742V132.534Z" fill="#F7B055" />
      <path d="M51.6742 95.8647H33.1836V114.281H51.6742V95.8647Z" fill="#F7B055" />
      <path d="M70.0003 95.8647V114.281H88.4909L70.0003 132.533H51.5098V114.281L70.0003 95.8647Z" fill="#EB5757" />
      <path d="M70.0003 59.1966V77.4486H88.4909L70.0003 95.865H51.5098V77.4486L70.0003 59.1966Z" fill="#EB5757" />
      <path d="M106.983 59.1966V77.4486H125.308L106.983 95.865H88.4922V77.4486L106.983 59.1966Z" fill="#EB5757" />
      <path d="M106.983 95.8647V114.281H125.308L106.983 132.533H88.4922V114.281L106.983 95.8647Z" fill="#EB5757" />
      <path d="M106.983 132.534V150.95H125.308L106.983 169.367H88.4922V150.95L106.983 132.534Z" fill="#EB5757" />
      <path d="M70.0003 132.534V150.95H88.4909L70.0003 169.367H51.5098V150.95L70.0003 132.534Z" fill="#EB5757" />
      <path d="M33.1839 95.8647V114.281H51.5094L33.1839 132.533H14.6934V114.281L33.1839 95.8647Z" fill="#EB5757" />
      <path d="M33.1839 59.1966V77.4486H51.5094L33.1839 95.865H14.6934V77.4486L33.1839 59.1966Z" fill="#EB5757" />
      <path d="M33.1839 132.534V150.95H51.5094L33.1839 169.367H14.6934V150.95L33.1839 132.534Z" fill="#EB5757" />
    </SVGIcon>
  );
}
