import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import { NewsItem, NewsItemProps, NewsItemStyled } from './NewsItem';


type Props = {
  list: Array<NewsItemProps & { start_date: string }>;
  isNewsPath: boolean;
};

function NewsList({ list, isNewsPath }: Props) {
  const path = isNewsPath ? 'news' : 'activity';

  return (
    <List>
      {
        Array.isArray(list) && list
          .filter((month, idx) => idx < 5)
          .map((item) => (
            <NewsItem
              key={item._id}
              _id={item._id}
              date={item.date || item.start_date}
              title={item.title}
              path={path}
            />
          ))
      }
    </List>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;

  ${NewsItemStyled} {
    border-top: 1px solid var(--el-lines);
  }

  ${NewsItemStyled}:last-child {
    border-bottom: 1px solid var(--el-lines);
  }

  @media ${device.mobile}, ${device.minMobile} {
    ${NewsItemStyled} {
      padding: 16px 0;
    }
  }
`;

export { NewsList };
