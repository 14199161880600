import React from 'react';
import { styled } from '@linaria/react';

import { SearchDropdown } from '../SearchDropdown';
import { Title as TitleC } from '../Title';


type KnowledgeBaseHeaderProps = {
  searchChange: (value: string) => void;
  searchValue: string;
};

export function KnowledgeBaseHeader({ searchChange, searchValue }: KnowledgeBaseHeaderProps) {
  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s60 h78"
      >
        Поможем найти ответ на любой вопрос
      </Title>
      <SearchDropdown searchValue={searchValue} setSearchValue={searchChange} />
    </Container>
  );
}

const Title = styled(TitleC)`
  margin-bottom: 40px;
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

