import React from 'react';
import { styled } from '@linaria/react';

import { RainNew } from 'components/glyphs/shapes/RainNew';
import { device } from 'styles/devices';
import ellipse from 'assets/images/main-event/Ellipse.svg';
import star from 'assets/images/main-event/Star.svg';


type Props = {
  src: string
  alt?: string
};

/**
 * обрезает картинку в виде "пилюли"
 * с наклоном в 20 град.
 * картинка желательно в размерах 388х388
 *
 * @param src - ссылка на картинку
 * @param alt - альт текст картинки для a11y
 */
export function PictureFrame({ src, alt = '' }: Props) {
  return (
    <Container>
      <FigureBox>
        <img src={ellipse} alt="" />
        <img src={star} alt="" />
        <RainNew />
      </FigureBox>
      <HideBox>
        <img src={src} alt={alt} />
      </HideBox>
    </Container>
  );
}

const HideBox = styled.div`
  overflow: clip;
  transform: rotate(20deg);
  border-radius: 128px;

  @media ${device.mobile}, ${device.minMobile} {
    height: 107px;
    width: 77px;
  }

  @media ${device.tablet} {
    height: 70%;
    width: 160px;
  }

  @media ${device.desktopS} {
    height: 80%;
    width: 190px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transform: rotate(-20deg);
    position: relative;
    /* move picture params */
    height: 100%;
    left: 0;
    top:0;
    max-width: none;
  }
`;

const FigureBox = styled.div`
  img:nth-child(1){
    position: absolute;
    @media ${device.mobile}{
      top: -23px;
      left: 65px;
      width: 3%;
    }
    @media ${device.tablet}{
      top: -49px;
      left: 135px;
      width: auto;
    }
  }
  img:nth-child(2){
    position: absolute;
    @media ${device.mobile}{
      top: 1px;
      left: -61px;
      width: 25%;
    }
    @media ${device.tablet}{
      top: 0;
      left: -130px;
      width: auto;
    }
  }
  svg:nth-child(3){
    z-index: 10;
    position: absolute;
    transform: rotate(180deg);
    @media ${device.mobile}{
      bottom: -29px;
      right: 246px;
    }
    @media ${device.tablet}{
      bottom: -115px;
      right: -10px;
      width: auto;
    }
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  @media ${device.mobile}, ${device.minMobile} {
    .picture {
      max-height: 156px;
      margin-top: 20px;
    }
  }
  @media ${device.tablet} {
    .picture {
      max-height: 120%;
    }
  }
  @media ${device.desktopS} {
    .picture {
      max-height: initial;
    }
  }
`;
