/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Eclipse() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(14, 14, 3);
    if (isTablet) return glyphSizes(18, 18, 2);
    if (isDesktop) return glyphSizes(35, 35);

    return glyphSizes(35, 35);
  };

  return (
    <SVGIcon color="none" {...getSizes()}>
      <path d="M34.5 17C34.5 26.0991 26.9026 33.5 17.5 33.5C8.0974 33.5 0.5 26.0991 0.5 17C0.5 7.90087 8.0974 0.5 17.5 0.5C26.9026 0.5 34.5 7.90087 34.5 17Z" stroke="white" />
    </SVGIcon>
  );
}
