/* eslint-disable max-len */
import React from 'react';
import { styled } from '@linaria/react';

import { FeatureWrapper } from 'components/FeatureWrapper';
import { Slider, SliderStyled } from 'components/Slider';
import { device } from 'styles/devices';
import { cc } from 'utils/classcat';
import { useSelector } from 'utils/use-selector';
import BusinessImg from 'assets/images/business.png';
import EduImg from 'assets/images/edu_org.png';
import GovImg from 'assets/images/gov_sector.png';
import IndividualImg from 'assets/images/individual.png';


import { EducationCard } from './EducationCard';


const COURSES_LINK = 'https://courses.unionepro.ru/courses';

// TODO: refactor blocks to list
function Education() {
  const { isMobile, mobileType } = useSelector((state) => state.common);

  const title = cc({
    Обучение: mobileType === 'tablet',
    'Главные события': mobileType === 'phone',
  });

  return (
    <Wrapper title={title}>
      <Slider active={isMobile}>
        <EducationCard
          title="Для бизнеса"
          content="Корпоративное обучение для топ-менеджеров компаний, собственников бизнеса и их команд в соответствии с требованиями цифровой экономики."
          image={BusinessImg}
          link={COURSES_LINK}
          decorNumber={1}
        />
        <EducationCard
          title="Для госсектора"
          content="Повышение квалификации представителей органов государственной власти и местного самоуправления, подведомственных организаций, а также подготовка регионов к цифровой трансформации."
          image={GovImg}
          link={COURSES_LINK}
          decorNumber={2}
        />
        <EducationCard
          title="Для образовательных организаций"
          content="Обучение преподавателей и методистов сквозным цифровым технологиям, актуальных в отрасли, для формирования и актуализации образовательных программ."
          image={EduImg}
          link={COURSES_LINK}
          decorNumber={2}
        />
        <EducationCard
          title="Для физических лиц"
          content="Получение востребованной ИТ-специальности по программам повышения квалификации с выдачей официального документа установленного образца о профессиональной переподготовке."
          image={IndividualImg}
          link={COURSES_LINK}
          decorNumber={1}
        />
      </Slider>
    </Wrapper>
  );
}

const Wrapper = styled(FeatureWrapper)`
  ${SliderStyled} {
    @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
      display: flex;
    }

    @media ${device.mobile}, ${device.minMobile} {
      gap: 16px;
    }

    @media ${device.tablet} {
      margin: 0 -60px;
      padding: 0 60px;
      gap: 32px;
    }

    @media ${device.desktopS} {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
  }
`;

export { Education };
