import React, { ReactNode, useEffect, useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';

import { Text as TextC } from 'components/Text';
// import { device } from 'styles/devices';
import { mn } from 'utils/merge-class-names';


type PageProps = {
  id?: number
  value: string | number
  isActive?: boolean
  callBack?: (id: number) => void
};

function Letter({
  id, value, isActive, callBack,
}: PageProps) {
  return (
    <Num
      className={mn(isActive && 'active')}
      onClick={() => callBack && id && callBack(id)}
    >
      <Text className={mn(isActive && 'active')}>
        {value}
      </Text>
    </Num>
  );
}

function LeftBtn({ onClick, disabled }: any) {
  return <Btn className={disabled && 'disabled'} onClick={onClick}><LeftOutlined /></Btn>;
}

function RightBtn({ onClick, disabled }: any) {
  return <Btn className={disabled && 'disabled'} onClick={onClick}><RightOutlined /></Btn>;
}

type Props = {
  page?: number
  pagesCount?: number
  onLeft?: Function
  onRight?: Function
  onPage?: (id: number) => void
};

export function PaginationCustom({
  page = 1,
  pagesCount = 8,
  onLeft,
  onRight,
  onPage,
}: Props) {
  const [items, setItems] = useState<ReactNode[]>([]);

  useEffect(() => {
    if (pagesCount > 0) {
      const result = Array(pagesCount)
        .fill(null)
        .map((_, index) => {
          const id = index + 1;

          return (
            <Letter
              id={id}
              value={id}
              isActive={page === id}
              callBack={onPage}
              key={id}
            />
          );
        });

      setItems(result);
    }
  }, [onPage, page, pagesCount]);

  return (
    <Wrapper>
      <Container>
        <LeftBtn disabled={page === 1} onClick={onLeft} />
        <NumList>
          {items}
        </NumList>
        <RightBtn disabled={page === pagesCount} onClick={onRight} />
      </Container>
    </Wrapper>
  );
}

const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: var(--brand-primary);
  }
  
  &.disabled {
    color: var(--el-disabled);
    cursor: default;
  }
`;

const Text = styled(TextC)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    color: white;
  }

  :hover:not(.active) {
    color: var(--brand-primary);
  }
`;

const Num = styled.div`
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background-color: var(--brand-primary);
  }
`;

const NumList = styled.div`
  display: flex;
  gap: 12px;
`;

const Container = styled.div`
  padding: 14px 30px;
  background-color: white;
  border-radius: 40px;
  display: flex;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;
  margin-bottom: 80px;
`;
