import React from 'react';
import { styled } from '@linaria/react';

// import { device } from 'styles/devices';

import { IActivity } from 'models/IActivity';

// import data from './grid.json';
import { NearestActivitiesBlock } from './NearestActivitiesBlock';
// import type { BlockProps } from './NearestActivitiesBlock';


type NearestActivitiesGridProps = {
  list: IActivity[];
};

export function NearestActivitiesGrid(props: NearestActivitiesGridProps) {
  const { list } = props;

  return (
    <Grid>
      {
        Array.isArray(list)
        && list.map((block) => (
          <NearestActivitiesBlock
            activity={block}
            key={block._id}
          />
        ))
      }
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0.3fr);
  gap: 27px;
  width: 100%;
`;
