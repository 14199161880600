import React from 'react';
import {
  DownOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
// import { device } from 'styles/devices';
import {
  MenuProps,
  Dropdown as AntDropdown,
} from 'antd';

import { Title as TitleC } from 'components/Title';


export type DDItemProps = MenuProps['items'];
export type DDItem = { key: string, label: string };
export type DDOnClick = MenuProps['onClick'];

type Props = {
  label: string
  items: DDItemProps
  onClick?: DDOnClick
};

export function Dropdown({
  label, items, onClick,
}: Props) {
  return (
    <AntDropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: ['1'],
        onClick,
      }}
    >
      <Wrapper>
        <Title
          level=""
          desktopFont="w700 s20 h24"
        >
          {label}
        </Title>
        <DownOutlined />
      </Wrapper>
    </AntDropdown>
  );
}

const Title = styled(TitleC)`
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;
