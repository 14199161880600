import React from 'react';

import { ViewFooter } from 'components/view-footer/ViewFooter';


interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props) {
  return (
    <>
      {children}
      <ViewFooter />
    </>
  );
}
