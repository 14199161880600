import React from 'react';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';
import Boxes from 'assets/icons/boxes.svg';
import Edit from 'assets/icons/edit.svg';
import NotebookLite from 'assets/icons/notebook_light.svg';
import Share from 'assets/icons/share.svg';
import Subtasks from 'assets/icons/subtasks.svg';
import Users from 'assets/icons/users.svg';


type Props = {
  icon: string
  title: string
  text: string
  link?: string
};

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'edit': return <img src={Edit} alt="" />;
    case 'share': return <img src={Share} alt="" />;
    case 'notebook-lite': return <img src={NotebookLite} alt="" />;
    case 'boxes': return <img src={Boxes} alt="" />;
    case 'subtasks': return <img src={Subtasks} alt="" />;
    case 'users': return <img src={Users} alt="" />;
    default: return '';
  }
};

function ActivitiesBlock(props: Props) {
  const {
    icon, title, text, link,
  } = props;

  return (
    <Container href={link}>
      <Icon>{getIcon(icon)}</Icon>
      <Title
        level=""
        desktopFont="w700 s20 h24"
        tabletFont="w700 s20 h24"
        mobileFont="w700 s20 h24"
      >
        {title}
      </Title>
      <Text
        // @ts-ignore
        level=""
        desktopFont="w400 s16 h20"
        tabletFont="w400 s16 h20"
        mobileFont="w400 s16 h20"
      >
        {text}
      </Text>
    </Container>
  );
}

const Icon = styled.div`
  border-radius: 50%;
  border: 1px solid var(--el-lines);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobile}, ${device.minMobile} {
    max-width: 50px;
    min-height: 50px;
    padding: 8px 0;
    justify-content: center;

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media ${device.tablet} {
    max-width: 70px;
    max-height: 70px;
    padding: 12px 12px 15px 14px;
    margin-bottom: 24px;
  }

  @media ${device.desktopS} {
    max-width: 80px;
    min-height: 80px;
    padding: 20px;
    margin-bottom: 48px;
  }
`;

const Title = styled(TitleC)`
  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    margin-top: initial;
  }
`;

const Text = styled.div<React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>>>`
  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 8px;
  }

  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const Container = styled.a`
  border-radius: 24px;
  /* override anchor color */
  color: var(--text-primary);

  &:not(:hover) {
    background-color: white;
  }

  &:hover {
    background-color: var(--el-hover-2);
    /* override anchor color */
    color: var(--text-primary);
    cursor: pointer;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    padding: 24px;
    width: initial;
    height: initial;
  }

  @media ${device.desktopS} {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export { ActivitiesBlock };
export type { Props as ActivitiesBlockProps };
