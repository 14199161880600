/**
 * Calculates font for different devices
 *
 * @param isMobile - mobile status
 * @param isTablet - tablet status
 * @param isDesktop - desktop status
 * @param desktopFont - font string for desktop
 * @param tabletFont - font string for tablet
 * @param mobileFont - font string for mobile
 * @returns string
 */
export const getFont = (
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  desktopFont: string | undefined,
  tabletFont: string | undefined,
  mobileFont: string | undefined,
) => {
  if (isTablet && tabletFont) {
    return tabletFont;
  }

  if (isMobile && mobileFont) {
    return mobileFont;
  }

  if (isDesktop) {
    return desktopFont;
  }

  return '';
};
