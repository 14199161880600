import React, { useRef, useState } from 'react';
import { styled, CSSProperties } from '@linaria/react';

// import { Text as TextC } from 'components/Text';

import { LRButtons } from 'components/buttons/LRButtons';
import { SimilarActivity } from 'components/similar-activities/SimilarActivity';
import { Slider as SliderC } from 'components/Slider';
import { SliderList } from 'components/SliderList';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';
import {
  INITIAL_POS, SPEED, calculateDistance,
} from 'utils/overflowSlider';


type Props = {
  title: string
  list: IActivity[]
  className?: string
  style?: CSSProperties
};

export function SimilarActivities({
  title, list, className, style,
}: Props) {
  const startPosRef = useRef<number>(INITIAL_POS);
  const [pos, setPos] = useState(startPosRef.current);
  const maxDistance = calculateDistance(list.length);

  const onLeft = () => (pos > startPosRef.current
    ? setPos((p) => p - SPEED)
    : startPosRef.current);

  const onRight = () => (pos < maxDistance
    ? setPos((p) => p + SPEED)
    : maxDistance);

  return (
    <Container className={className} style={style}>
      <Header>
        <Title
          level=""
          desktopFont="w800 s40 h48"
        >
          {title}
        </Title>
      </Header>
      <Slider active>
        <SliderList
          list={list}
          posY={pos}
          Component={SimilarActivity}
        />
      </Slider>
      <Buttons>
        {list.length > 1
          && (
            <LRButtons
              leftDisabled={pos <= startPosRef.current}
              // rightDisabled={pos >= MAX_DISTANCE}
              rightDisabled={pos >= maxDistance}
              onLeft={onLeft}
              onRight={onRight}
            />
          )}
      </Buttons>
    </Container>
  );
}

const Title = styled(TitleC)``;

const Header = styled.div``;

const Slider = styled(SliderC)`
  margin-top: 32px;
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
`;
