import React from 'react';
import { styled } from '@linaria/react';

import {
  ActivitySpeaker,
  SpeakerProps,
} from 'components/activity-speakers/ActivitySpeaker';
import { Title as TitleC } from 'components/Title';


type Props = {
  list: SpeakerProps[]
};

export function ActivitySpeakers({ list }: Props) {
  return (
    <Container>
      <Header>
        <Title
          level=""
          desktopFont="w800 s24 h30"
        >
          Спикеры
        </Title>
        <Count
          level=""
          desktopFont="w600 s18 h24"
        >
          {list.length}
        </Count>
      </Header>
      <List>
        {
          Array.isArray(list)
          && list.map((speaker) => (
            <ActivitySpeaker {...speaker} />
          ))
        }
      </List>
    </Container>
  );
}

const Count = styled(TitleC)`
  background-color: #F5F6F8;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(TitleC)`
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
