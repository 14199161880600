import React, { useState } from 'react';
import { styled } from '@linaria/react';

import { DigestPagination } from 'components/DigestPagination';
import { FeatureWrapper } from 'components/FeatureWrapper';
import { Slider } from 'components/Slider';
import { useBreakpoints } from 'utils/useBreakpoints';

import list from './data.json';
import { EventsList } from './EventsList';


const START_POS = 1;
const STEP = 1;

function Events() {
  const [pos, setPos] = useState(START_POS);
  // desktop
  let blockWidth = 1105;
  let blockGap = 32;
  let maxPos = list.length;

  const { isMobile, isTablet } = useBreakpoints();

  if (isTablet) {
    blockWidth = 904;
    blockGap = 60;
    maxPos = Math.ceil(list.length / 2);
  }

  const initSlidePos = (blockWidth + blockGap) * (pos - 1);

  const onLeft = () => (pos > START_POS
    ? setPos((p) => p - STEP)
    : START_POS);

  const onRight = () => (pos < maxPos
    ? setPos((v) => v + STEP)
    : maxPos);

  return (
    <Wrapper title="Главные события">
      <Slider active={isMobile}>
        <EventsList list={list} initPosY={initSlidePos} />
      </Slider>
      {
        !isMobile
          ? (
            <Pagination
              startPage={START_POS}
              currentPage={pos}
              totalPages={maxPos}
              onPrevPage={onLeft}
              onNextPage={onRight}
              delay={100}
            />
          )
          // eslint-disable-next-line react/jsx-no-useless-fragment
          : <></>
      }
    </Wrapper>
  );
}

const Pagination = styled(DigestPagination)`
  margin-top: 24px;
`;

const Wrapper = styled(FeatureWrapper)`
  margin-right: -20px;
`;

export { Events };
