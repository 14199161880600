/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Arc() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(58, 58, 3);
    if (isTablet) return glyphSizes(78, 60, 2);
    if (isDesktop) return glyphSizes(153, 125);

    return glyphSizes(153, 125);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M101.363 37.6909C129.572 52.4809 140.33 87.3092 125.747 115.458L144.155 125C164.236 86.8321 149.414 39.3607 111.164 19.3225C72.9145 -0.715618 25.341 14.0745 5.25977 52.2424L23.6676 61.7844C38.2504 33.6355 73.1535 22.9008 101.363 37.6909Z" fill="#EB5757" />
      <path d="M96.3422 27.1946C124.312 41.9847 135.309 76.813 120.488 104.962L138.895 114.504C158.977 76.3359 144.155 28.8645 105.905 8.82632C67.6547 -11.2119 20.0812 3.57823 0 41.9847L18.4078 51.5267C33.2297 23.3778 68.1328 12.4046 96.3422 27.1946Z" fill="#FFBA15" />
    </SVGIcon>
  );
}
