import React from 'react';
import { styled } from '@linaria/react';
import { Button } from 'antd';

import { Heading } from 'components/Heading';
import { ReactComponent as ResultIcon } from 'assets/icons/bullseye-arrow.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/bx-task.svg';
import { ReactComponent as CityIcon }
  from 'assets/icons/city-variant-outline.svg';

import { ReactComponent as ArrowRight }
  from '../../assets/icons/arrow-right.svg';
import { device } from '../../styles/devices';


export interface PortfolioItemProps {
  client: string;
  task: string;
  result: string;
}

export function PortfolioItem({
  client,
  task,
  result,
}: PortfolioItemProps) {
  return (
    <Container>
      <Block>
        <BlockHead>
          <CityIcon title="Клиент" />
          <StyledHeading level={4}>Клиент:</StyledHeading>
        </BlockHead>
        <Description>{client}</Description>
      </Block>
      <Block>
        <BlockHead>
          <TaskIcon title="Задача" />
          <StyledHeading level={4}>Задача:</StyledHeading>
        </BlockHead>
        <Description>{task}</Description>
      </Block>
      <Block>
        <BlockHead>
          <ResultIcon title="Результат" />
          <StyledHeading level={4}>Результат:</StyledHeading>
        </BlockHead>
        {/* eslint-disable-next-line react/no-danger,@typescript-eslint/naming-convention */}
        <Description dangerouslySetInnerHTML={{ __html: result }} />
      </Block>
      <Button>
        <ButtonContent>
          Подробнее
          <StyledArrowRight />
        </ButtonContent>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid var(--el-lines);
  border-radius: 8px;
  padding: 24px;

  .ant-btn{
    margin-left: auto;
    color: var(--brand-primary);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    border: none;
    box-shadow: none;
    padding-right: 0;
  }
  .ant-btn:hover{
    border: none;
    box-shadow: none;
  }
  [ant-click-animating-without-extra-node='true']::after{display:none;}
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
`;

const StyledArrowRight = styled(ArrowRight)`
  margin-left: 10px;
`;

const Block = styled.div`
  background-color: #F2F8FD;
  margin-bottom: 14px;
  padding: 18px;
  border-radius: 8px;
`;

const Description = styled.div`
  color: var(-text-thirdly);
  @media ${device.mobile}{
    font-size: 14px;
  }
  @media ${device.tablet}{
    font-size: 16px;
  }
`;

const BlockHead = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`;
