import React from 'react';
import { styled } from '@linaria/react';

import { CommonCarousel }
  from 'components/common/common-carousel/CommonCarousel';
import { Heading } from 'components/Heading';
import { useBreakpoints } from 'utils/useBreakpoints';
import DummyKE from 'assets/images/dummy/1.png';
import DummyPI from 'assets/images/dummy/2.png';
import DummyEA from 'assets/images/dummy/3.png';


import data from './data.json';
import { VideoReviewItem } from './VideoReviewItem';


interface IdummyImages {
  [index: string]: string;
}
const dummyImages: IdummyImages = {
  dummyKE: DummyKE,
  dummyPI: DummyPI,
  dummyEA: DummyEA,
};

export function VideoReviews() {
  const { isMobile, isTablet } = useBreakpoints();
  let slidesPerRow = isTablet ? 2 : 3;
  if (isMobile) {
    slidesPerRow = 1;
  }

  const items = data.map((reviewItem) => (
    <VideoReviewItem
      {...reviewItem}
      key={reviewItem.key}
      url={dummyImages[reviewItem.url]}
    />
  ));

  return (
    <>
      <StyledHeading level={2}>
        Представители компаний об обучении
      </StyledHeading>
      <SubHeading level={5}>Отзывы участников</SubHeading>
      <CommonCarousel items={items} slidesPerRow={slidesPerRow} />
    </>
  );
}

const StyledHeading = styled(Heading)`
  && {
    margin-bottom: 10px;
  }
`;

const SubHeading = styled(Heading)`
  && {
    color: var(--text-secondary);
    margin-bottom: 30px;
  }
`;
