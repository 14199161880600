import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ISoftware } from 'models/ISoftware';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit: number;
  skip: number;
}

export const softwareService = createApi({
  reducerPath: 'softwareService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Software'],
  endpoints: (build) => ({
    fetchAllSoftware: build.query<ISoftware[], QueryProps>({
      query: ({ limit = 100, skip = 0 }) => ({
        url: '/software/all',
        params: {
          limit,
          skip,
        },
      }),
      providesTags: () => ['Software'],
    }),
  }),
});
