import React from 'react';
import { css } from '@linaria/core';

import { mn } from 'utils/merge-class-names';
import { useBreakpoints } from 'utils/useBreakpoints';


type Props = {
  children?: string | number | React.ReactElement | boolean
  level?: number | string
  desktopFontLevel?: string
  tabletFontLevel?: string
  mobileFontLevel?: string
  desktopFont?: string
  tabletFont?: string
  mobileFont?: string
  color?: 'primary' | 'secondary' | 'thirdly' | 'white' | 'brand' | 'black'
  className?: string
};

function Title(props: Props) {
  const { isMobile, isTablet } = useBreakpoints();

  const {
    children,
    level = 0,
    desktopFontLevel,
    tabletFontLevel,
    mobileFontLevel,
    desktopFont,
    tabletFont,
    mobileFont,
    color = 'primary',
    className,
  } = props;

  /**
   * @deprecated
   * @returns string
   */
  const getLevel = () => {
    if (isTablet && tabletFontLevel) {
      return tabletFontLevel;
    }

    if (isMobile && mobileFontLevel) {
      return mobileFontLevel;
    }

    if (desktopFontLevel) {
      return desktopFontLevel;
    }

    return level;
  };

  const getFont = () => {
    if (isTablet && tabletFont) {
      return tabletFont;
    }

    if (isMobile && mobileFont) {
      return mobileFont;
    }

    if (desktopFont) {
      return desktopFont;
    }

    return level;
  };

  // TODO: refactor, remove title level-*
  // ^ from all implementations

  const cn = mn(
    [container],
    [`level-${getLevel()}`],
    getFont(),
    color,
    className,
  );

  return (
    <div className={cn}>
      {children}
    </div>
  );
}

const container = css`
  font-family: var(--font-header);
  letter-spacing: 0;
  text-align: left;
  /* user-select: none; */

  &.level-9 {
    font-weight: 800;
    font-size: 60px;
    line-height: 78px;
  }
  &.level-8 {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
  }
  &.level-81 {
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;
  }
  &.level-w,
  &.w {
    &800 { font-weight: 800; }
    &700 { font-weight: 700; }
    &600 { font-weight: 600; }
    &500 { font-weight: 500; }
    &400 { font-weight: 400; }
  }
  &.s {
    &60 { font-size: 60px; }
    &40 { font-size: 40px; }
    &32 { font-size: 32px; }
    &30 { font-size: 30px; }
    &26 { font-size: 26px; }
    &24 { font-size: 24px; }
    &20 { font-size: 20px; }
    &18 { font-size: 18px; }
    &16 { font-size: 16px; }
    &14 { font-size: 14px; }
    &12 { font-size: 12px; }
  }
  &.h {
    &78 { line-height: 78px; }
    &52 { line-height: 52px; }
    &48 { line-height: 48px; }
    &40 { line-height: 40px; }
    &34 { line-height: 34px; }
    &32 { line-height: 32px; }
    &30 { line-height: 30px; }
    &28 { line-height: 28px; }
    &27 { line-height: 27px; }
    &24 { line-height: 24px; }
    &22 { line-height: 22px; }
    &20 { line-height: 20px; }
  }
  &.level-7 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
  &.level-6 {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
  }
  &.level-61 {
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
  }
  &.level-62 {
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
  }
  &.level-6b {
    font-weight: 800;
    font-size: 26px;
    line-height: 34px;
  }
  &.level-6c {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
  }
  &.level-5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
  &.level-4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
  &.level-3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  &.level-33 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
  &.level-32 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  &.level-33 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  &.level-34 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  &.level-35 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
  &.level-2 {
    /* h3 */
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
  &.level-1 {
    /* h4 */
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  &.level-1с {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  &.level-1d {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  &.level-1a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  &.level-1b {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  &.level-1c {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  &.level-0 {
    /* h5 */
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  &.level-01 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  &.level-02 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &.level-03 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &.brand {
    color: var(--brand-primary);
  }
  &.primary {
    color: var(--text-primary);
  }
  &.secondary {
    color: var(--text-secondary);
  }
  &.thirdly {
    color: var(--text-gray);
  }
  &.white {
    color: white;
  }
  &.black {
    color: #3b4168;
  }
`;

export { Title, container as TitleCSS };
export type { Props as TitleProps };
