import React from 'react';
import { styled } from '@linaria/react';

import { Title } from 'components/Title';


interface Props {
  stage: string;
  description: string | undefined;
}

export function StagesItem({ stage, description }: Props) {
  return (
    <Container>
      <StageNumber level={62}>{stage}</StageNumber>
      <Description>{description}</Description>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  z-index: 2;
  width: 16.6667%;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;
  margin-bottom: 30px;

  &:after,
  &:before {
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    background: var(--bg-primary);
    z-index: -1;
    top: 33px;
    width: 50%;
    left: 0;
  }
  &:after {
    left: auto;
    right: 0;
  }
`;

const StageNumber = styled(Title)`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--bg-secondary);
  margin-bottom: 16px;
  && {
    padding: 40px;
    color: #15b012;
  }
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 0;
  color: var(--text-primary);

`;
