/* eslint-disable max-len */
export const size = {
  minMobile: 374,
  mobile: 375,
  tabletS: 768,
  tablet: 992,
  desktopS: 1200,
  desktopM: 1536,
  desktop: 1920, // FullHD, S, M, L,
};

export const device = {
  minMobile: `(max-width: ${size.minMobile}px)`,
  mobile: `(min-width: ${size.mobile}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  tabletS: `(min-width: ${size.tabletS}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopS: `(min-width: ${size.desktopS}px)`,
  desktopM: `(min-width: ${size.desktopM}px)`,
};


export const media = {
  /* FROM 0 TO 992 */
  mobileOnly: `@media screen and (max-width: ${size.tablet})`,
  /* FROM 374 TO 992 */
  mobileAndTablet: `@media screen and (min-width: ${size.minMobile}px) and (max-width: ${size.tablet}px)`,
  /* FROM 992 TO 1536 */
  tabletAndDesktopS: `@media screen and (min-width: ${size.tablet}px) and (max-width: ${size.desktopS}px)`,
};
