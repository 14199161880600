import React, { useMemo } from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { cc } from 'utils/classcat';
import { useLongPress } from 'utils/useLongPress';
import { ReactComponent as AngleDownIcon } from 'assets/icons/angle-down.svg';


type Props = {
  direction: 'left' | 'right'
  disabled?: boolean
  onClick?: () => void
  delay?: number
};

function ButtonAngle(props: Props) {
  const {
    direction, disabled, onClick, delay = 25,
  } = props;
  const mouseLongPress = useLongPress(onClick, delay);

  const rotate = useMemo(() => {
    switch (direction) {
      case 'left': return 'rotate(90deg)';
      case 'right': return 'rotate(-90deg)';
      default: return '';
    }
  }, [direction]);

  return (
    <Container rotate={rotate} {...mouseLongPress}>
      <AngleDownIcon className={cc({ [icon]: true, disabled })} />
    </Container>
  );
}

export { ButtonAngle };

const Container = styled.span<{ rotate: string }>`
  transform: ${(props) => props.rotate}
`;

const icon = css`
  height: 32px;
  width: 32px;

  &:not(.disabled) {
    fill: var(--brand-primary);
    cursor: pointer;
  }
  &.disabled {
    fill: var(--el-disabled);
  }
`;
