import React from 'react';
import { styled } from '@linaria/react';

import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';


type ListItem = {
  title: string
  link?: string
};

type Props = {
  title: string
  list: ListItem[]
  footer?: string | false
  footerNode?: React.ReactNode
};

function ViewFooterColumn({
  title, list, footer, footerNode,
}: Props) {
  return (
    <Container>
      <Title
        desktopFont="w600 s14 h16"
        color="white"
        level=""
      >
        {title}
      </Title>
      <List>
        {
          Array.isArray(list) && list.map((item) => (
            <a
              href={item.link}
              key={item.title}
              target="_blank"
              rel="noreferrer"
            >
              <LinkText color="white">
                {item.title}
              </LinkText>
            </a>
          ))
        }
      </List>
      {
        footer && (
          <FooterText>
            {footer}
          </FooterText>
        )
      }
      {
        footerNode && (
          <FooterNode>
            {footerNode}
          </FooterNode>
        )
      }
    </Container>
  );
}
 
export { ViewFooterColumn };

const Title = styled(TitleC)`
  margin-bottom: 24px;
  opacity: 0.32;
`;

const LinkText = styled(TextC)`
  white-space: pre-line;
`;

const FooterText = styled(TextC)`
  opacity: 0.32;
  position: absolute;
  bottom: 0;
`;

const FooterNode = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  /* width: 360px; */
  min-width: 280px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
