import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Collapse } from 'antd';

import { ReactComponent as AngleDownIcon } from 'assets/icons/angle-down.svg';

import data from './data.json';

import { IAnswer } from '../../models/IQuestion';
import { questionService } from '../../stores/question/questionService';
import { cc } from '../../utils/classcat';
import { Title as TitleQ, Title as TitleA, Title } from '../Title';


export type CollapseProps = {
  _id: string;
  title: string;
};

type QuestionItem = {
  _id: string;
  section: {
    title: string;
  };
  question: string;
  answers: IAnswer[];
};
const { Panel } = Collapse;


function AnswerBlock({ text, picture }: IAnswer) {
  return (
    <>
      <Answer level="" desktopFont="w400 s16 h20">{text}</Answer>
      <img src={picture} alt="" />
    </>
  );
}

export function KnowledgeBaseCollapse({ _id, title }: CollapseProps) {
  const [isRotated, setIsRotated] = React.useState(Array(data.length).fill(false));
  const { data: dataQuestion, isLoading } = questionService.useFetchAllQuestionQuery({ system: _id });

  if (isLoading) {
    return null;
  }

  const handleClick = (index: number) => {
    const rotated = [...isRotated];
    rotated[index] = !rotated[index];
    setIsRotated(rotated);
  };

  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s40 h48"
      >
        {title}
      </Title>
      <Collapse className={collapse} bordered={false}>
        {
          dataQuestion?.items
            && dataQuestion.items.map((item: QuestionItem, index: number) => (
              <Panel
                showArrow={false}
                key={item._id}
                // @ts-ignore
                onClick={() => handleClick(index)}
                header={(
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    className={header}
                  >
                    <Title level="" desktopFont="w700 s24 h28">{item.section?.title}</Title>
                    <AngleDownIcon
                      className={cc({ [icon]: true, rotated: isRotated[index] })}
                      onClick={() => handleClick(index)}
                    />
                  </div>
                )}
              >
                <CollapseContent>
                  <Block>
                    <div className={block}>
                      <Question level="" desktopFont="w500 s20 h24">{item.question}</Question>
                      {item?.answers.map((answer: IAnswer, idx: number) => (
                        <AnswerBlock
                          key={idx}
                          picture={answer.picture}
                          text={answer.text}
                        />
                      ))}
                    </div>
                  </Block>
                </CollapseContent>

              </Panel>
            ))
        }
      </Collapse>
    </Container>
  );
}

const Block = styled.div`
 display: flex;
 flex-wrap: wrap;
 gap: 60px;
`;

const Question = styled(TitleQ)`
`;

const Answer = styled(TitleA)`
    margin: 24px 0 32px 0;
    max-width: 79%;
`;

const block = css`
    :nth-child(2){
    margin-top: 43px;
 }
`;

const Container = styled.div`
 display: flex;
 width: 1540px;
 padding: 40px;
 flex-direction: column;
 align-items: flex-start;
 gap: 40px;
 background: #fff;
 border-radius: 24px;
 margin: 0 auto;
`;

const icon = css`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  background: var(--elements-primary-7, rgba(0, 113, 206, 0.07));
  fill: #0071CE;
  text-align: end;
  &:hover{
    fill: #fff;
    background: #0071CE;
  }
  &.rotated {
    transform: rotate(180deg);
  }
`;

const collapse = css`
   display: grid;
   width: 100%;
   background-color: #ffffff;
   border: none;
   border-radius: 16px;
 
   &.ant-collapse-borderless {
    background-color: #ffffff;
    border-bottom: none; 
    padding-left: none;
     > .ant-collapse-item {
          border-bottom: none;
     }
   }
   
   &.ant-collapse, .ant-collapse-icon-position-start, .ant-collapse-borderless {
        background-color: #ffffff;
        border-bottom: none;
   }
   &.ant-collapse-header{
      padding-left: none;
   }
   &.ant-collapse-content{
     background: #F5FBFF;
   }
`;

const CollapseContent = styled.div`
     background: #F5FBFF;
     margin-top: -30px;
     border-radius: 0 0 16px 16px;
     padding: 24px 32px;
`;

const header = css`
   background: #F5FBFF;
   padding: 24px 32px;
   border-radius: 16px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-left: 4px;
`;
