import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';
import { useSelector } from 'utils/use-selector';

import FooterLogo from '../../assets/icons/new-footer-logo.svg';


function ViewFooterCredentials() {
  const { mobileType } = useSelector((state) => state.common);

  return (
    <Container>
      {
        mobileType === 'phone'
          ? false
          : (
            <Logo>
              <img src={FooterLogo} alt="" />
            </Logo>
          )
      }
      <Ceil>
        <Link href="/docs/politicacookies.pdf" target="_blank">
          <Copy>
            Мы используем файлы cookie, для персонализации сервисов{' '}
            и повышения удобства пользования сайтом.{' '}
            Если вы не согласны на их использование,{' '}
            поменяйте настройки браузера.
          </Copy>
        </Link>
        <span>
          © Университет Иннополис, 2023
        </span>
      </Ceil>
    </Container>
  );
}

const Logo = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
  }
`;

const Link = styled.a`
  color: unset;
  margin-bottom: 32px;

  :hover {
    color: unset;
  }
`;

const Copy = styled.span`
  /* margin-bottom: 32px; */
`;

const Ceil = styled.div`
  display: flex;
  opacity: 0.32;
  max-width: 262px;
  font-size: 14px;

  @media ${device.mobile}, ${device.minMobile} {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    flex-direction: column;
    justify-content: space-between;
  }

  @media ${device.desktopM} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* .ceil {
    opacity: 0.32;
  } */
`;

export { ViewFooterCredentials };
