import React from 'react';
import { styled } from '@linaria/react';

import { FeatureWrapper } from 'components/FeatureWrapper';
import { Slider } from 'components/Slider';
import { Text } from 'components/Text';
// import apps from 'stores/services/apps.json';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

import { CatalogAppList } from './CatalogAppList';

import { softwareService } from '../../stores/software/softwareService';


function CatalogApp() {
  const { isMobile } = useBreakpoints();
  const { data: apps } = softwareService.useFetchAllSoftwareQuery({ limit: 3, skip: 0 });

  return (
    <Wrapper title="Каталог ПО">
      { !isMobile
        ? (
          <HeaderLink href="https://apps.unionepro.ru/catalog">
            <Text
              type="buttons"
              color="brand"
            >
              Перейти в каталог ПО
            </Text>
            <ArrowRight className="icon" />
          </HeaderLink>
        )
        // eslint-disable-next-line react/jsx-no-useless-fragment
        : <></>}
      <Slider active={isMobile}>
        <CatalogAppList list={apps} />
      </Slider>
    </Wrapper>
  );
}

const HeaderLink = styled.a`
  position: absolute;
  top: 28px;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  @media ${device.mobile}, ${device.minMobile} {
    top: 20px;
  }

  @media ${device.tablet} {
    top: 24px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
`;

const Wrapper = styled(FeatureWrapper)`
  margin-right: -20px;
  position: relative;
  user-select: none;
`;

export { CatalogApp };
