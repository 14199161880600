import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';

import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';
// import { getSourceImage } from 'utils/article-sources';

import { useBreakpoints } from '../../utils/useBreakpoints';


export type Props = {
  _id: string
  tablet_cover: string
  mobile_cover: string
  desktop_cover: string
  date: string
  // source: string
  title: string
  text_top: string
  // textBottom: string
};

function stripTags(original: string) {
  return original.replace(/(<([^>]+)>)/gi, '');
}


type BlockHeaderProps = {
  date: string
};

function BlockHeader({ date }: BlockHeaderProps) {
  return (
    <Header>
      <Date
        level=""
        desktopFont="w500 s18 h20"
        tabletFont="w500 s14 h20"
        mobileFont="w500 s14 h20"
      >
        {dayjs(date).format('DD.MM.YYYY')}
      </Date>
      {/* <Source>
        <img
          src={getSourceImage(source)}
          alt=""
        />
        <SourceLabel
          level=''
          desktopFont='w500 s18 h20'
        >
          {source}
        </SourceLabel>
      </Source> */}
    </Header>
  );
}

export function ArticleArchiveBlock({
  _id,
  date,
  tablet_cover,
  mobile_cover,
  desktop_cover,
  title,
  text_top,
}: Props) {
  const [image, setImage] = useState<string>('');
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  useEffect(() => {
    if (tablet_cover || mobile_cover || desktop_cover) {
      if (isMobile) {
        setImage(desktop_cover);
      }

      if (isTablet) {
        setImage(tablet_cover);
      }

      if (isDesktop) {
        setImage(mobile_cover);
      }
    }
  }, [tablet_cover, mobile_cover, desktop_cover, isMobile, isTablet, isDesktop]);

  return (
    <Anchor href={`/news/${_id}`} target="_blank">
      <Container>
        <Image>
          <img src={image} alt="" />
        </Image>
        <Information>
          <div>
            <BlockHeader date={date} />
            <Title
              level=""
              desktopFont="w700 s30 h40"
              tabletFont="w700 s18 h24"
              mobileFont="w700 s18 h24"
            >
              {title}
            </Title>
          </div>
          <Description color="thirdly">
            {`${stripTags(text_top).slice(0, 200)}...`}
          </Description>
        </Information>
      </Container>
    </Anchor>
  );
}


const Anchor = styled.a`
  cursor: pointer;
`;

const Image = styled.div`
  max-width: 492px;
  min-width: 492px;
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    justify-content: center;
    align-items: center;
    max-width: none;
    min-width: auto;
  }

  @media ${device.tabletS} {
    max-width: 315px;
    min-width: 315px;
  }

  img {
    border-radius: 24px;
    overflow: hidden;
  }
`;

const Date = styled(TitleC)``;

// const SourceLabel = styled(TitleC)``;

// const Source = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 12px;
// `;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const Title = styled(TitleC)``;

const Description = styled(TextC)``;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 952px;
  justify-content: space-between;
`;

const Container = styled.div`
  padding: 32px;
  background-color: white;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  gap: 32px;
  flex-direction: row;

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: column;
  }

  @media ${device.tabletS} {
    flex-direction: row;
    padding: 24px;
    gap: 20px;
  }
`;
