/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function CeltStar() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(19, 23, 3);
    if (isTablet) return glyphSizes(26, 31, 2);
    if (isDesktop) return glyphSizes(49, 60);

    return glyphSizes(49, 60);
  };

  return (
    <SVGIcon color="none" {...getSizes()}>
      <path d="M30.1693 74.9702C29.9299 62.7615 35.6273 38.344 60.332 38.344" stroke="#909EBB" />
      <path d="M30.1628 74.9702C30.4022 62.7615 24.7047 38.344 0 38.344" stroke="#909EBB" />
      <path d="M30.1628 1C30.4022 13.2087 24.7047 37.6262 0 37.6262" stroke="#909EBB" />
      <path d="M30.1693 1C29.9299 13.2087 35.6273 37.6262 60.332 37.6262" stroke="#909EBB" />
    </SVGIcon>
  );
}
