import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';
import { useBreakpoints } from 'utils/useBreakpoints';

import { CourseCardsList } from './CourseCardList';
import { CourseDownloadLink } from './CourseDownloadLink';


export function CoursesInfo() {
  const { isMobile } = useBreakpoints();

  return (
    <div>
      <CourseHeader>
        <StyledHeading level={2}>Наши курсы</StyledHeading>
        {!isMobile && <CourseDownloadLink /> }
      </CourseHeader>
      <CourseCardsList />
      {isMobile && <CourseDownloadLink /> }
    </div>
  );
}

const StyledHeading = styled(Heading)`
  && {
  margin-bottom: 24px;
  }
`;

const CourseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;
