import React, { useMemo, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Carousel } from 'antd';
import { CarouselRef, DotPosition } from 'antd/lib/carousel';
import classnames from 'classnames';

import { CommonProgress } from './CommonProgress';


interface Props {
  items: React.ReactNode[];
  slidesPerRow?: number;
  slidesToShow?: number;
  dots?: boolean;
  infinite?: boolean;
  showProgress?: boolean;
  dotPosition?: DotPosition;
  className?: string;
  autoplay?: boolean;
  adaptiveHeight?: boolean;
}

export function CommonCarousel({
  items,
  slidesPerRow = 3,
  slidesToShow = 1,
  dots = false,
  infinite = false,
  showProgress = true,
  autoplay = false,
  dotPosition,
  className,
  adaptiveHeight,
}: Props) {
  const ref = useRef<CarouselRef>(null);
  const [current, setCurrent] = useState(1);
  const [isPrevDisabled, isNextDisabled] = useMemo(
    () => [current === 1, current * slidesPerRow + slidesPerRow - 1 > items.length],
    [current, items.length, slidesPerRow],
  );

  const onPrevClickHandler = () => {
    if (ref.current) {
      ref.current.prev();
    }
  };

  const onNextClickHandler = () => {
    if (ref.current) {
      ref.current.next();
    }
  };

  const onChangeScreen = (currentScreen: number) => {
    setCurrent(currentScreen * slidesPerRow + 1);
  };

  const containerClassName = classnames(
    className,
    { [carouselWithDots]: !!dots },
  );

  return (
    <>
      <CarouselContainer className={containerClassName}>
        <Carousel
          ref={ref}
          slidesPerRow={slidesPerRow}
          slidesToShow={slidesToShow}
          dots={dots}
          infinite={infinite}
          afterChange={onChangeScreen}
          dotPosition={dotPosition}
          autoplay={autoplay}
          adaptiveHeight={adaptiveHeight}
        >
          {items.map((item, index) => <div key={index}>{item}</div>)}
        </Carousel>
      </CarouselContainer>
      {showProgress && (
        <CarouselBottom>
          <CommonProgress current={current} max={items.length} />
          <CarouselActions>
            <LeftOutlined
              onClick={onPrevClickHandler}
              className={isPrevDisabled ? 'disabled' : undefined}
            />
            <RightOutlined
              onClick={onNextClickHandler}
              className={isNextDisabled ? 'disabled' : undefined}
            />
          </CarouselActions>
        </CarouselBottom>
      )}
    </>
  );
}

const CarouselBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  font-family: var(--font-header);
  font-weight: 600;
  color: var(--brand-primary);
`;

const CarouselContainer = styled.div`
  margin: 0 -15px;
`;

const CarouselActions = styled.div`
  display: flex;
  gap: 44px;
  .disabled {
    color: var(--el-lines);
  }
`;

const carouselWithDots = css`
  .ant-carousel {
    padding-bottom: 30px;
    .slick-dots {
      &.slick-dots-bottom {
        bottom: -25px;
      }
      li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.slick-active {
          width: 8px;
          button {
            background: var(--brand-primary);
          }
        }
        button {
          border-radius: 50%;
          background: var(--text-gray);
          opacity: 1;
          height: 8px;
        }
      }
    }
  }
`;
