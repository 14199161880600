import React from 'react';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';

import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';


type Props = {
  _id: string;
  date: string;
  title: string;
  path: string;
};

function NewsItem({
  date, title, _id, path,
}: Props) {
  // TODO: заменить фонт тайтлы во всех Тайтл копонентах
  // TODO: заменить фонт тайтлы во всех Текст компонентах
  // TODO: убрать депрекейтед фонт пропы из Тайтл и Текст компонентов
  return (
    <Item>
      <Anchor href={`/${path}/${_id}`} target="_blank">
        <Date
          level=""
          desktopFont="w600 s20 h24"
          tabletFont="w600 s14 h20"
          mobileFont="w600 s14 h24"
          color="thirdly"
        >
          {dayjs(date).format('DD.MM.YYYY')}
        </Date>
        <Description
          level=""
          desktopFont="w600 s20 h24"
          tabletFont="w600 s16 h20"
          mobileFont="w600 s16 h20"
        >
          {title}
        </Description>
      </Anchor>
    </Item>
  );
}

const Anchor = styled.a`
  display: flex;

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    flex-direction: column;
  }

  @media ${device.mobile}, ${device.minMobile}{
    gap: 4px;
  }

  @media ${device.tablet} {
    /* padding: 20px 0; */
    gap: 8px;
  }

  @media ${device.desktopS} {
    flex-direction: row;
    padding: initial;
    gap: initial;
  }
`;

const Date = styled(TitleC)`
  && {
    /* min-width: 68px; */
    min-width: 200px;
  }
`;

const Description = styled(TitleC)``;

const Item = styled.div`
  display: flex;

  &:hover {
    ${Date}, ${Description} {
      color: var(--brand-primary);
    }
  }

  /* @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    flex-direction: column;
  }

  @media ${device.mobile}, ${device.minMobile} {
    gap: 4px;
  }

  @media ${device.tablet} {
    padding: 20px 0;
    gap: 8px;
  } */

  @media ${device.desktopS} {
    /* padding: 40px 0; */
    flex-direction: row;
    gap: 32px;

    &:hover {
      cursor: pointer;
      gap: 0;
      &::before {
        content: "<";
        color: var(--brand-primary);
        font-size: 36px;
        /* min-width: 68px; */
        /* min-width: 200px; */
      }

      ${Date} {
        z-index: -10;
        min-width: 178px;
        width: 178px;
      }
    }
  }
`;

export { NewsItem, Item as NewsItemStyled };
export type { Props as NewsItemProps };
