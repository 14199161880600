import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import Title from 'antd/lib/typography/Title';
import classnames from 'classnames';

import { BusinessInfo } from 'components/business-info/BusinessInfo';
import { CommonHeader } from 'components/common/CommonHeader';
import { CoursesInfo } from 'components/courses-info/CoursesInfo';
import { ExplanationInfo } from 'components/ExplanationInfo';
import { FoundNothing } from 'components/FoundNothing';
import { GeneralInfo } from 'components/general-info/GeneralInfo';
import { Layout } from 'components/layout/Layout';
import { MainLayout } from 'components/MainLayout';
import { PortfolioInfo } from 'components/portfolio-info/PortfolioInfo';
import { StagesInfo } from 'components/stages-info/StagesInfo';
import { Tile } from 'components/Tile';
import { Clients } from 'components/trained-clients/Clients';
import { VideoReviews } from 'components/video-reviews/VideoReviews';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';
import foundNothingBGLeft from 'assets/images/found-nothing/bg-left.svg';


export function Companies() {
  const { isMobile } = useBreakpoints();

  return (
    <Layout>
      <CommonHeader className={commonHeader}>
        <Title level={2}>Компаниям</Title>
        <TextContainer>
          Профессионализм. Эффективность. Системность. Непрерывность.
        </TextContainer>
      </CommonHeader>
      <MainLayout>
        <Tile className={classnames({ [noPadding]: isMobile })}>
          <GeneralInfo />
        </Tile>
        <Tile>
          <ExplanationInfo />
        </Tile>
        <Tile>
          <BusinessInfo />
        </Tile>
        <CoursesTile>
          <CoursesInfo />
        </CoursesTile>
        <Tile
          className={foundNothing}
          style={{ backgroundImage: `url(${foundNothingBGLeft})` }}
        >
          <FoundNothing />
        </Tile>
        <Tile>
          <StagesInfo />
        </Tile>
        <ClientsTile>
          <Clients />
        </ClientsTile>
        <Tile>
          <VideoReviews />
        </Tile>
        <Tile>
          <PortfolioInfo />
        </Tile>
        {/* <Tile>
          FeedbackForm
        </Tile> */}
      </MainLayout>
    </Layout>
  );
}


const TextContainer = styled.p`
  display: none;
  margin-bottom: 0;
  @media ${device.tablet} {
    display: block;
  }
`;

const commonHeader = css`
  color: var(--text-primary);
  h2.ant-typography {
    color: var(--text-primary);
    font-size: 28px;
    font-weight: 700;
    font-family: var(--font-header);
    margin-bottom: 8px;
    line-height: 36px;
  }
`;

const foundNothing = css`
  padding: 0;
  background-position: -300px 100%;
  background-repeat: no-repeat;
  
  @media ${device.tablet} {
    background-position: 0 160%;
  }
`;

const noPadding = css`
  padding: 0;
`;

const CoursesTile = styled(Tile)`
  background: none;
  padding: 0;
  @media ${device.tablet} {
    background: var(--bg-secondary);
    padding: 30px 24px;
  }
`;

const ClientsTile = styled(Tile)`
  @media ${device.tablet}{
    padding: 30px 11px;
  }
`;

