// import React, { useState } from 'react';
// import { Layout, Menu } from 'antd';
import React from 'react';
import { css } from '@linaria/core';
import { Layout } from 'antd';

// import type { MenuProps } from 'antd';

import { device } from 'styles/devices';
import Staff from 'assets/images/staff.svg';


// const items: MenuProps['items'] = [
//   {
//     label: 'О проекте',
//     key: 'about',
//   },
//   {
//     label: 'Консорциум',
//     key: 'Consorcium',
//   },
//   {
//     label: 'Обучение',
//     key: 'learning',
//   },
//   {
//     label: 'ОПОП и профстандарты',
//     key: 'professional-standards',
//   },
//   {
//     label: 'Отраслевые рабочие группы',
//     key: 'work-groups',
//   },
//   {
//     label: 'Компаниям',
//     key: 'companies',
//   },
// ];

interface Props {
  className: string;
  children: React.ReactNode;
}

export function CommonHeader({ children, className }: Props) {
  // const [current, setCurrent] = useState<string>('companies');
  // const onClick: MenuProps['onClick'] = (e) => setCurrent(e.key);
  return (
    <div className={`${header} ${className}`}>
      {/* <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode='horizontal'
        items={items}
      /> */}
      <Layout className={pageTitle}>
        <div>{children}</div>
        <img src={Staff} alt="" className="stuff" />
      </Layout>
    </div>
  );
}


const header = css`
  background: var(--bg-secondary);

  .ant-menu-horizontal {
    padding-left: 24px;
    border-bottom-width: 2px;
    color: var(--text-secondary);
    > .ant-menu-item:after {
      bottom: -1px;
    }
    &:not(.ant-menu-dark) > .ant-menu-item:hover {
      color: var(--text-primary);

      &:after {
        display: none;
      }
    }
  }
`;

const pageTitle = css`
  &.ant-layout {
    display: flex;
    justify-content: space-between;
    background: var(--bg-secondary);
    padding: 24px 20px;
    position: relative;
    flex-direction: row;

    @media ${device.tablet} {
      padding: 29px 40px;
    }
    @media ${device.desktopS} {
      padding: 40px 190px;
    }
  }

  .stuff {
    margin-top: -16px;
    height: auto;
    position: absolute;
    width: 118px;
    margin-bottom: -28px;
    bottom: 0;
    right: 0;

    @media ${device.tablet} {
      position: relative;
      width: 206px;
      margin-bottom: -40px;
      bottom: auto;
      right: auto;
    }
  }
`;
