import React from 'react';
import { styled } from '@linaria/react';
import DOMPurify from 'dompurify';

import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';


type ActivityArchiveBlockProps = {
  activity: IActivity;
};

export function ActivityArchiveBlock({ activity }: ActivityArchiveBlockProps) {
  const purifiedDescription = DOMPurify.sanitize(activity.short_description);

  return (
    <Link href={`/activity/${activity._id}`} target="_blank">
      <Container>
        <Image>
          <img src={activity.desktop_cover} alt="" />
        </Image>
        <Information>
          <div>
            <Title
              level=""
              desktopFont="w700 s30 h40"
              tabletFont="w700 s18 h24"
              mobileFont="w700 s18 h24"
            >
              {activity.title}
            </Title>
          </div>
          <Description color="thirdly">
            {/* eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention */}
            <div dangerouslySetInnerHTML={{ __html: purifiedDescription }} />
          </Description>
        </Information>
      </Container>
    </Link>

  );
}

const Link = styled.a`
  cursor: pointer;
`;

const Image = styled.div`
  max-width: 492px;
  min-width: 492px;
  display: flex;
  justify-content: center;

  img {
    border-radius: 24px;
    overflow: hidden;
  }
`;

const Title = styled(TitleC)``;

const Description = styled(TextC)``;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 952px;
  justify-content: space-between;
`;

const Container = styled.div`
  padding: 32px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  gap: 32px;
  flex-direction: row;
`;
