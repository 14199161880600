import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { commonSlice } from 'stores/common/commonSlice';


export function MobilitySentry() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const dispatch = useDispatch();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // const isMobile = width <= 768;
    // TODO: refactor sizes as of devices.ts
    const isTablet = width <= 1024;
    const isPhone = width <= 375;

    if (isPhone || isTablet) {
      dispatch(commonSlice.actions.setMobileState({
        isMobile: true,
        mobileType: isPhone ? 'phone' : 'tablet',
      }));
    } else {
      // is desktop
      dispatch(commonSlice.actions.setMobileState({
        isMobile: false,
        mobileType: 'desktop',
      }));
    }
  }, [dispatch, width]);

  return null;
}
