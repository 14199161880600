export const wordDeclension = (singular: string, plural1: string, plural2: string) => function (count: number): string {
  const left = count % 10;
  const left2 = count % 100;
  
  if (left === 1 && left2 !== 11) {
    return singular;
  }

  if (left >= 2 && left <= 4 && (left2 < 10 || left2 >= 20)) {
    return plural1;
  }

  return plural2;
};
