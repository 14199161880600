import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { INews } from 'models/INews';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit: number;
  skip: number;
}

export const newsService = createApi({
  reducerPath: 'newsService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['News'],
  endpoints: (build) => ({
    fetchAllNews: build.query<INews[], QueryProps>({
      query: ({ limit = 100, skip = 0 }) => ({
        url: '/news/all',
        params: {
          limit,
          skip,
          sort: '-date',
        },
      }),
      providesTags: () => ['News'],
    }),
    getNewsById: build.query<INews, string>({
      query: (id) => `/news/get/${id}`,
    }),
  }),
});
