import { useEffect, useState } from 'react';

import { size } from 'styles/devices';


export const useBreakpoints = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function updateDevice() {
      const { clientWidth } = document.documentElement;
      setIsMobile(clientWidth < size.tabletS);
      setIsTablet(clientWidth >= size.tabletS && clientWidth < size.desktopS);
      setIsDesktop(clientWidth >= size.desktopS);
    }

    window.addEventListener('resize', updateDevice);
    updateDevice();

    return () => window.removeEventListener('resize', updateDevice);
  }, []);

  return { isMobile, isTablet, isDesktop };
};
