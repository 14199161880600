import React from 'react';


const ICON_DEFAULT_SIZE = 24;
const ICON_DEFAULT_COLOR = 'currentColor';
const ICON_DEFAULT_VIEW_BOX = '0 0 24 24';

type SVGIconProps = {
  style?: any
  color?: string
  height?: number
  width?: number
  size?: number
  children?: React.ReactNode
  viewBox?: string
};

function SVGIcon({
  style,
  color = ICON_DEFAULT_COLOR,
  height,
  width,
  size = ICON_DEFAULT_SIZE,
  viewBox = ICON_DEFAULT_VIEW_BOX,
  children,
}: SVGIconProps) {
  return (
    <svg
      className="emop-SVGIcon"
      fill={color}
      style={{ height: height || size, width: width || size, ...style }}
      viewBox={viewBox || ICON_DEFAULT_VIEW_BOX}
    >
      {children}
    </svg>
  );
}

SVGIcon.cxBase = 'SVGIcon';

export { SVGIcon };
