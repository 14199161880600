import React, { ReactElement } from 'react';
import { styled, CSSProperties } from '@linaria/react';

// particles

import { Arc } from 'components/glyphs/Arc';
import { Boxes } from 'components/glyphs/Boxes';
import { Combo } from 'components/glyphs/Combo';
import { Cone } from 'components/glyphs/Cone';
import { CrosshairBox } from 'components/glyphs/CrosshairBox';
import { Eye } from 'components/glyphs/Eye';
import { CeltStar } from 'components/glyphs/shapes/CeltStar';
import { Crosshair } from 'components/glyphs/shapes/Crosshair';
import { Echinus } from 'components/glyphs/shapes/Echinus';
import { Eclipse } from 'components/glyphs/shapes/Eclipse';
import { Rain } from 'components/glyphs/shapes/Rain';
import { Spring } from 'components/glyphs/shapes/Spring';
import { SpringVertical } from 'components/glyphs/shapes/SpringVertical';
import { StarBig } from 'components/glyphs/shapes/StarBig';
import { useBreakpoints } from 'utils/useBreakpoints';


type Props = {
  type: number
  className?: string
  style?: CSSProperties
};

const COORD_PENALTY = 1;
const DESKTOP_WIDTH = 316;
const DESKTOP_HEIGHT = 404;
const DESKTOP_X_BORDER = 150;
const DESKTOP_Y_BORDER = 144;
const TABLET_WIDTH = 166;
const TABLET_HEIGHT = 264;
const TABLET_X_BORDER = 79;
const TABLET_Y_BORDER = 76;
const MOBILE_WIDTH = 123;
const MOBILE_HEIGHT = 196;
const MOBILE_X_BORDER = 57;
const MOBILE_Y_BORDER = 55;


function Fg(
  { children, position }: { children: ReactElement, position: { top: string, left: string } },
) {
  return <Glyph style={position}>{children}</Glyph>;
}

/**
 * Глиф для блока популярных курсов
 */
function GlyphImage({ type, className, style }: Props) {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getOffset = (coordLen: number) => (
    `${Math.floor(Math.random() * coordLen) + COORD_PENALTY}px`
  );
  const rndX = () => {
    if (isMobile) return getOffset(MOBILE_WIDTH - MOBILE_X_BORDER);
    if (isTablet) return getOffset(TABLET_WIDTH - TABLET_X_BORDER);
    if (isDesktop) return getOffset(DESKTOP_WIDTH - DESKTOP_X_BORDER);

    return getOffset(DESKTOP_WIDTH - DESKTOP_X_BORDER);
  };

  const rndY = () => {
    if (isMobile) return getOffset(MOBILE_HEIGHT - MOBILE_Y_BORDER);
    if (isTablet) return getOffset(TABLET_HEIGHT - TABLET_Y_BORDER);
    if (isDesktop) return getOffset(DESKTOP_HEIGHT - DESKTOP_Y_BORDER);

    return getOffset(DESKTOP_HEIGHT - DESKTOP_Y_BORDER);
  };

  const randomPosition = () => ({ top: rndY(), left: rndX() });

  // Float Glyph


  const getGlyph = () => {
    switch (type) {
      case 1:
      case 5:
      case 9:
        return (
          <>
            <Fg position={randomPosition()}>
              {/* @ts-ignore */}
              <Echi />
            </Fg>
            <Fg position={randomPosition()}><Spring /></Fg>
            <Fg position={randomPosition()}><Boxes /></Fg>
          </>
        );
      case 2:
      case 6:
      case 10:
        return (
          <>
            <Fg position={randomPosition()}><Crosshair /></Fg>
            <Fg position={randomPosition()}><SpringVertical /></Fg>
            <Fg position={randomPosition()}><Eye /></Fg>
          </>
        );
      case 3:
      case 7:
      case 11:
        return (
          <>
            <Fg position={randomPosition()}><StarBig /></Fg>
            <Fg position={randomPosition()}><CeltStar /></Fg>
            <Fg position={randomPosition()}><Arc /></Fg>
            <Fg position={randomPosition()}><Cone /></Fg>
          </>
        );

      case 4:
      case 8:
      case 12:
      default: return (
        <>
          <Fg position={randomPosition()}><Combo /></Fg>
          <Fg position={randomPosition()}><CrosshairBox /></Fg>
          <Fg position={randomPosition()}><Rain /></Fg>
          <Fg position={randomPosition()}><Eclipse /></Fg>
        </>
      );
    }
  };

  return (
    <Container className={className} style={style}>
      {getGlyph()}
    </Container>
  );
}

// @ts-ignore
const Echi = styled(Echinus)`
  path {
    stroke: white;
  }
`;

const Glyph = styled.div``;

const Container = styled.div`
  position: relative;

  ${Glyph} {
    position: absolute;
  }
`;

export {
  GlyphImage,
  Container as GlyphImageStyled,
};
