import React from 'react';
import { styled } from '@linaria/react';

import { ActivityFiles } from 'components/activity/ActivityFiles';
import { ActivityPlace } from 'components/activity/ActivityPlace';
import { SpeakerProps } from 'components/activity-speakers/ActivitySpeaker';
import { ActivitySpeakers } from 'components/activity-speakers/ActivitySpeakers';


type Props = {
  start_date: string
  end_date: string
  price?: number
  price_visible?: boolean
  place?: string
  speakers?: SpeakerProps[]
  files?: string[]
  pubDate: string
  link_to_entry: string
};

export function ActivityInfo({
  start_date,
  end_date,
  pubDate,
  price,
  price_visible,
  place,
  speakers = [],
  files = [],
  link_to_entry = '',
}: Props) {
  return (
    <Container>
      <ActivityPlace
        pubDate={pubDate}
        start_date={start_date}
        end_date={end_date}
        price={price}
        price_visible={price_visible}
        place={place}
        link_to_entry={link_to_entry}
      />
      { speakers?.length > 1
        && (
          <ActivitySpeakers
            list={speakers}
          />
        )}
      { files?.length > 1
        && (
          <ActivityFiles
            list={files}
          />
        )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

