import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ISection } from 'models/ISection';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit?: number;
  offset?: number;
  sort?: string;
  system?: string;
}

export const sectionService = createApi({
  reducerPath: 'sectionService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Section'],
  endpoints: (build) => ({
    fetchAllSection: build.query<ISection[], QueryProps>({
      query: ({
        limit = 100, offset = 0, sort = '-created_at', system,
      }) => ({
        url: '/section/all',
        params: {
          limit,
          offset,
          sort,
          system,
        },
      }),
    }),
  }),
});
