import React, { useState, useEffect } from 'react';
import { styled, CSSProperties } from '@linaria/react';

import { Tag, TagCSS, TagTextCSS } from 'components/common/Tag';
import { device } from 'styles/devices';


export type GroupProps = {
  id: string | number
  text: string
  active?: boolean
};

type Props = {
  list: GroupProps[]
  selectGroup?: Function
  className?: string
  style?: CSSProperties
};

export function GroupsBar({
  list, selectGroup, className, style,
}: Props) {
  const [groups, setGroups] = useState<GroupProps[]>([]);

  useEffect(() => {
    if (Array.isArray(list) && list.length) {
      setGroups(list);
    }
  }, [list]);

  const toggleTab = (group: GroupProps) => {
    const newGroups = groups.map((mapGroup) => {
      mapGroup.active = mapGroup.id === group.id;

      return mapGroup;
    });

    setGroups(newGroups);

    if (selectGroup) { selectGroup(group); }
  };

  return (
    <Tags className={className} style={style}>
      {
        groups.map((tag) => (
          <Tag
            text={tag.text}
            level=""
            desktopFont="w500 s18 h24"
            tabletFont="w500 s16 h24"
            mobileFont="w500 s16 h24"
            active={tag.active}
            onClick={() => toggleTab(tag)}
            key={tag.id}
          />
        ))
      }
    </Tags>
  );
}

export const Tags = styled.div`
  display: flex;

  .${TagCSS}:not(.active) {
    background-color: transparent;
    border-color: var(--el-active);
    cursor: pointer;

    .${String(TagTextCSS)} {
      color: var(--brand-primary);
    }
  }

  @media ${device.mobile}, ${device.minMobile} {
    .${TagCSS} {
      margin: 0;
      padding: 6px 20px;
    }
  }

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    gap: 10px;
  }

  @media ${device.desktopS} {
    gap: 16px;
  }
`;
