import React from 'react';
import { styled } from '@linaria/react';

import { ReactComponent as EchinusSvg } from 'assets/particles/echinus.svg';


type Props = {
  top?: string
  left?: string
  right?: string
  bottom?: string
};

function Echinus(props: Props) {
  return (
    <Span {...props}>
      <EchinusSvg />
    </Span>
  );
}

export { Echinus };

const Span = styled.span<Props>`
  display: inline-block;
  position: absolute;
  top: ${({ top }) => top || ''};
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
`;
