/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Rain() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(90, 60, 3);
    if (isTablet) return glyphSizes(140, 110, 2);
    if (isDesktop) return glyphSizes(306, 221);

    return glyphSizes(306, 221);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M1 125L268 -71" stroke="white" />
      <path d="M37 173L306 -24" stroke="white" />
      <path d="M72 220L340 23" stroke="white" />
    </SVGIcon>
  );
}
