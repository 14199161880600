import React from 'react';
import { styled } from '@linaria/react';

// import { device } from 'styles/devices';

import {
  ArticleArchiveBlock,
  Props as ArticleProps,
} from 'components/article-archive/ArticleArchiveBlock';


type Props = {
  list: ArticleProps[]
};

export function ArticleArchiveList({ list }:Props) {
  if (!Array.isArray(list) || !list.length) {
    return null;
  }

  return (
    <List>
      {
        list.map(({ _id, ...props }) => (
          <ArticleArchiveBlock {...props} _id={_id} key={_id} />
        ))
      }
    </List>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

