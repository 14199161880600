/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Crosshair() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(31, 31, 3);
    if (isTablet) return glyphSizes(41, 41, 2);
    if (isDesktop) return glyphSizes(80, 80);

    return glyphSizes(80, 80);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M40.2855 63.6275C53.3002 63.6275 63.8359 53.0917 63.8359 40.077C63.8359 27.0623 53.3002 16.5266 40.2855 16.5266C27.2708 16.5266 16.7351 27.0623 16.7351 40.077C16.7351 53.0917 27.2708 63.6275 40.2855 63.6275ZM40.2855 18.1793C52.4739 18.1793 62.3899 28.0952 62.3899 40.2836C62.3899 52.472 52.4739 62.388 40.2855 62.388C28.0971 62.388 18.1812 52.472 18.1812 40.2836C18.1812 27.8887 28.0971 18.1793 40.2855 18.1793Z" fill="#EFEFE8" />
      <path d="M39.2516 0H40.9043V80.3607H39.2516V0Z" fill="#EFEFE8" />
      <path d="M0.000671387 39.4575H80.3613V41.1101H0.000671387V39.4575Z" fill="#EFEFE8" />
    </SVGIcon>
  );
}
