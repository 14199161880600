import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { IQuestion } from '../../models/IQuestion';
import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit?: number;
  offset?: number;
  sort?: string;
  system?: string;
  section?: string;
  question?: string;
}

export const questionService = createApi({
  reducerPath: 'questionService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Question'],
  endpoints: (build) => ({
    fetchAllQuestion: build.query<IQuestion, QueryProps>({
      query: ({
        limit = 100, offset = 0, sort = 'sort_priority', system, section, question,
      }) => ({
        url: '/question/all',
        params: {
          limit,
          offset,
          sort,
          system,
          section,
          question,
        },
      }),
    }),
  }),
});
