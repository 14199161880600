import {
  useSelector as useSelectorReactRedux, TypedUseSelectorHook,
} from 'react-redux';

import { Store } from 'stores/store.types';


export const useSelector: TypedUseSelectorHook<Store> = (
  selector,
) => useSelectorReactRedux(selector);
