import React from 'react';
import { styled } from '@linaria/react';

// import { device } from 'styles/devices';

// import { Button, ButtonCSS } from 'components/common/Button';
import { GroupsBar } from 'components/GroupsBar';
import { SearchBar } from 'components/SearchBar';
import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';

import tags from './tags.json';


type ActivityAfficheInfoProps = {
  searchChange: (value: string) => void;
};

export function ActivityAfficheInfo(props: ActivityAfficheInfoProps) {
  const { searchChange } = props;

  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s60 h78"
      >
        Афиша событий
      </Title>
      <SubTitle color="thirdly">
        Будьте в курсе всех предстоящих мероприятий
      </SubTitle>
      {/* <Buttons>
        <Button
          type='primary'
          textColor='white'
        >
          Подписаться на рассылку
        </Button>
      </Buttons> */}
      <SearchBar setSearchValue={searchChange} />
      <Tags list={tags} />
    </Container>
  );
}

const Title = styled(TitleC)`
`;

const SubTitle = styled(TextC)`
  margin: 16px 0 32px;
`;

// const Buttons = styled.div`
//   margin-bottom: 76px;
//
//   .${ButtonCSS} {
//     border-radius: 16px;
//   }
// `;

const Tags = styled(GroupsBar)`
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
