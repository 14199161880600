import React from 'react';

import { BlurSpot } from 'components/particles/BlurSpot';
import { useSelector } from 'utils/use-selector';

import { CeltStar } from './particles/CeltStar';
import { Echinus } from './particles/Echinus';
import { HollowCross } from './particles/HollowCross';
import { SmallCirlce } from './particles/SmallCircle';


export function Particles() {
  const { isMobile, mobileType } = useSelector((state) => state.common);

  return (
    <div>
      <BlurSpot color="#0000FF80" top="50vh" />
      <BlurSpot color="#A020F080" right="0px" />
      <BlurSpot color="#0000FF80" top="260vh" left="-100px" />
      <BlurSpot color="#A020F080" right="0px" top="190vh" />
      <BlurSpot color="#0000FF80" top="460vh" left="-100px" />
      <BlurSpot color="#A020F080" right="0px" top="380vh" />
      {
        !isMobile && (
          <>
            <HollowCross right="40vw" top="10vh" />
            <CeltStar left="8vw" top="70vh" />
            <Echinus right="4vw" top="20vh" />
            <SmallCirlce top="45vh" right="25vw" />
          </>
        )
      }
      {
        isMobile && mobileType === 'tablet' && (
          <CeltStar left="5vw" top="90vh" />
        )
      }
    </div>
  );
}
