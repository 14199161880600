import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';

import { FeatureWrapper as Wrapper } from 'components/FeatureWrapper';
import { GroupsBar } from 'components/GroupsBar';
import { Slider } from 'components/Slider';
import { activityService } from 'stores/activity/activityService';
import { device } from 'styles/devices';
import { useSelector } from 'utils/use-selector';
import NewsPoster from 'assets/images/news-poster.png';

// import { activityService } from '../../stores/activity/activityService';
import { NewsList } from './NewsList';

import { newsService } from '../../stores/news/newsService';


const TAGS = [
  { text: 'новости', id: 0, active: true },
  { text: 'мероприятия', id: 1, active: false },
];

// type Props = Array<NewsItemProps>

// TODO: перенести все фичи в FeatureWrapper
function News() {
  const { mobileType } = useSelector((state) => state.common);
  const [isNews, setIsNews] = useState<boolean>(true);
  const { data: news } = newsService.useFetchAllNewsQuery({ limit: 5, skip: 0 });
  const { data: activities } = activityService.useFetchAllActivityQuery({ limit: 5, offset: 0 });

  const [currentData, setCurrentData] = useState<any>();
  // const [currentImage, setCurrentImage] = useState<any>();

  useEffect(() => {
    if (news) {
      setCurrentData(news);
      // setCurrentImage(news[0]?.desktop_cover)
    }
  }, [news]);

  const changeData = (tag: typeof TAGS[0]) => {
    switch (tag.text) {
      case 'мероприятия': {
        setCurrentData(activities);
        setIsNews(false);

        return;
      }

      case 'новости': {
        setCurrentData(news);
        setIsNews(true);

        return;
      }

      default: {
        setCurrentData(news);
        setIsNews(true);
        // news && setCurrentImage(news[0].desktop_cover)
      }
    }
  };

  return (
    <Wrapper title="Новости">
      <Header>
        <Slider active={mobileType === 'phone'}>
          <GroupsBar list={TAGS} selectGroup={changeData} />
        </Slider>
      </Header>
      <Content>
        <ImageWrapper>
          <Image src={NewsPoster} alt="" />
          {/* {currentImage && <Image src={currentImage} alt="" />}  */}
        </ImageWrapper>
        <NewsList list={currentData} isNewsPath={isNews} />
      </Content>
    </Wrapper>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const Image = styled.img`
  border-radius: 24px;
  width: 100%;
`;

const ImageWrapper = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    max-width: 100%;
  }
  
  @media ${device.tablet} {
    max-height: 332px;
    max-width: 500px;
    min-width: 500px;
  }
`;

const Content = styled.div`
  margin-top: 32px;
  display: flex;

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: column;
    gap: 40px;
  }

  @media ${device.tablet}, ${device.desktopS} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    gap: 30px;
  }

  @media ${device.desktopS} {
    gap: 80px;
  }
`;

export { News };
