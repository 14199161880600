import React from 'react';
import { styled } from '@linaria/react';

// import { AboutPlatform } from 'components/about-platform/AboutPlatform';

import { Activities } from 'components/activities/Activities';
import { ButtonSupport } from 'components/buttons/ButtonSupport';
import { CatalogApp } from 'components/catalog-app/CatalogApp';
import { Education } from 'components/education/Education';
import { Events } from 'components/events/Events';
import { Layout } from 'components/layout/Layout';
import { News } from 'components/news/News';
import { PopularCourses } from 'components/popular-courses/PopularCourses';
// import { ContactUs } from 'components/contact-us/ContactUs';
import { ViewHeader } from 'components/ViewHeader';
import { ViewWrapper } from 'components/ViewWrapper';
import { device } from 'styles/devices';


export function Home() {
  return (
    <Layout>
      <ViewWrapper>
        <BtnSupport url="/support" />
        <ViewHeader />
        <Content>
          {/* <AboutPlatform /> */}
          <Activities />
          <Events />
          <News />
          <CatalogApp />
          <PopularCourses />
          <Education />
          {/* <ContactUs /> */}
        </Content>
      </ViewWrapper>
    </Layout>
  );
}

const BtnSupport = styled(ButtonSupport)`
  position: absolute;
  right: calc(28px + 16px);
  top: 28px;
  @media ${device.minMobile}{
   right: 10px;
   top: 20px;
  }
  @media ${device.mobile}{
   right: 20px;
   top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${device.mobile}, ${device.minMobile} {
    padding-top: 112px;
    gap: 60px;
  }

  @media ${device.tablet} {
    padding-top: 46px;
    gap: 80px;
  }
  
  @media ${device.desktopS} {
    gap: 136px;
  }
`;
