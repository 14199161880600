import React from 'react';
import { styled } from '@linaria/react';

// import { device } from 'styles/devices';

import { Tag } from 'components/common/Tag';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';
import { toHumanDate, toHumanTime } from 'utils/to-human-date';


export type BlockProps = {
  // tag: string
  // banner: string
  // title: string
  // date: string
  activity: IActivity;
};

const getColor = (text?: string) => {
  switch (text) {
    case 'интенсив': return 'var(--status-error)';
    case 'обучение': return 'var(--status-warning)';
    case 'семинар': return 'var(--status-green)';
    case 'тренинг': return 'var(--colors-helsinki)';
    case 'вебинар': return 'var(--colors-prague)';
    default: return 'var(--status-error)';
  }
};

export function NearestActivitiesBlock({
  // tag, banner, title, date
  activity,
}: BlockProps) {
  const [activityTag] = activity.tags;

  return (
    <Link href={`/activity/${activity._id}`} target="_blank">
      <Container>
        <Banner>
          <img src={activity.desktop_cover} alt="" />
        </Banner>
        <Content>
          {activityTag
            && (
              <Tag
                text={activityTag}
                color={getColor()}
              />
            )}
          <Title
            level=""
            desktopFont="w700 s24 h28"
          >
            {activity.title}
          </Title>
          <Date>{toHumanDate(activity.start_date)} в {toHumanTime(activity.start_date)}</Date>
        </Content>
      </Container>
    </Link>

  );
}

const Link = styled.a`
  cursor: pointer;
  width: 492px;
  height: 464px;
`;

const Banner = styled.div`
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 492px;
  height: 240px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
  }
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  color: #909EBB;
  font-weight: 500;
`;

const Title = styled(TitleC)`
  margin: 16px 0 20px;
  word-break: break-word;
`;

const Content = styled.div`
  padding: 24px 32px 32px;
  // set date to bottom pt.1
  display: flex;
  flex-direction: column;

`;

const Container = styled.div`
  border-radius: 24px;
  background-color: white;
  height: 100%;
`;
