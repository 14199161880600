import {
  CalendarFilled,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
import DOMPurify from 'dompurify';

import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';
// import { GroupsBar, GroupProps } from 'components/GroupsBar';
// import { ArticleSocial } from 'components/articles/ArticleSocial';
import { device, media } from 'styles/devices';


type Props = {
  title: string
  date?: string
  // time?: string
  banner?: string
  content: {
    text_top: string
    leftImg: string
    rightImg: string
    imagesDescription: string
    text_bottom: string
  }
};

export function ArticleData({
  title,
  date,
  banner,
  content,
}: Props) {
  const {
    text_top, leftImg, rightImg, imagesDescription, text_bottom,
  } = content;

  const purifiedTextTop = DOMPurify.sanitize(text_top);
  const purifiedTextBottom = DOMPurify.sanitize(text_bottom);

  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s40 h48"
        mobileFont="w800 s26 h32"
        tabletFont="w800 s40 h52"
      >
        {title}
      </Title>

      <TimeBlock>
        <CalendarFilled
          style={{ color: 'var(--text-gray)' }}
        />
        <Date
          level=""
          desktopFont="w500 s18 h24"
          mobileFont="w500 s14 h20"
          tabletFont="w500 s18 h24"
          color="thirdly"
        >
          {date}
        </Date>
      </TimeBlock>

      <Banner>
        <img src={banner} alt="" />
      </Banner>

      <Description>
        <TopText
          mobileFont="w400 s14 h20"
        >
          {/* eslint-disable-next-line @typescript-eslint/naming-convention,react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: purifiedTextTop }} />
        </TopText>
        <ImgBlock>
          <img src={leftImg} alt="" />
          <img src={rightImg} alt="" />
        </ImgBlock>
        <QuoteBlock>
          {imagesDescription}
        </QuoteBlock>
        <BottomText>
          {/* eslint-disable-next-line @typescript-eslint/naming-convention,react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: purifiedTextBottom }} />
        </BottomText>
      </Description>

      {/* <SocialLinks /> */}
    </Container>
  );
}

const Title = styled(TitleC)`
  /* margin: 40px 0 32px; */
  margin-bottom: 32px;
`;

const TimeBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Date = styled(TitleC)``;

// const Time = styled.div``;

const Banner = styled.div`
  margin-top: 32px;
`;

// const Text = styled(TextC)`
//   white-space: pre-line;
// `;

const TopText = styled(TextC)`
  display: inline-block;
  margin-top: 32px;
  white-space: pre-line;
`;

const ImgBlock = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 24px;
  align-items: center;

  ${media.mobileAndTablet} {
    flex-direction: column;
    align-items: center;
  }
  
  img {
    max-width: 50%;

    ${media.mobileAndTablet} {
      max-width: 100%;
    }
  }
`;

const QuoteBlock = styled.div`
  white-space: pre-line;
  margin: 32px 0;
  padding-left: 16px;
  border-left: 4px solid var(--brand-primary);
  color: var(--text-secondary);

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const BottomText = styled.div`
  white-space: pre-line;

  @media ${device.tabletS} {
    
  }
`;

const Description = styled.div``;

// const SocialLinks = styled(ArticleSocial)`
//   margin-top: 40px;
// `;

const Container = styled.div`
  max-width: 1016px;
`;
