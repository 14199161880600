import { createSlice } from '@reduxjs/toolkit';


export type CommonState = {
  isMobile: boolean
  mobileType?: 'tablet' | 'phone' | 'desktop'
};

type actionProps = {
  payload: CommonState
};

const initialState: CommonState = {
  isMobile: false,
  mobileType: 'desktop',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setMobileState: (state: CommonState, action: actionProps) => {
      if (action.payload.isMobile) {
        state.isMobile = action.payload.isMobile;
        state.mobileType = action.payload.mobileType;
      }
    },
  },
});

export const { setMobileState } = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
