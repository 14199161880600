/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function CrosshairBox() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(30, 30, 3);
    if (isTablet) return glyphSizes(50, 50, 2);
    if (isDesktop) return glyphSizes(61, 61);

    return glyphSizes(61, 61);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M45.9172 15.0824H15.082V45.9176H45.9172V15.0824Z" fill="#0071CE" />
      <path d="M31.1703 29.8297C31.1703 14.5797 31.1703 1.00549 31.1703 0.167582L30.5 0L29.9972 0C29.8297 0.67033 29.8297 15.0824 29.8297 29.8297C16.2555 29.8297 2.8489 29.8297 0 29.8297V31.0027C8.21154 31.0027 19.272 31.0027 29.8297 31.0027C29.8297 44.5769 29.8297 57.9835 29.8297 61H31.0027C31.0027 52.7885 31.0027 41.5604 31.0027 31.0027C46.2527 31.0027 59.9945 31.0027 60.6648 31.0027L60.8324 30.3324L61 29.6621C60.3297 29.8297 45.9176 29.8297 31.1703 29.8297Z" fill="#3B4168" />
    </SVGIcon>
  );
}
