import React, { CSSProperties } from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';


interface Props {
  className?: string;
  children: React.ReactNode;
  style?: CSSProperties | undefined;
}
export function Tile({ children, className, style }: Props) {
  return (
    <Container
      className={className}
      style={style}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
background: var(--bg-secondary);
padding: 32px 16px;
border-radius: 8px;
margin-bottom: 32px;
  @media ${device.tablet} {
    padding: 30px 24px;
  }
  @media ${device.desktopS} {
    padding: 32px;
  }
`;
