import React from 'react';
import { styled } from '@linaria/react';


type Props = {
  top?: string
  left?: string
  right?: string
  bottom?: string
};

function SmallCirlce(props: Props) {
  return (
    <Span {...props} />
  );
}
 
export { SmallCirlce };

const Span = styled.span<Props>`
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid var(--text-gray);
  border-radius: 50%;
  position: absolute;
  top: ${({ top }) => top || ''};
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
`;
