import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';
import { device } from 'styles/devices';

import data from './data.json';
import { PortfolioItem } from './PortfolioItem';


export function PortfolioInfo() {
  return (
    <>
      <StyledHeading level={2}>Примеры успешных решений</StyledHeading>
      <PortfolioList>
        {data.map((portfolioItem) => (
          <PortfolioItem {...portfolioItem} key={portfolioItem.id} />
        ))}
      </PortfolioList>
    </>
  );
}

const StyledHeading = styled(Heading)`
  && {
    margin-bottom: 10px;
  }
`;

const PortfolioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet} {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 24px;
  }
  @media ${device.desktopS} {
    grid-gap: 30px;
  }
`;
