import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ICourse } from 'models/ICourse';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit: number;
  skip: number;
}

export const courseService = createApi({
  reducerPath: 'courseService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Course'],
  endpoints: (build) => ({
    fetchAllCourse: build.query<ICourse[], QueryProps>({
      query: ({ limit = 100, skip = 0 }) => ({
        url: '/course/all',
        params: {
          limit,
          skip,
        },
      }),
      providesTags: () => ['Course'],
    }),
  }),
});
