import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';
import { device } from 'styles/devices';


interface GeneralInfoCardProps {
  title: string;
  text: string;
  bgUrl: string;
}

export function GeneralInfoCard({
  title, text, bgUrl,
} : GeneralInfoCardProps) {
  return (
    <CardContainer style={{ backgroundImage: `url(${bgUrl})` }}>
      <StyledHeading level={1}>{title}</StyledHeading>
      <p>{text}</p>
    </CardContainer>
  );
}

const StyledHeading = styled(Heading)`
  && {
  color: var(--bg-secondary);
  }
`;

const CardContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;
    color: var(--bg-secondary);
    padding: 16px;
    min-height: 200px;
    margin: 0 15px;
    
    @media ${device.tablet} {
      padding: 28px;
      > p {
        font-size: 16px;
        line-height: 20px;
      }
    }
    @media ${device.desktopS} {
      margin: 0;
      padding: 32px;
    }
    
    > p{
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
`;
