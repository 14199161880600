/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function SpringVertical() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(44, 75, 3);
    if (isTablet) return glyphSizes(59, 101, 2);
    if (isDesktop) return glyphSizes(170, 206);

    return glyphSizes(170, 206);
  };

  return (
    <SVGIcon {...getSizes()} color="none">
      <path d="M167.5 29.5C167.5 33.3653 165.271 37.1181 161.079 40.598C156.891 44.0755 150.801 47.2295 143.231 49.8879C128.096 55.2032 107.155 58.5 84 58.5C60.8451 58.5 39.9037 55.2032 24.7687 49.8879C17.1989 47.2295 11.1086 44.0755 6.92054 40.598C2.72941 37.1181 0.5 33.3653 0.5 29.5C0.5 25.6347 2.72941 21.8819 6.92054 18.402C11.1086 14.9245 17.1989 11.7705 24.7687 9.1121C39.9037 3.79684 60.8451 0.5 84 0.5C107.155 0.5 128.096 3.79684 143.231 9.1121C150.801 11.7705 156.891 14.9245 161.079 18.402C165.271 21.8819 167.5 25.6347 167.5 29.5Z" stroke="#F5F6F8" />
      <path d="M167.5 59.5C167.5 63.3653 165.271 67.1181 161.079 70.598C156.891 74.0755 150.801 77.2295 143.231 79.8879C128.096 85.2032 107.155 88.5 84 88.5C60.8451 88.5 39.9037 85.2032 24.7687 79.8879C17.1989 77.2295 11.1086 74.0755 6.92054 70.598C2.72941 67.1181 0.5 63.3653 0.5 59.5C0.5 55.6347 2.72941 51.8819 6.92054 48.402C11.1086 44.9245 17.1989 41.7705 24.7687 39.1121C39.9037 33.7968 60.8451 30.5 84 30.5C107.155 30.5 128.096 33.7968 143.231 39.1121C150.801 41.7705 156.891 44.9245 161.079 48.402C165.271 51.8819 167.5 55.6347 167.5 59.5Z" stroke="#F5F6F8" />
      <path d="M167.5 88.5C167.5 92.3653 165.271 96.1181 161.079 99.598C156.891 103.075 150.801 106.229 143.231 108.888C128.096 114.203 107.155 117.5 84 117.5C60.8451 117.5 39.9037 114.203 24.7687 108.888C17.1989 106.229 11.1086 103.075 6.92054 99.598C2.72941 96.1181 0.5 92.3653 0.5 88.5C0.5 84.6347 2.72941 80.8819 6.92054 77.402C11.1086 73.9245 17.1989 70.7705 24.7687 68.1121C39.9037 62.7968 60.8451 59.5 84 59.5C107.155 59.5 128.096 62.7968 143.231 68.1121C150.801 70.7705 156.891 73.9245 161.079 77.402C165.271 80.8819 167.5 84.6347 167.5 88.5Z" stroke="#F5F6F8" />
      <path d="M167.5 117.5C167.5 121.365 165.271 125.118 161.079 128.598C156.891 132.075 150.801 135.229 143.231 137.888C128.096 143.203 107.155 146.5 84 146.5C60.8451 146.5 39.9037 143.203 24.7687 137.888C17.1989 135.229 11.1086 132.075 6.92054 128.598C2.72941 125.118 0.5 121.365 0.5 117.5C0.5 113.635 2.72941 109.882 6.92054 106.402C11.1086 102.925 17.1989 99.7705 24.7687 97.1121C39.9037 91.7968 60.8451 88.5 84 88.5C107.155 88.5 128.096 91.7968 143.231 97.1121C150.801 99.7705 156.891 102.925 161.079 106.402C165.271 109.882 167.5 113.635 167.5 117.5Z" stroke="#F5F6F8" />
      <path d="M167.5 147.5C167.5 151.365 165.271 155.118 161.079 158.598C156.891 162.075 150.801 165.229 143.231 167.888C128.096 173.203 107.155 176.5 84 176.5C60.8451 176.5 39.9037 173.203 24.7687 167.888C17.1989 165.229 11.1086 162.075 6.92054 158.598C2.72941 155.118 0.5 151.365 0.5 147.5C0.5 143.635 2.72941 139.882 6.92054 136.402C11.1086 132.925 17.1989 129.771 24.7687 127.112C39.9037 121.797 60.8451 118.5 84 118.5C107.155 118.5 128.096 121.797 143.231 127.112C150.801 129.771 156.891 132.925 161.079 136.402C165.271 139.882 167.5 143.635 167.5 147.5Z" stroke="#F5F6F8" />
      <path d="M167.5 176.5C167.5 180.365 165.271 184.118 161.079 187.598C156.891 191.075 150.801 194.229 143.231 196.888C128.096 202.203 107.155 205.5 84 205.5C60.8451 205.5 39.9037 202.203 24.7687 196.888C17.1989 194.229 11.1086 191.075 6.92054 187.598C2.72941 184.118 0.5 180.365 0.5 176.5C0.5 172.635 2.72941 168.882 6.92054 165.402C11.1086 161.925 17.1989 158.771 24.7687 156.112C39.9037 150.797 60.8451 147.5 84 147.5C107.155 147.5 128.096 150.797 143.231 156.112C150.801 158.771 156.891 161.925 161.079 165.402C165.271 168.882 167.5 172.635 167.5 176.5Z" stroke="#F5F6F8" />
    </SVGIcon>
  );
}
