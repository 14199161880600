import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Result } from 'antd';

import { device } from 'styles/devices';
import foundNothingBGRight from 'assets/images/found-nothing/bg-right.svg';

import { Button } from './common/Button';
import { Heading } from './Heading';


export function FoundNothing() {
  return (
    <Result
      icon={' '}
      title={(
        <StyledHeading level={3}>
          Если вы не нашли подходящее решение, оставьте заявку, и мы подберём для
          вас курсы или программу
        </StyledHeading>
      )}
      extra={<Button type="primary" textColor="white">Оставить заявку</Button>}
      className={foundNothing}
      style={{ backgroundImage: `url(${foundNothingBGRight})` }}
    />
  );
}

const foundNothing = css`
  background-repeat: no-repeat;
  background-position: 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    background-position: 140% 0;
  }
  @media ${device.desktopS}{
    background-position: 100% 0;
  }

  .ant-result-title {
    max-width: 900px;
    top: 0;
    .ant-typography{
        margin-bottom: 30px;
    }
  }
  .ant-result-icon{
    display: none;
  }

  .ant-result-extra {
    margin-top: 0;
  }
`;

const StyledHeading = styled(Heading)`
 
  @media ${device.tablet}{
    font-size: 22px;
    line-height: 28px;
  }
  margin-bottom: 30px;
  @media ${device.tablet}{
    padding: 0 100px;
  }
  @media ${device.desktopS}{
    padding: 0 0;
  }  
`;
