import React, { useEffect, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { styled } from '@linaria/react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';

import { CommonCarousel }
  from 'components/common/common-carousel/CommonCarousel';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';
import companyOne from 'assets/images/clients/1.png';
import companyTwo from 'assets/images/clients/2.png';
import companyThree from 'assets/images/clients/3.png';
import companyFour from 'assets/images/clients/4.png';
import companyFive from 'assets/images/clients/5.png';
import companySix from 'assets/images/clients/6.png';
import companySeven from 'assets/images/clients/7.jpg';
import companyEight from 'assets/images/clients/8.png';


const clientsList = [
  companyOne,
  companyTwo,
  companyThree,
  companyFour,
  companyFive,
  companySix,
  companySeven,
  companyEight,
];

export function ClientsCarousel() {
  const { isMobile } = useBreakpoints();
  const [isTablet, setIsTablet] = useState(0);

  useEffect(() => {
    function updateDevice() {
      const { clientWidth } = document.documentElement;
      // @ts-ignore
      setIsTablet(clientWidth >= 992 && clientWidth < 1600);
    }

    window.addEventListener('resize', updateDevice);
    updateDevice();

    return () => window.removeEventListener('resize', updateDevice);
  }, []);

  const slidesPerRow = isTablet ? 4 : 5;
  const ref = useRef<CarouselRef>(null);
  const onPrevClickHandler = () => {
    if (ref.current) {
      ref.current.prev();
    }
  };

  const onNextClickHandler = () => {
    if (ref.current) {
      ref.current.next();
    }
  };

  const list = clientsList.map((url, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={index}>
      <ClientItem>
        <Img src={url} alt="" />
      </ClientItem>
    </div>
  ));

  return !isMobile
    ? (
      <CarouselContainer>
        <LeftOutlined onClick={onPrevClickHandler} />
        <CarouselContainerInner>
          <Carousel ref={ref} slidesPerRow={slidesPerRow} dots={false} infinite>
            {list}
          </Carousel>
        </CarouselContainerInner>
        <RightOutlined onClick={onNextClickHandler} />
      </CarouselContainer>
    )
    : <CommonCarousel items={list} slidesPerRow={2} dots showProgress={false} />;
}

const CarouselContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 30px;
`;

const CarouselContainerInner = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

const ClientItem = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 110px;
  margin: 0 5px;
  border: 1px solid var(--el-lines);
  border-radius: 8px;
  @media ${device.tablet} {
    height: 162px;
    width: 210px;
  }
  @media ${device.desktopS}{
    height: 205px;
    width: 268px;
  }
`;

const Img = styled.img`
  max-width: 80%;
  max-height: 80%;
`;
