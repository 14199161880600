import React from 'react';
import {
  CalendarFilled,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

// import { device } from 'styles/devices';
import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';


export type ArticleProps = {
  _id: string
  date: string
  // source: string
  title: string
};

export function Article({
  _id, date, title,
}: ArticleProps) {
  return (
    <Container>
      <Header>
        <DateWrapper>
          <CalendarFilled style={{ color: 'var(--text-gray)' }} />
          <Date color="thirdly">
            {dayjs(date).format('DD.MM.YYYY')}
          </Date>
        </DateWrapper>
        {/* <Source color='thirdly'>{source}</Source> */}
      </Header>
      <Title
        level=""
        desktopFont="w500 s18 h24"
      >
        <LinkBlack to={`/news/${_id}`}>{title}</LinkBlack>
      </Title>
    </Container>
  );
}

const Date = styled(TextC)``;

const DateWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

// const Source = styled(TextC)``;

const LinkBlack = styled(Link)`
  color: inherit;
`;

const Header = styled.div`
  display: flex;
  gap: 24px;
`;

const Title = styled(TitleC)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
