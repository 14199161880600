import React from 'react';
import { styled } from '@linaria/react';

import { Title, TitleCSS, Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';
import { navigateTo } from 'utils/navigation';


type Props = {
  logo: string
  title: string
  description: string
  link?: string
};

function CatalogAppBlock({
  logo, title, description, link,
}: Props) {
  return (
    <Container onClick={() => navigateTo(link)}>
      <CompanyLogo>
        <img src={logo} alt="" />
      </CompanyLogo>
      <Title
        level=""
        desktopFont="w700 s30 h40"
        tabletFont="w600 s24 h28"
        mobileFont="w600 s24 h28"
      >
        {title}
      </Title>
      <Description
        color="secondary"
        level=""
        desktopFont="w500 s18 h24"
        tabletFont="w500 s16 h24"
        mobileFont="w500 s16 h24"
      >
        {description}
      </Description>
    </Container>
  );
}

const CompanyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    height: 142px;

    img {
      height: 72px;  
    }
  }

  @media ${device.tablet} {
    margin-bottom: 20px;
  }

  @media ${device.desktopS} {
    height: 162px;
    margin-bottom: 48px;

    img {
      height: 104px;
    }
  }
`;

const Description = styled(TitleC)`
  overflow-x: hidden;
`;

const Container = styled.div`
  border-radius: 24px;
  background-color: white;
  cursor: pointer;
  flex: 1 1 0;

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    padding: 20px;
  }

  @media ${device.mobile}, ${device.minMobile} {
    min-width: 92%;
  }

  @media ${device.tablet} {
    min-width: initial;

    .${TitleCSS}:last-child {
      margin-top: 8px;
    }
  }

  @media ${device.desktopS} {
    padding: 40px;
    height: 520px;

    .${TitleCSS}:last-child {
      margin-top: 16px;
    }
  }
`;

export { CatalogAppBlock };
export type { Props as CatalogAppBlockProps };
