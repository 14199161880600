import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { ArticleData } from 'components/articles/ArticleData';
import { ArticleTop } from 'components/articles/ArticleTop';
import { Breadcrumbs } from 'components/Breadcrumbs';
// import {
//   SimilarEvents as SimilarEventsC
// } from 'components/similar-events/SimilarEvents';
import { Layout } from 'components/layout/Layout';
import { device, media } from 'styles/devices';

import { newsService } from '../stores/news/newsService';
import { useBreakpoints } from '../utils/useBreakpoints';


export function Article() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const { id: newsId } = useParams();
  const { data: currentNews, isLoading } = newsService.useGetNewsByIdQuery(newsId || '');
  const { data: news } = newsService.useFetchAllNewsQuery({ limit: 3, skip: 0 });

  const [content, setContent] = useState<any>();
  const [banner, setBanner] = useState<any>();
  const [topArticles, setTopArticles] = useState<any>();

  useEffect(() => {
    if (currentNews) {
      const {
        images, text_top, text_bottom, images_description,
      } = currentNews;

      setContent({
        text_top,
        leftImg: images[0],
        rightImg: images[1],
        imagesDescription: images_description,
        text_bottom,
      });

      setTopArticles(news?.slice(0, 3));
    }
  }, [currentNews, news]);


  useEffect(() => {
    if (currentNews) {
      const { mobile_cover, desktop_cover } = currentNews;

      if (isMobile || isTablet) {
        setBanner(mobile_cover);
      }

      if (isDesktop) {
        setBanner(desktop_cover);
      }
    }
  }, [currentNews, isMobile, isTablet, isDesktop]);

  if (isLoading || !currentNews || !content) {
    return null;
  }

  const { title, date } = currentNews;

  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['Новости', 'Новость']}
          links={['/news']}
        />
        <Content>
          <ArticleData
            title={title}
            date={dayjs(date).format('DD.MM.YYYY HH:MM')}
            banner={banner}
            content={content}
          />
          <ArticleTop
            list={topArticles}
          />
        </Content>
        {/* <SimilarEvents
          title='Похожие статьи'
          list={similarArticles}
        /> */}
      </Container>
    </Layout>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 100px;

  ${media.mobileAndTablet} {
    flex-direction: column;
    padding-bottom: 20px;
  }

  @media ${device.mobile} {
  }

  @media ${device.tablet} {
  }
`;

/*
const SimilarEvents = styled(SimilarEventsC)`
  margin-bottom: 100px;
`;
*/

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
    padding: 20px;
  }

  @media ${device.tabletS} {
    padding: 32px 60px;
  }
  
  @media ${device.desktopS} {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  }
`;
