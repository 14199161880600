import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { IActivity } from 'models/IActivity';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit?: number;
  offset?: number;
  title?: string;
  skip?: number;
}

export const activityService = createApi({
  reducerPath: 'activityService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Activity'],
  endpoints: (build) => ({
    fetchAllActivity: build.query<IActivity[], QueryProps>({
      query: ({ limit = 100, offset = 0, title = '' }) => ({
        url: '/activity/all',
        params: {
          limit,
          offset,
          title,
          sort: '-start_date',
        },
      }),
      providesTags: () => ['Activity'],
    }),
    getActivityById: build.query<IActivity, string>({
      query: (id) => `/activity/get/${id}`,
    }),
  }),
});
