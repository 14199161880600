import React from 'react';
import { styled } from '@linaria/react';

import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';


export type SpeakerProps = {
  _id: string;
  fullname: string;
  appointment: string;
  photo_id: string;
};

export function ActivitySpeaker({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _id,
  appointment,
  fullname,
  photo_id,
}: SpeakerProps) {
  return (
    <Container>
      <Image>
        <img src={photo_id} alt="" />
      </Image>
      <Title
        level=""
        desktopFont="w600 s20 h24"
      >
        {fullname}
      </Title>
      <Text
        color="secondary"
      >
        {appointment}
      </Text>
    </Container>
  );
}

const Image = styled.div`
  grid-row: 1 / span 2;
`;

const Text = styled(TextC)`
  grid-column: 2;
  white-space: pre-line;
`;

const Title = styled(TitleC)`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  column-gap: 24px;
`;
