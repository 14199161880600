import React from 'react';
import Icon from '@ant-design/icons';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';
import { getFileName } from 'utils/get-file-name';
import {
  ReactComponent as DownloadIcon,
} from 'assets/icons/download.svg';


export type FileProps = {
  id: string | number,
  name: string,
  link: string
};

type Props = {
  list: string[]
};

export function ActivityFiles({ list }: Props) {
  return (
    <Container>
      <Header>
        <Title
          level=""
          desktopFont="w800 s24 h30"
        >
          Файлы
        </Title>
      </Header>
      <List>
        {
          Array.isArray(list)
          && list.map((file, index) => (
            <FileItem
              href={file}
              target="_blank"
              key={`file-${index}`}
            >
              <FileName
                level=""
                desktopFont="w600 s16 h24"
                color="brand"
              >
                {getFileName(file)}
              </FileName>
              <Icon
                component={DownloadIcon}
                style={{ fontSize: '24px' }}
              />
            </FileItem>
          ))
        }
      </List>
    </Container>
  );
}

const Title = styled(TitleC)`
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const FileName = styled(TitleC)`
`;

const FileItem = styled.a`
  padding: 14px 24px;
  border-radius: 12px;
  background-color: #F5FBFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Container = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
