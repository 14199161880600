import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';

import { Button, ButtonCSS } from 'components/common/Button';
import { FeatureWrapper } from 'components/FeatureWrapper';
import { GroupsBar } from 'components/GroupsBar';
import { Slider } from 'components/Slider';
import { Text } from 'components/Text';
import { device } from 'styles/devices';
import { useSelector } from 'utils/use-selector';

// import { default as courses } from 'stores/services/courses.json'
import { CoursesGroupProps } from './PopularCoursesBlock';
import { PopularCoursesList } from './PopularCoursesList';

import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { courseService } from '../../stores/course/courseService';


function PopularCourses() {
  const { isMobile, mobileType } = useSelector((state) => state.common);
  const { data: courses } = courseService.useFetchAllCourseQuery({ limit: 100, skip: 0 });
  const [tags, setTags] = useState<any>();
  const [groups, setGroups] = useState<any>();
  const [currentCourses, setCurrentCourses] = useState<any>();

  useEffect(() => {
    if (courses) {
      const uniqueCoursesGroups = [
        ...new Map(courses.map((item) => [item.tag, item])).values(),
      ];
      const uniqueCoursesTags = [
        ...new Map(courses.map((item) => [item.tag.title, item])).values(),
      ];


      const courseTags = uniqueCoursesTags.map((item, idx) => ({
        id: idx + 1,
        text: item.tag.title,
      }));


      const courseGroups = uniqueCoursesGroups.map((item, idx) => ({
        id: idx,
        text: item.title,
        active: idx === 0,
      }));

      // setCurrentCourses(courses?.filter((item)=>item.group === 'Все'));
      setCurrentCourses(courses.slice(0, 4));
      // setTags([{ id: 0,  text: 'Все', active: true }].concat(courseTags));
      setTags(courseTags);
      setGroups(courseGroups);
    }
  }, [courses]);

  const changeData = (group: CoursesGroupProps) => {
    const filteredCourses = courses?.filter((item) => item.tag.title === group.text);
    setCurrentCourses(filteredCourses);

    // if (tag.text === 'Все') {
    //   setCurrentCourses(courses);
    // } else {
    //   const filteredTags = courses?.filter((item)=>item.group === tag.text);
    //   setCurrentCourses(filteredTags);
    // }
  };

  return (
    <Wrapper title="Популярные курсы">
      <TagBarNav>
        <Slider active={mobileType === 'phone'}>
          <GroupsBar list={tags} selectGroup={changeData} />
        </Slider>
        {
          !isMobile && (
            <Link href="https://courses.unionepro.ru/courses">
              <Text
                type="buttons"
                color="brand"
              >
                Перейти в каталог курсов
              </Text>
              <span><ArrowRight /></span>
            </Link>
          )
        }
      </TagBarNav>
      <CoursesList list={currentCourses} groups={groups} tags={tags} />
      {
        isMobile
          ? (
            <Footer>
              <a href="https://courses.unionepro.ru/courses">
                <Button type="ghost" textColor="brand">
                  Перейти в каталог курсов
                </Button>
              </a>
            </Footer>
          )
          // eslint-disable-next-line react/jsx-no-useless-fragment
          : <></>
      }
    </Wrapper>
  );
}

const Link = styled.a`
  color: var(--brand-primary);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  cursor: pointer;
`;

const TagBarNav = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    margin-bottom: 24px;
  }

  @media ${device.desktopS} {
    margin-bottom: 32px;
  }
`;

const CoursesList = styled(PopularCoursesList)``;

const Footer = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 30px;

    a {
      width: 100%;

      .${ButtonCSS} {
        width: 100%;
        justify-content: center;
      }
    }
  }

  @media ${device.tablet} {
    margin-top: 24px;
  }
`;

const Wrapper = styled(FeatureWrapper)`
  ${CoursesList} {
    margin-top: 20px;
  }
`;

export { PopularCourses };
