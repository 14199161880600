import React from 'react';
import { styled } from '@linaria/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { HelpBlock } from 'components/HelpBlock';
import { Layout } from 'components/layout/Layout';
import { UniversityBlock } from 'components/UniversityBlock';
import { device } from 'styles/devices';


export function Support() {
  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['Поддержка']}
        />
        <Content>
          <HelpBlock />
          <UniversityBlock />
        </Content>
      </Container>
    </Layout>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 112px;
`;

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
  }

  @media ${device.tablet} {
  }
  
  @media ${device.desktopS} {
    padding: 0 190px 148px;
    display: flex;
    flex-direction: column;
    gap: 92px;
  }
`;
