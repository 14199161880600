import React from 'react';
import { styled } from '@linaria/react';

import { GroupsBar } from 'components/GroupsBar';
import { Title as TitleC } from 'components/Title';


type Props = {
  banner?: string
  title: string
  text: string
  tags: string[]
};

export function ActivityData({
  banner, title, text, tags,
}: Props) {
  const eventTags = tags.map((item, i) => ({
    id: i + 1,
    text: item,
    active: false,
  }));

  return (
    <Container>
      <div className="banner">
        <img src={banner} alt="" />
      </div>
      <div className="description">
        <Title
          level=""
          desktopFont="w800 s40 h48"
        >
          {title}
        </Title>
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        <Description dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      { tags.length > 1 && <Tags list={eventTags} />}
    </Container>
  );
}

const Title = styled(TitleC)`
  margin: 40px 0 32px;
`;

const Description = styled.div`
  white-space: pre-line;
`;

const Tags = styled(GroupsBar)`
  margin-top: 40px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  max-width: 1016px;
`;
