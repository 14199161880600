/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Spring() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(80, 60, 3);
    if (isTablet) return glyphSizes(120, 90, 2);
    if (isDesktop) return glyphSizes(200, 181);

    return glyphSizes(200, 181);
  };

  return (
    <SVGIcon color="none" {...getSizes()}>
      <path d="M125.122 141C102.706 152.79 79.4956 157.869 60.105 156.572C40.7048 155.275 25.2135 147.609 18.058 134.004C10.9024 120.4 13.3631 103.292 23.2864 86.5711C33.2047 69.8587 50.5408 53.6118 72.9571 41.8215C95.3735 30.0311 118.583 24.952 137.974 26.2488C157.374 27.5461 172.865 35.2122 180.021 48.8167C187.177 62.4213 184.716 79.5295 174.793 96.2502C164.874 112.963 147.538 129.209 125.122 141Z" stroke="#F5F6F8" />
      <path d="M111.821 115.506C89.4048 127.297 66.1948 132.376 46.8042 131.079C27.404 129.782 11.9128 122.116 4.75718 108.511C-2.39841 94.9065 0.0622947 77.7983 9.98557 61.0776C19.9039 44.3652 37.24 28.1183 59.6563 16.328C82.0727 4.53767 105.283 -0.541455 124.673 0.75529C144.073 2.05268 159.565 9.7187 166.72 23.3233C173.876 36.9278 171.415 54.036 161.492 70.7567C151.574 87.4691 134.237 103.716 111.821 115.506Z" stroke="#F5F6F8" />
      <path d="M139.532 164.278C117.116 176.068 93.9058 181.147 74.5152 179.85C55.1149 178.553 39.6237 170.887 32.4681 157.282C25.3125 143.678 27.7732 126.57 37.6965 109.849C47.6149 93.1364 64.9509 76.8896 87.3673 65.0992C109.784 53.3089 132.994 48.2298 152.384 49.5265C171.784 50.8239 187.276 58.4899 194.431 72.0945C201.587 85.699 199.126 102.807 189.203 119.528C179.284 136.24 161.948 152.487 139.532 164.278Z" stroke="#F5F6F8" />
    </SVGIcon>
  );
}
