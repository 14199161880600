import React from 'react';
import { styled } from '@linaria/react';

import { Tag, TagCSS } from 'components/common/Tag';
import { GlyphImage, GlyphImageStyled } from 'components/GlyphImage';
import { Title as TitleC } from 'components/Title';
// import { Text as TextC } from 'components/Text';
import { device } from 'styles/devices';


type Props = {
  _id: string;
  // discount?: string;
  // status: string;
  tag: PropsSingleTag;
  // group: string;
  tagId: any
  title: string;
  card_color: string;
  card_color_tag: string;
  slug?: string;
};

type PropsTag = {
  id: string;
  text: string;
  active: boolean;
};

type PropsSingleTag = {
  _id: string;
  title: string;
};

type PropsGroup = {
  id: string;
  text: string;
  active: boolean;
};

function CardTag({ children, ...rest }: any) {
  return (
    <Tag
      level=""
      desktopFont="w500 s18 h24"
      tabletFont="w500 s16 h24"
      mobileFont="w500 s14 h24"
      {...rest}
    >
      {children}
    </Tag>
  );
}

function PopularCoursesBlock({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _id,
  // discount,
  card_color,
  card_color_tag,
  tag,
  tagId,
  title,
  slug,
}: Props) {
  return (
  // or https://courses.unionepro.ru/courses/
    <Anchor href={`https://courses.unionedev.ru/courses/${slug}`} target="_blank">
      <Container bgColor={card_color}>
        <Info>
          <TagsBlock color={card_color_tag}>
            {/* {discount && (
              <CardTag color="var(--status-error)">
                {discount}
              </CardTag>
            )} */}
            <CardTag>{tag.title}</CardTag>
          </TagsBlock>
          <Footer>
            <Description
              level=""
              desktopFont="w700 s30 h40"
              tabletFont="w600 s18 h24"
              mobileFont="w600 s16 h22"
              color={card_color ? 'black' : 'white'}
            >
              {title}
            </Description>
            {/* {
              isMobile
                ? <DateAsText>{date}</DateAsText>
                : <DateAsTitle>{date}</DateAsTitle>
            } */}
          </Footer>
        </Info>
        <GlyphImage type={tagId} />
      </Container>
    </Anchor>
  );
}

const TagsBlock = styled.div<{ color: string }>`
  .${TagCSS}:not(.ant-tag-has-color) {
    background-color: ${(props) => (props?.color ? props.color : 'rgba(255, 255, 255, 0.16)')};
    border-color: ${(props) => (props?.color ? props.color : '#fff')};
  }

  display: flex;
  @media  ${device.minMobile} {
    .${TagCSS}> div {
      font-size: 12px;
    }
    
  }

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    flex-direction: column;

    .${TagCSS} + .${TagCSS} {
      margin-top: 10px;
    }
    .${TagCSS} > div {
      font-size: 16px;
    }
  }

  @media ${device.desktopS} {
    .${TagCSS} + .${TagCSS} {
      margin-top: 16px;
    }
    .${TagCSS} > div {
      font-size: 18px;
    }
  }
`;

const Description = styled(TitleC)``;

// const DateAsText = styled(TextC)`
//   &.primary {
//     color: white;
//     opacity: 0.68;
//   }
// `;
//
// const DateAsTitle = styled(TitleC)`
//   &.primary {
//     color: white;
//   }
// `;

const Footer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 20px;
    gap: 8px;
  }

  @media ${device.tablet} {
    gap: 12px;
  }

  @media ${device.desktopS} {
    gap: 16px;
  }
`;

const Info = styled.div`
  flex-basis: 0;
  flex-grow: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.mobile}, ${device.minMobile} {
    padding: 18px 0 18px 18px;
  }

  @media ${device.tablet} {
    padding: 24px 0 24px 24px;
  }

  @media ${device.desktopS} {
    padding: 40px 0 40px 40px;
  }
`;

const Container = styled.div<{ bgColor: string }>`
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#0071cE')};
  cursor: pointer;
  display: flex;

  ${GlyphImageStyled} {
    flex-basis: 0;
    flex-grow: 4;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    border-radius: 8px;
  }

  @media ${device.tablet} {
    height: 264px;
  }

  @media ${device.desktopS} {
    border-radius: 24px;
    height: 404px;
  }

`;

const Anchor = styled.a`
`;

export { PopularCoursesBlock };
export type { Props as PopularCoursesBlockProps };
export type { PropsTag as CoursesTagProps };
export type { PropsGroup as CoursesGroupProps };
