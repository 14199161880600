import React from 'react';
import { css } from '@linaria/core';
import { Layout } from 'antd';

import { device } from 'styles/devices';


interface mainLayoutProps {
  children: React.ReactNode
}

export function MainLayout({ children } : mainLayoutProps) {
  return <Layout className={mainLayout}>{children}</Layout>;
}

const mainLayout = css`
  &.ant-layout {
    background: var(--bg-primary);
    min-height: calc(100vh - 252px);
    padding: 40px 20px;
    border-radius: 24px 24px 0 0;
    @media ${device.tablet} {
      padding: 24px 20px;
      border-radius: 0;
    }
    @media ${device.desktopS} {
      padding: 32px 190px;
    }
  }
`;
