import React, { useState } from 'react';
import { styled } from '@linaria/react';

import { Button as ButtonC } from 'components/common/Button';
import { TextStyled } from 'components/Text';
import { Title, Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';
import { navigateTo } from 'utils/navigation';
import { useSelector } from 'utils/use-selector';
import ActivityOpen from 'assets/images/activity-open.png';


const LINK = 'https://courses.unionepro.ru/courses';

function ActivitiesBlockOpen() {
  const { isMobile } = useSelector((state) => state.common);
  const [btnText, setBtnText] = useState('Выбрать курс');

  const toggleBtnText = () => {
    if (btnText === 'Выбрать курс') setBtnText('Подробнее');
    else if (btnText === 'Подробнее') setBtnText('Выбрать курс');
  };

  return (
    <Container onClick={() => navigateTo(LINK)}>
      <Info>
        <Title
          level=""
          desktopFont="w800 s30 h40"
          tabletFont="w800 s30 h40"
          mobileFont="w700 s24 h28"
          color="white"
        >
          Каталог курсов
        </Title>
        <SubTitle
          level=""
          desktopFont="w500 s18 h24"
          tabletFont="w500 s18 h24"
          mobileFont="w500 s18 h24"
          color="white"
        >
          Курсы для взрослых и школьников,
          программы повышения квалификации
          для работников сферы образования,
          госсектора и бизнеса
        </SubTitle>
        {
          isMobile ? (
            <JoinBtn
              type="ghost"
              textColor="white"
            >
              Вступить в сотрудничество
            </JoinBtn>
          ) : (
            <SelectCourseBtn
              type="ghost"
              iconNext
              textColor="white"
              onMouseEnter={toggleBtnText}
              onMouseLeave={toggleBtnText}
            >
              {btnText}
            </SelectCourseBtn>
          )
        }
      </Info>
      <Image>
        <img src={ActivityOpen} alt="" />
      </Image>
    </Container>
  );
}

const SubTitle = styled(TitleC)`
  flex-grow: 1;

  @media ${device.desktopS} {
    width: 332px;
  }
`;

const SelectCourseBtn = styled(ButtonC)`
  border-color: white !important;
  max-width: fit-content;

  &:hover {
    background-color: white !important;

    ${TextStyled} {
      color: var(--brand-primary);
    }
  }
`;

const JoinBtn = styled(ButtonC)`
  border-color: white !important;

  &:hover {
    background-color: white !important;

    ${TextStyled} {
      color: var(--brand-primary);
    }
  }

  @media ${device.mobile}, ${device.minMobile} {
    && {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
  }

  @media ${device.tablet}, ${device.desktopS} {
    margin-top: initial;
  }

  @media ${device.tablet} {
    max-width: fit-content;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.mobile}, ${device.minMobile} {
    padding: 0 20px 20px;
    gap: 10px;
  }

  @media ${device.tablet}, ${device.desktopS} {
    gap: 16px;
  }

  @media ${device.tablet} {
    padding: 40px 0 40px 40px;
  }

  @media ${device.desktopS} {
    padding: 64px 0 48px 40px;
  }
`;

const Image = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    padding: 0 24px;
    text-align: left;

    img {
      height: 160px;
    }
  }

  @media ${device.tablet}, ${device.desktopS} {
    padding: initial;
    text-align: right;

    img {
      height: 80%;
      max-height: initial;
    }
  }
`;

const Container = styled.div`
  background-color: var(--block-open);
  border-radius: 24px;
  display: flex;
  justify-content: space-between;

  ${Info},
  ${Image} {
    flex-basis: 0;
  }

  ${Info} {
    flex-grow: 2;
  }

  ${Image} {
    flex-grow: 3;
  }

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: column-reverse;
    /* padding: 0 20px; */
  }

  @media ${device.tablet}, ${device.desktopS} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    max-width: 100%;
    grid-column: 1 / span 3;
  }

  @media ${device.desktopS} {
    max-width: 1144px;
    grid-column: 1 / span 2;
    &:hover {
      cursor: pointer;
      background-color: var(--block-open-hover);
    }
  }
`;

export { ActivitiesBlockOpen };
