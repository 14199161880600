/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Combo() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(98, 87, 3);
    if (isTablet) return glyphSizes(127, 119, 2);
    if (isDesktop) return glyphSizes(241, 226);

    return glyphSizes(241, 226);
  };

  return (
    <SVGIcon {...getSizes()}>
      <g clipPath="url(#clip0_2994_65565)">
        <path d="M151.549 74.8105H0V226H151.549V74.8105Z" fill="#F7B055" />
        <path d="M90.5528 74.8106C85.6794 85.0049 82.8496 96.6107 82.8496 108.687C82.8496 148.68 112.719 181.772 151.392 186.791V74.8106H90.5528Z" fill="#EB5757" />
        <path d="M239.984 104.658C247.089 61.6682 217.917 21.072 174.825 13.9834C131.734 6.89471 91.0414 35.998 83.9359 78.9873C76.8304 121.977 106.003 162.573 149.094 169.661C192.186 176.75 232.878 147.647 239.984 104.658Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2994_65565">
          <rect width="241" height="226" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  );
}
