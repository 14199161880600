import React from 'react';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';

import { Tag, TagCSS } from 'components/common/Tag';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';


export type Props = {
  activity: IActivity
};

export function SimilarActivity({
  activity,
}: Props) {
  const {
    _id,
    desktop_cover,
    tags,
    title,
    created_at,
  } = activity;

  return (
    <Link href={`/events/${_id}`} target="_blank">
      <Container>
        <Banner>
          <Image src={desktop_cover} alt="" />
        </Banner>
        <Description>
          {tags && <Tag text={tags[0]} /> }
          <Title
            level="3"
            desktopFont="w700 s24 h28"
          >
            {title}
          </Title>
          <Date color="thirdly">
            {dayjs(created_at).format('DD.MM.YYYY')}
          </Date>
        </Description>
      </Container>
    </Link>
  );
}

const Banner = styled.div`
  img {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
`;

const Link = styled.a`
  cursor: pointer;
`;

const Date = styled(TitleC)`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  margin-top: 20px;
`;

const Title = styled(TitleC)`
  margin-top: 16px;
`;

const Image = styled.img`
  height: 240px;
  width: 100%;
  object-fit: cover;
`;

const Description = styled.div`
  padding: 0 32px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .${TagCSS} {
    margin-top: 20px;
    background-color: #7B61FF;
  }
`;

const Container = styled.div`
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  max-width: 492px;
  min-width: 492px;
  height: 100%;
`;
