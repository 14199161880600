/* eslint-disable max-len */
import React from 'react';
import { styled } from '@linaria/react';

import { ReactComponent as TelegramIcon } from 'assets/icons/social/telegram.svg';
import { ReactComponent as VcRuIcon } from 'assets/icons/social/vc-ru.svg';
import { ReactComponent as VkIcon } from 'assets/icons/social/vk.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/social/youtube.svg';


type Props = {
  ytLink?: string
  vkLink?: string
  tgLink?: string
  vcLink?: string
};

export function SocialContacts({
  ytLink,
  vkLink,
  tgLink,
  vcLink,
}: Props) {
  return (
    <Container>
      <Link href={ytLink} target="_blank">
        <YouTubeIcon />
      </Link>
      <Link href={vkLink} target="_blank">
        <VkIcon />
      </Link>
      <Link href={tgLink} target="_blank">
        <TelegramIcon />
      </Link>
      <Link href={vcLink} target="_blank">
        <VcRuIcon />
      </Link>
    </Container>
  );
}

const Link = styled.a`
  :hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
`;
