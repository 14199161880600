import React from 'react';
import { styled } from '@linaria/react';
import { Divider } from 'antd';

import { Heading } from 'components/Heading';
import { device } from 'styles/devices';


export interface CourseCardProps {
  icon: string;
  title: string;
  text?: string;
  list: string[];
}

export function CourseCard({
  icon,
  title,
  text,
  list,
} : CourseCardProps) {
  return (
    <Container>
      <CourseCardHeader>
        <img src={icon} alt="" />
        <StyledHeading level={3}>{title}</StyledHeading>
      </CourseCardHeader>
      <p>{text}</p>
      <Divider />
      <ul>
        {list.map((item) => <li className="items" key={item}>{item}</li>)}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  background: var(--bg-secondary);
  border: 1px solid var(--el-lines);
  border-radius: 8px;
  padding: 30px;
  color: var(--text-thirdly);
  margin: 0 15px;
  font-size: 14px;
  @media ${device.tablet} {
    margin: 0;
    font-size: 16px;
  }
  > p{
    line-height: 20px;
  }

  .ant-divider-horizontal {
    margin: 20px 0;
  }
  > ul{
    .items {
      @media ${device.mobile}{ line-height: 20px; }
      @media ${device.tablet}{ line-height: 22px; }
    }
  }
`;

const CourseCardHeader = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 36px 1fr;
  align-items: center;
  @media ${device.tablet} {
    grid-template-columns: 46px 1fr;
  }
  @media ${device.desktopS} {
    grid-template-columns: 60px 1fr;
  }
  > img {
    width: 100%;
    height: auto;
  }
`;

const StyledHeading = styled(Heading)`
  && {
    margin: 0;
  }
`;
