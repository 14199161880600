import React from 'react';
import { styled } from '@linaria/react';


type Props = {
  color: string
  top?: string
  left?: string
  right?: string
  bottom?: string
};

function BlurSpot(props: Props) {
  return (
    <Span {...props} />
  );
}
 
export { BlurSpot };

const Span = styled.span<Props>`
  display: inline-block;
  width: 300px;
  height: 300px;
  background-color: ${(props) => props.color};
  filter: blur(115px);
  position: absolute;
  z-index: 0;
  top: ${({ top }) => top || ''};
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
`;
