import React from 'react';
import { styled, CSSProperties } from '@linaria/react';
import { Link } from 'react-router-dom';

import { Text } from 'components/Text';

import { device } from '../../styles/devices';


type Props = {
  url: string
  className?: string
  style?: CSSProperties
};

export function ButtonSupport({ url, className, style }: Props) {
  return (
    <Link to={url} className={className} style={style}>
      <Container>
        <div className="icon">
          <img src="/icons/help.svg" alt="" />
        </div>
        <Text
          color="brand"
          type="buttons"
        >
          Поддержка
        </Text>
      </Container>
    </Link>
  );
}

const Container = styled.div`
  padding: 12px 24px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  @media ${device.minMobile}{
  padding: 7px 15px;
  }
`;
