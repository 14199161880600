import React, { ReactElement } from 'react';
import { styled } from '@linaria/react';


type Props = {
  className?: string
  style?: React.CSSProperties
  active?: boolean
  children: ReactElement | ReactElement[]
};

function Slider({
  className, style, active, children,
}: Props) {
  return (
    <Container className={className} style={style} active={active}>
      {children}
    </Container>
  );
}

const Container = styled.div<{ active?: boolean }>`
  overflow-x: ${(props) => (props.active ? 'auto' : 'initial')};
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &::-webkit-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export {
  Slider,
  Container as SliderStyled,
};
