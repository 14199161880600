export const getMonthByDate = (value: string | Date): string => new Date(value)
  .toLocaleDateString('ru', { month: 'long' });

export const months = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];
