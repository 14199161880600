import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';
import { device } from 'styles/devices';

import { StagesItem } from './StagesItem';


interface IItems {
  [index: string]: string | undefined;
}

export interface StagesListProps {
  title: string;
  items: IItems;
}

export function StagesList({ title, items }: StagesListProps) {
  return (
    <>
      <StyledHeading level={4} className="h4">
        {title}
      </StyledHeading>
      <StageItems>
        {Object.entries(items).map((item) => (
          <StagesItem key={item[0]} stage={item[0]} description={item[1]} />
        ))}
      </StageItems>
    </>
  );
}

const StyledHeading = styled(Heading)`
  && {
    margin-bottom: 24px;
  }
`;

const StageItems = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  max-width: 100%;
  overflow: auto;
  @media ${device.desktopS} {
    flex-wrap: wrap;
  }

  > div {
    &:first-child {
      &:before {
        opacity: 0;
      }
    }
    &:last-child {
      &:after {
        opacity: 0;
      }
    }
  }
`;
