import React from 'react';
import { styled } from '@linaria/react';

import { Button, ButtonCSS } from 'components/common/Button';
import { PictureFrame } from 'components/PictureFrame';
import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';


type Props = {
  title: string
  text: string
  link: string
  image: string
  blockColor?: string
};

function EventsBlock({
  title, text, link, image, blockColor = 'var(--brand-primary)',
}: Props) {
  return (
    <Container blockColor={blockColor}>
      <Info>
        <Title
          level=""
          desktopFont="w800 s30 h40"
          tabletFont="w800 s26 h32"
          mobileFont="w700 s18 h24"
          color="white"
        >
          {title}
        </Title>
        <SubTitle
          mobileFontLevel="03"
          color="white"
        >
          {text}
        </SubTitle>
        <Anchor href={link} target="_blank">
          <Button type="ghost" iconNext textColor="white">
            Подробнее
          </Button>
        </Anchor>
      </Info>
      <ImageBlock>
        <PictureFrame src={`/images/main-events/${image}`} />
      </ImageBlock>
    </Container>
  );
}

const Title = styled(TitleC)`
  @media ${device.desktopS} {
    white-space: pre-line;
  }
`;

const SubTitle = styled(TitleC)`
  @media ${device.desktopS} {
    white-space: pre-line;
  }

  && {
    opacity: 0.68;
  }
`;

const Info = styled.div`
  width: 668px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media ${device.mobile}, ${device.minMobile} {
    max-width: 100%;
    /* max-width: 90%; */
    padding: 20px;
    height: 100%;
  }

  @media ${device.tablet} {
    padding: 40px 0 40px 40px;
  }

  @media ${device.desktopS} {
    padding: 56px 0 56px 56px;
    /* negate fill tablet */
    gap: 16px;
  }
`;

const ImageBlock = styled.div`
  width: 437px;
  @media ${device.mobile}, ${device.minMobile} {
    justify-content: flex-start; 
    padding-left: 105px;
    padding-top: 46px;
    padding-bottom: 20px;
    overflow-y: visible;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
    padding-right: 64px;
    overflow-y: clip;
  }
`;

const Anchor = styled.a`
  /* position element at the bottom */
  flex: 1 0 0;
  display: flex;
  align-items: flex-end;

  .${ButtonCSS}.ant-btn {
    border-color: white;

    &:hover {
      background-color: white;

      span:first-child {
        color: var(--brand-primary);
      }

      span:last-child:not(:hover) {
        display: inline-block;

        path {
          fill: var(--brand-primary);
        }
      }
    }

    span:last-child:not(:hover) {
      display: inline-block;

      path {
        fill: white;
      }
    }
  }
`;

const Container = styled.div<{ blockColor: string }>`
  border-radius: 24px;
  background-color: ${(props) => props.blockColor};
  display: flex;

  @media ${device.mobile}, ${device.minMobile} {
    max-width: 92%;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  @media ${device.tablet}, ${device.desktopS} {
    max-height: 388px;
    flex-direction: row;
  }

  @media ${device.tablet} {
    max-width: 904px;
    /* move 2nd column off screen to hide it */
    gap: 60px;
  }

  @media ${device.desktopS} {
    max-width: initial;
  }

  :hover {
    filter: brightness(85%);
  }
`;

export { EventsBlock };
export type { Props as EventsBlockProps };
