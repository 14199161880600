import React, { ReactNode } from 'react';


export type FgProps = {
  top?: string
  right?: string
  bottom?: string
  left?: string
  style?: any
  children?: ReactNode
};

/**
 * Float glyph
 * */
export function Fg({
  top = 'initial',
  right = 'initial',
  bottom = 'initial',
  left = 'initial',
  style,
  children,
}: FgProps) {
  return (
    <div
      style={{
        top,
        right,
        bottom,
        left,
        position: 'absolute',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
