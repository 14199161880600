import React from 'react';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';


type Props = {
  iconUrl: string
  title: string
  detail: string
  link?: string
};

function Block({
  iconUrl, title, detail, link,
}: Props) {
  return (
    <BlockA
      href={link}
      target={(link && link.includes('http')) ? '_blank' : '_self'}
    >
      <HeaderC>
        <Icon>
          <img src={iconUrl} alt="" />
        </Icon>
      </HeaderC>
      <ContentC>
        <Title
          level=""
          desktopFont="w600 s24 h28"
        >
          {title}
        </Title>
        <Detail
          level=""
          desktopFont="w400 s16 h20"
          color="thirdly"
        >
          {detail}
        </Detail>
      </ContentC>
    </BlockA>
  );
}

const Icon = styled.div`
  width: 72px;

  img {
    width: inherit;
  }
`;

const HeaderC = styled.div``;

const Title = styled(TitleC)``;

const Detail = styled(TitleC)`
  margin-top: 12px;
  font-family: var(--font-content);
`;

const ContentC = styled.div``;

const BlockA = styled.a`
  padding: 40px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  gap: 24px;
  flex: 1;
`;


export function UniversityBlock() {
  return (
    <Container>
      <Header
        level=""
        desktopFont="w800 s60 h78"
      >
        Университет
      </Header>
      <List>
        <Block
          iconUrl="/icons/social/yt.svg"
          title="Innopolis University"
          detail="@InnopolisU"
          link="https://youtube.com/@InnopolisU"
        />
        <Block
          iconUrl="/icons/social/yt.svg"
          title="IT Education"
          detail="@innopolisuniversityido"
          link="https://www.youtube.com/@innopolisuniversityido"
        />
        <Block
          iconUrl="/icons/social/vk.svg"
          title="IT Education"
          detail="vk.com/innopolisu"
          link="https://vk.com/innopolisu"
        />
        <Block
          iconUrl="/icons/social/vk.svg"
          title="Сообщество в ВК"
          detail="https://vk.com/edtechiu"
          link="https://vk.com/edtechiu"
        />
        <Block
          iconUrl="/icons/social/tg.svg"
          title="Телеграм-канал"
          detail="https://t.me/innokadriu"
          link="https://t.me/innokadriu"
        />
      </List>
    </Container>
  );
}

const Header = styled(TitleC)``;

const List = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
`;


const Container = styled.div``;
