import React from 'react';
import { styled } from '@linaria/react';

import { Heading } from 'components/Heading';

import { ClientsCarousel } from './ClientsCarousel';


export function Clients() {
  return (
    <>
      <StyledHeading level={2} className="h2">
        Компании, которые мы обучили
      </StyledHeading>
      <ClientsCarousel />
    </>
  );
}

const StyledHeading = styled(Heading)`
  && {
    text-align: center;
    margin-bottom: 24px;
  }
`;
