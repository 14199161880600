import React from 'react';
import { styled } from '@linaria/react';

import { ButtonAngle } from 'components/buttons/ButtonAngle';


type Props = {
  leftDisabled?: boolean
  rightDisabled?: boolean
  onLeft?: () => void
  onRight?: () => void
  delay?: number
};

export function LRButtons(props: Props) {
  const {
    leftDisabled, rightDisabled, onLeft, onRight, delay,
  } = props;
  
  return (
    <Container>
      <ButtonAngle
        direction="left"
        disabled={leftDisabled}
        onClick={onLeft}
        delay={delay}
      />
      <ButtonAngle
        direction="right"
        disabled={rightDisabled}
        onClick={onRight}
        delay={delay}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 16px;
`;
