import React, { ReactElement } from 'react';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';


type Props = {
  className?: string
  style?: React.CSSProperties
  title?: string
  children: ReactElement | ReactElement[]
};

export function FeatureWrapper({
  className, style, title, children,
}: Props) {
  return (
    <Container className={className} style={style}>
      <Title
        level=""
        desktopFont="w800 s40 h48"
        tabletFont="w800 s32 h40"
        mobileFont="w800 s24 h28"
      >
        {title}
      </Title>
      <Content>
        {children}
      </Content>
    </Container>
  );
}

const Title = styled(TitleC)`
  user-select: none;
`;

const Content = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    margin-top: 24px;
  }

  @media ${device.desktopS} {
    margin-top: 32px;
  }
`;

const Container = styled.div`
  position: relative;
`;
