import React from 'react';
import { styled } from '@linaria/react';

import { CommonCarousel }
  from 'components/common/common-carousel/CommonCarousel';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';
import TechnologyIcon from 'assets/icons/computer-technology.svg';
import ManagementIcon from 'assets/icons/management.svg';
import SoftSkillsIcon from 'assets/icons/soft-skills.svg';


import { CourseCard } from './CourseCard';
import data from './data.json';


interface IIconsList {
  [index: string]: string;
}
const iconsList: IIconsList = {
  technologyIcon: TechnologyIcon,
  managementIcon: ManagementIcon,
  softSkillsIcon: SoftSkillsIcon,
};

export function CourseCardsList() {
  const { isMobile } = useBreakpoints();

  const cards = data.map((course) => (
    <CourseCard {...course} icon={iconsList[course.icon]} key={course.id} />
  ));

  return isMobile ? (
    <CommonCarousel
      adaptiveHeight
      items={cards}
      slidesPerRow={1}
      dots
      showProgress={false}
    />
  ) : (
    <CourseCardsListContainer>{cards}</CourseCardsListContainer>
  );
}

const CourseCardsListContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 30px;
    grid-template-areas:
      "first second"
      "first third";

    > div {
      grid-area: first;
      &:nth-child(2) {
        grid-area: second;
      }
      &:nth-child(3) {
        grid-area: third;
      }
    }
  }
`;
