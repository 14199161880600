import React from 'react';
import { CSSProperties, styled } from '@linaria/react';

import { getFont } from 'utils/font';
import { mn } from 'utils/merge-class-names';
import { useBreakpoints } from 'utils/useBreakpoints';


type Props = {
  children: string | number | React.ReactElement
  type?: 'buttons' | 'captions'
  className?: string
  desktopFont?: string
  tabletFont?: string
  mobileFont?: string
  style?: CSSProperties
  color?: 'primary' | 'secondary' | 'thirdly' | 'white' | 'brand'
};

function Text({
  children,
  type,
  className = '',
  desktopFont,
  tabletFont,
  mobileFont,
  style,
  color = 'primary',
}: Props) {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  return (
    <Container
      // @ts-ignore
      type={type}
      style={style}
      className={mn(
        className,
        color,
        getFont(
          isMobile,
          isTablet,
          isDesktop,
          desktopFont,
          tabletFont,
          mobileFont,
        ),
      )}
    >
      {children}
    </Container>
  );
}

const Container = styled.span`
  /* main-text */
  font-family: var(--font-content);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;

  &.w {
    &500 { font-weight: 500; }
    &400 { font-weight: 400; }
  }
  &.s {
    &16 { font-size: 16px; }
    &14 { font-size: 14px; }
  }
  &.h {
    &20 { line-height: 20px; }
  }
  &[type='buttons'] {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &[type='captions'] {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &.primary {
    color: var(--text-primary);
  }
  &.secondary {
    color: var(--text-secondary);
  }
  &.thirdly {
    color: var(--text-gray);
  }
  &.brand {
    color: var(--brand-primary);
  }
  &.white {
    color: white;
  }
`;

export { Text, Container as TextStyled };
