import React, { useState } from 'react';
import { styled } from '@linaria/react';

import Bag from 'assets/icons/base/bag.svg';
import Book from 'assets/icons/base/book.svg';
import Box from 'assets/icons/base/box.svg';
import Profile from 'assets/icons/base/profile.svg';

import { KnowledgeBaseSystemBlock } from './KnowledgeBaseSystemBlock';

import { SystemItem } from '../../models/ISystem';
import { systemService } from '../../stores/system/systemService';
import { KnowledgeBaseCollapse } from '../knowledge-base-info/KnowledgeBaseCollapse';
import { KnowledgeSearchCollapse } from '../knowledge-base-search/KnowledgeSearchCollapse';


const iconList = [Book, Profile, Bag, Box];
type Props = {
  defaultData: SystemItem;
  defaultId: string;
  moreResult: boolean;
  dataQuestions: any;
  search: string;
  setSearch: (value: string) => void;
  onClick: (value :boolean) => void;
};

export function KnowledgeBaseSystem({
  defaultData,
  defaultId,
  dataQuestions,
  moreResult,
  search, onClick, setSearch,
}: Props) {
  const {
    data: dataSystem,
    isLoading,
  } = systemService.useFetchAllSystemQuery({ sort: 'created_at' });
  const [selectedId, setSelectedId] = useState<any>(defaultId);
  const [activeBlock, setActiveBlock] = useState<any>(defaultData);

  if (isLoading) {
    return null;
  }

  const handleTab = (id: string): void => {
    setSelectedId(id);
    findSystem(id);
    onClick(false);
    setSearch('');
  };

  const findSystem = (id: string) => {
    const result = dataSystem?.items.find((item: any) => item._id === id);
    setActiveBlock(result);
  };

  return (
    <Container>
      <Tabs>
        { dataSystem?.items && dataSystem?.items.slice(0, 4).map((item: any, index: number) => (
          <KnowledgeBaseSystemBlock
            question_count={item.question_count}
            color={moreResult ? '' : selectedId}
            icon={iconList[index]}
            key={item._id}
            onClick={handleTab}
            _id={item._id}
            title={item.title}
          />
        ))}
      </Tabs>
      {activeBlock
      && !moreResult && <KnowledgeBaseCollapse title={activeBlock.title} _id={activeBlock._id} />}
      {moreResult && search && <KnowledgeSearchCollapse searchTitle={search} data={dataQuestions} />}
    </Container>
  );
}

const Container = styled.div`
  margin-top: -37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 112px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
