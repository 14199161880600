import React, { ReactElement } from 'react';
import { css } from '@linaria/core';

import { Particles } from 'components/ParticlesBg';
import { device } from 'styles/devices';

import { MobilitySentry } from './MobilitySentry';


type Props = {
  children: ReactElement | ReactElement[]
};

function ViewWrapper({ children }: Props) {
  return (
    <div className={container}>
      <MobilitySentry />
      <Particles />
      {children}
    </div>
  );
}
 
const container = css`
  background-color: var(--bg-primary);
  color: var(--text-primary);
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  @media ${device.mobile}, ${device.minMobile} {
    padding: 80px 20px 30px;
  }

  @media ${device.tablet} {
    padding: 88px 60px 80px;
  }

  @media ${device.desktopS} {
    padding: 96px 68px 88px;
  }

  @media ${device.desktopS} {
    padding: 142px 190px 104px;
  }
`;

export { ViewWrapper };
