/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Echinus() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(19, 19, 3);
    if (isTablet) return glyphSizes(26, 26, 2);
    if (isDesktop) return glyphSizes(44, 44);

    return glyphSizes(44, 44);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M44 20.9363H25.0735L42.3824 13.0449L41.8971 11.9176L24.2647 19.809L36.5588 4.99251L35.5882 4.18727L23.2941 19.0037L27.6618 0.322097L26.3676 0L22 18.6816L17.6324 0L16.5 0.322097L20.8676 19.0037L8.41176 4.18727L7.44118 4.99251L19.7353 19.809L2.26471 11.9176L1.61765 13.0449L19.0882 20.9363H0V22.2247H19.0882L1.61765 29.9551L2.26471 31.0824L19.7353 23.191L7.44118 38.0075L8.41176 38.8127L20.8676 23.9963L16.5 42.6779L17.6324 43L22 24.3184L26.3676 43L27.6618 42.6779L23.2941 23.9963L35.5882 38.8127L36.5588 38.0075L24.2647 23.191L41.8971 31.0824L42.3824 29.9551L25.0735 22.2247H44V20.9363Z" fill="#F5F6F8" />
    </SVGIcon>
  );
}
