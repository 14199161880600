import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Col, Row } from 'antd';

import { device } from 'styles/devices';
import CheckboxMarkedOutlined from 'assets/icons/checkbox-marked-outline.svg';

import { Heading } from './Heading';


export function ExplanationInfo() {
  return (
    <Container>
      <Row gutter={30}>
        <Col xs={24} sm={8}>
          <Heading level={1}>
            Почему стоит обучать сотрудников в Университете Иннополис?
          </Heading>
        </Col>
        <Col xs={24} sm={16} className={previewText}>
          <p>
            Университет Иннополис — молодой российский ИТ-вуз,
            специализирующийся на образовании, разработках и исследованиях в
            области информационных технологий, робототехники и искусственного
            интеллекта.
          </p>
          <p>
            Университет Иннополис представляет более 45 программ для обучения
            сотрудников, с помощью которых они прокачают свои soft skills,
            разовьют управленческие навыки и научатся использовать актуальные
            технологии в работе.
          </p>
        </Col>
      </Row>
      <Row
        gutter={[{
          xs: 20, sm: 20, md: 20, lg: 30,
        }, 16]}
        align="stretch"
      >
        <Col xs={24} sm={8}>
          <Tile>
            <img src={CheckboxMarkedOutlined} alt="" />
            <div>
              В университете преподают ведущие российские и иностранные
              специалисты, имеющие большой практический опыт работы в индустрии,
              поэтому на курсе рассматриваются реальные кейсы.
            </div>
          </Tile>
        </Col>
        <Col xs={24} sm={8}>
          <Tile>
            <img src={CheckboxMarkedOutlined} alt="" />
            <div>
              Образовательные программы готовятся специалистами из индустрии. Мы
              обновляем программы в соответствии с развитием рынка и технологий.
            </div>
          </Tile>
        </Col>
        <Col xs={24} sm={8}>
          <Tile>
            <img src={CheckboxMarkedOutlined} alt="" />
            <div>
              Более 60% учебного плана отводится на выполнение практических
              проектов, максимально приближенных к реальным задачам, с которыми
              предстоит столкнуться в работе
            </div>
          </Tile>
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  h1.ant-typography {
    border-bottom: 2px dashed var(--text-primary);
    padding-bottom: 15px;
    @media ${device.tablet}{
      min-width: 335px;
    }
  }

  @media ${device.tablet}{
    p{
      margin-left: 40px;
      width: 571px;
    }
  }
  @media ${device.desktopS}{
    p{
      margin-left: 0;
      width: auto;
    }
  }
 
  div{
    font-weight: 500;
  }
`;

const previewText = css`
  color: var(--text-primary);
  font-weight: 500;
  font-size: 16px;
  font-family: var(--font-header); 
  @media ${device.desktopS} {
    font-size: 18px;
  }
`;

const Tile = styled.div`
  color: var(--bg-secondary);
  background-color: var(--brand-primary);
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;

  @media ${device.desktopS}  {
    font-size: 16px;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 18px;
  }

  img {
    width: 30px;
    height: 30px;
    margin-bottom: 12px;
  }
`;
