/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes, glyphSizesViewBox } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


const DESKTOP_SIZE = 251;

export function StarBig() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(98, 98, 3);
    if (isTablet) return glyphSizes(132, 132, 2);
    if (isDesktop) {
      return glyphSizesViewBox(
        DESKTOP_SIZE,
        DESKTOP_SIZE,
        `0 -40 ${DESKTOP_SIZE} ${DESKTOP_SIZE}`,
      );
    }

    return glyphSizesViewBox(
      DESKTOP_SIZE,
      DESKTOP_SIZE,
      `0 -40 ${DESKTOP_SIZE} ${DESKTOP_SIZE}`,
    );
  };

  return (
    <SVGIcon color="none" {...getSizes()}>
      <path d="M121.913 -36.4179C123.301 -37.9417 125.699 -37.9417 127.087 -36.4179L148.389 -13.0378C149.573 -11.7382 151.405 -11.2472 153.081 -11.7806L183.218 -21.3776C185.183 -22.0031 187.259 -20.8042 187.7 -18.7904L194.457 12.1082C194.833 13.8257 196.174 15.1672 197.892 15.5428L228.79 22.3004C230.804 22.7409 232.003 24.8175 231.378 26.7816L221.781 56.9194C221.247 58.5946 221.738 60.4272 223.038 61.6112L246.418 82.9128C247.942 84.3011 247.942 86.6989 246.418 88.0872L223.038 109.389C221.738 110.573 221.247 112.405 221.781 114.081L231.378 144.218C232.003 146.183 230.804 148.259 228.79 148.7L197.892 155.457C196.174 155.833 194.833 157.174 194.457 158.892L187.7 189.79C187.259 191.804 185.183 193.003 183.218 192.378L153.081 182.781C151.405 182.247 149.573 182.738 148.389 184.038L127.087 207.418C125.699 208.942 123.301 208.942 121.913 207.418L100.611 184.038C99.4271 182.738 97.5946 182.247 95.9194 182.781L65.7816 192.378C63.8175 193.003 61.7409 191.804 61.3004 189.79L54.5428 158.892C54.1672 157.174 52.8257 155.833 51.1082 155.457L20.2096 148.7C18.1958 148.259 16.9969 146.183 17.6224 144.218L27.2194 114.081C27.7528 112.405 27.2618 110.573 25.9622 109.389L2.58206 88.0872C1.05832 86.6989 1.05833 84.3011 2.58207 82.9128L25.9622 61.6112C27.2618 60.4271 27.7528 58.5946 27.2194 56.9194L17.6224 26.7816C16.9969 24.8175 18.1958 22.7409 20.2096 22.3004L51.1082 15.5428C52.8257 15.1672 54.1672 13.8257 54.5428 12.1082L61.3004 -18.7904C61.7409 -20.8042 63.8175 -22.0031 65.7816 -21.3776L95.9194 -11.7806C97.5946 -11.2472 99.4272 -11.7382 100.611 -13.0378L121.913 -36.4179Z" stroke="white" />
    </SVGIcon>
  );
}
