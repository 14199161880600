import dayjs from 'dayjs';


export const toHumanDate = (date: string): string => new Date(date).toLocaleDateString('ru', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const toHumanTime = (date: string): string => dayjs(date).format('HH:mm');
