const glyphSizes = (width: number, height: number, multiplier = 1) => {
  const mplrRounded = Math.ceil(multiplier);

  return {
    width,
    height,
    viewBox: `0 0 ${width * mplrRounded} ${height * mplrRounded}`,
  };
};

const glyphSizesViewBox = (
  width: number,
  height: number,
  viewBox: string,
  multiplier = 1,
) => {
  const mplrRounded = Math.ceil(multiplier);

  return {
    width,
    height,
    viewBox: viewBox ?? `0 0 ${width * mplrRounded} ${height * mplrRounded}`,
  };
};

export { glyphSizes, glyphSizesViewBox };
