import React from 'react';

import { useBreakpoints } from 'utils/useBreakpoints';


export function RainNew() {
  const { isMobile } = useBreakpoints();
  if (isMobile) {
    return (
      <svg style={{ width: '49px', height: '78px' }}>
        <path d="M0.904297 33.2417L72.1858 -19.973633" stroke="white" />
        <path d="M0.515625 73.1077L71.7971 21.437622" stroke="white" />
        <path d="M0.923828 53.5714L72.2053 0.901306" stroke="white" />
      </svg>
    );
  }

  return (
    <svg style={{ width: '306px', height: '221px' }}>
      <path d="M1 125L268 -71" stroke="white" />
      <path d="M37 173L306 -24" stroke="white" />
      <path d="M72 220L340 23" stroke="white" />
    </svg>
  );
}
