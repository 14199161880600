import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';

import {
  ArticleArchiveList,
} from 'components/article-archive/ArticleArchiveList';
import { Breadcrumbs } from 'components/Breadcrumbs';
// import {
//   SimilarEvents as SimilarEventsC,
// } from 'components/similar-events/SimilarEvents';
import { Layout } from 'components/layout/Layout';
import { PaginationCustom } from 'components/PaginationCustom';
import { Title as TitleC } from 'components/Title';
// import { Text as TextC } from 'components/Text';
// import { Dropdown, DDItemProps } from 'components/common/Dropdown';
// import { SearchBar } from 'components/SearchBar';
import { device } from 'styles/devices';

import { newsService } from '../stores/news/newsService';
// import { activityService } from '../stores/activity/activityService';


// const items: DDItemProps = [
//   { key: '1', label: 'По дате', },
//   { key: '2', label: 'По наименованию', },
// ];

const ITEMS_ON_PAGE = 8;

export function ArticlesArchive() {
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [newsOnPage, setNewsOnPage] = useState<any>([]);
  const { data: news } = newsService.useFetchAllNewsQuery({ limit: 20, skip: 0 });
  // const { data: activity } = activityService.useFetchAllActivityQuery({ limit: 20, skip: 0 });


  useEffect(() => {
    if (news) {
      setNewsOnPage(news.slice(0, ITEMS_ON_PAGE));
      setPagesCount(Math.ceil(news.length / ITEMS_ON_PAGE));
    }
  }, [news]);


  function onPrevPage() {
    if (news) {
      // first page
      if (page === 1) {
        return;
      }

      setPage((v) => {
        const offset = (v - 1 - 1) * ITEMS_ON_PAGE;
        setNewsOnPage(news.slice(offset, ITEMS_ON_PAGE + offset));

        return v - 1;
      });
    }
  }

  function onNextPage() {
    if (news) {
      // last page
      if (page === pagesCount) {
        return;
      }

      setPage((v) => {
        const offset = (v + 1 - 1) * ITEMS_ON_PAGE;
        setNewsOnPage(news.slice(offset, ITEMS_ON_PAGE + offset));

        return v + 1;
      });
    }
  }

  function onPage(pageNumber: number) {
    if (news) {
      if (pageNumber < news.length + 1 && pageNumber > 0) {
        const offset = (pageNumber - 1) * ITEMS_ON_PAGE;
        setNewsOnPage(news.slice(offset, ITEMS_ON_PAGE + offset));
        setPage(pageNumber);
      }
    }
  }

  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['Новости']}
        />
        <TopHeader>
          <TopTitle
            level=""
            desktopFont="w800 s60 h78"
            mobileFont="w800 s24 h28"
          >
            Актуальные новости
          </TopTitle>
          {/* <SearchBar /> */}
        </TopHeader>
        <Content>
          <TitleBar>
            {/* <Title
              level=''
              desktopFont='w800 s40 h48'
            >
              Актуальные новости
            </Title>
            <SortSelect>
              <Dropdown
                label='Сортировать'
                items={items}
              />
            </SortSelect> */}
          </TitleBar>
          <List>
            <ArticleArchiveList
              list={newsOnPage}
            />
          </List>
          <PaginationCustom
            page={page}
            pagesCount={pagesCount}
            onLeft={() => onPrevPage()}
            onRight={() => onNextPage()}
            onPage={(id) => onPage(id)}
          />
        </Content>
        {/* { activity && <SimilarEvents title='Ближайшие мероприятия' list={activity} /> } */}
      </Container>
    </Layout>
  );
}

const TopTitle = styled(TitleC)`
  margin-bottom: 40px;
`;

// const Title = styled(TitleC)``;

const TopHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const SortSelect = styled.div``;

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 32px;
`;

// const SimilarEvents = styled(SimilarEventsC)`
//   margin-bottom: 100px;
// `;

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
    padding: 0 2%;
  }

  @media ${device.tablet} {
    padding: 0 2%;
  }
  
  @media ${device.desktopS} {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  }
`;
