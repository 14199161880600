import React from 'react';
import { styled } from '@linaria/react';

import { SocialContacts } from 'components/SocialContacts';
import { Text } from 'components/Text';
import { device } from 'styles/devices';

import contacts from './contacts.json';
import directions from './directions.json';
import information from './information.json';
import materials from './materials.json';
import { ViewFooterColumn } from './ViewFooterColumn';


function ViewFooterInfo() {
  return (
    <>
      <Container>
        <ViewFooterColumn
          title="Продукты"
          list={materials}
        />
        <ViewFooterColumn
          title="Направления деятельности"
          list={directions}
        />
        <ViewFooterColumn
          title="Контакты"
          list={contacts}
          footerNode={(
            <SocialContacts
              ytLink="https://www.youtube.com/@innopolisuniversityido"
              vcLink="https://vc.ru/u/1271854-it-obrazovanie-v-innopolise"
              tgLink="https://t.me/unionepro_bot"
              vkLink="https://vk.com/edtechiu"
            />
          )}
        />
        <ViewFooterColumn
          title="Полезная информация"
          list={information}
        />
      </Container>
      <Copyright>
        <Text color="white">© Университет Иннополис, 2023</Text>
      </Copyright>
    </>
  );
}

const Container = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media ${device.tablet} {
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    /* gap: 24px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @media ${device.desktopM} {
    display: flex;
    flex-direction: row;
    gap: 0;
    /* hack */
    /* margin-right: -82px; */
    /* margin-left: 82px; */
  }
  @media ${device.desktopS} {
    gap: 24px;
  }
`;

const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: 0.32;

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: column-reverse;
    margin-top: 30px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

// const Divider = styled.div`
//   height: 1px;
//   margin: 32px -8px 16px;
//   background-color: white;
//   opacity: 10%;
// `;

export { ViewFooterInfo };
