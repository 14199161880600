import React from 'react';
import {
  ArrowRightOutlined,
} from '@ant-design/icons';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Collapse } from 'antd';

import { ReactComponent as AngleDownIcon } from '../../assets/icons/angle-down.svg';
import { IAnswer } from '../../models/IQuestion';
import { cc } from '../../utils/classcat';
import { Title as TitleA, Title } from '../Title';


type Props = {
  data: QuestionItem[];
  searchTitle: string;
};

type QuestionItem = {
  _id: string;
  section: {
    title: string;
  };
  system: {
    title: string;
  };
  question: string;
  answers: IAnswer[];
};

const { Panel } = Collapse;

export function KnowledgeSearchCollapse({ data, searchTitle }: Props) {
  const [isRotated, setIsRotated] = React.useState(Array(data.length).fill(false));

  const handleClick = (index: number) => {
    const rotated = [...isRotated];
    rotated[index] = !rotated[index];
    setIsRotated(rotated);
  };

  return (
    <div>
      <Title
        level=""
        desktopFont="w800 s40 h48"
        className={mainTitle}
      >
        <>
          Результаты поиска: <span style={{ color: '#909EBB' }}>{searchTitle}</span>
        </>
      </Title>
      <Container>
        <Collapse className={collapse} bordered={false}>
          {
            data
                        && data.map((item: QuestionItem, index: number) => (
                          <>
                            <Title
                              level=""
                              desktopFont="w700 s20 h24"
                            >
                              <>
                                {item.system?.title}
                                <ArrowRightOutlined
                                  size={24}
                                  style={{
                                    color: '#909EBB',
                                    marginRight: '12px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <span style={{ color: '#0071CE' }}>{item.section?.title}</span>
                              </>
                            </Title>
                            <Panel
                              showArrow={false}
                              key={item._id}
                              // @ts-ignore
                              onClick={() => handleClick(index)}
                              header={(
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={() => {}}
                                  className={header}
                                >
                                  <Title level="" desktopFont="w700 s24 h28">{item.question}</Title>
                                  <AngleDownIcon
                                    className={cc({ [icon]: true, rotated: isRotated[index] })}
                                    onClick={() => handleClick(index)}
                                  />
                                </div>
                              )}
                            >
                              <CollapseContent>
                                <Block>
                                  <div className={block}>
                                    {item?.answers.map((answer) => (
                                      <>
                                        <Answer level="" desktopFont="w400 s16 h20">{answer.text}</Answer>
                                        <img src={answer.picture} alt="" />
                                      </>
                                    ))}
                                  </div>
                                </Block>
                              </CollapseContent>

                            </Panel>
                          </>
                        ))
          }
        </Collapse>
      </Container>
    </div>
  );
}

const Block = styled.div`
 display: flex;
 flex-wrap: wrap;
 gap: 60px;
`;


const Answer = styled(TitleA)`
    margin: 24px 0 32px 0;
    max-width: 79%;
`;

const block = css`
    :nth-child(2){
    margin-top: 43px;
 }
`;

const mainTitle = css`
    margin-bottom: 32px;
`;

const Container = styled.div`
 display: flex;
 width: 1540px;
 padding: 40px;
 flex-direction: column;
 align-items: flex-start;
 gap: 40px;
 background: #fff;
 border-radius: 24px;
 margin: 0 auto;
`;

const icon = css`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  background: var(--elements-primary-7, rgba(0, 113, 206, 0.07));
  fill: #0071CE;
  text-align: end;
  &:hover{
    fill: #fff;
    background: #0071CE;
  }
  &.rotated {
    transform: rotate(180deg);
  }
`;

const collapse = css`
   display: grid;
   width: 100%;
   background-color: #ffffff;
   border: none;
   border-radius: 16px;
 
   &.ant-collapse-borderless {
    background-color: #ffffff;
    border-bottom: none; 
    padding-left: none;
     > .ant-collapse-item {
          border-bottom: none;
     }
   }
   
   &.ant-collapse, .ant-collapse-icon-position-start, .ant-collapse-borderless {
        background-color: #ffffff;
        border-bottom: none;
   }
   &.ant-collapse-header{
      padding-left: none;
   }
   &.ant-collapse-content{
     background: #F5FBFF;
   }
`;

const CollapseContent = styled.div`
     background: #F5FBFF;
     margin-top: -30px;
     border-radius: 0 0 16px 16px;
     padding: 24px 32px;
`;

const header = css`
   background: #F5FBFF;
   padding: 24px 32px;
   border-radius: 16px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-left: 4px;
`;
