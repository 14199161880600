import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';
// import { ShowCaseBlock } from './showcase/ShowCaseBlock';


type Props = {
  list: any[]
  posY?: number
  Component: any
};

export function SliderList({
  list, posY = -30, Component,
}: Props) {
  return (
    <List position={posY}>
      {
        Array.isArray(list) && (
          list.map((item) => <Component activity={item} key={item?.id} />)
        )
      }
    </List>
  );
}

const List = styled.div<{ position: number }>`
  position: relative;
  right: ${(props) => props.position}px;
  transition: right 50ms ease-in-out;
  display: flex;

  @media ${device.mobile}, ${device.minMobile} {
    gap: 16px;
  }

  @media ${device.tablet} {
    gap: 20px;
  }

  @media ${device.desktopS} {
    gap: 32px;
  }
`;

// export {
//   ShowCaseList,
//   List as ShowCaseListStyled,
// };
