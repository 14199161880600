import React from 'react';
import { styled } from '@linaria/react';

import { ActivityArchiveBlock } from 'components/activity-archive/ActivityArchiveBlock';
import { Title as TitleC } from 'components/Title';
import { IActivity } from 'models/IActivity';


type ActivitySearchResultsProps = {
  data: IActivity[];
};

export function ActivitySearchResults({ data }: ActivitySearchResultsProps) {
  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s60 h78"
      >
        {`Результаты запроса: ${data.length} найдено`}
      </Title>
      <List>
        {data.length > 0 && data.map((item) => (
          <ActivityArchiveBlock activity={item} key={item._id} />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.div`
  background-color: #F5F6F8;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled(TitleC)``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

