import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';

import { Heading } from 'components/Heading';
import { device } from 'styles/devices';

import ArrowRight from '../../assets/icons/arrow-right.svg';


export interface BusinessCardProps {
  icon: string;
  title?: string;
  text: string;
  link?: string;
  linkTitle?: string;
}

export function BusinessCard({
  icon,
  title,
  text,
  link,
  linkTitle,
} : BusinessCardProps) {
  return (
    <Col xs={24} lg={12} xl={8}>
      <BusinessCardContainer>
        <img src={icon} alt="" />
        <div>
          {!!title && <StyledHeading level={5}>{title}</StyledHeading>}
          <p>
            {text}
          </p>
          {!!link && !!linkTitle
          && (
            <Link to={link} className={linkClass}>
              {linkTitle}
              <ArrowIcon src={ArrowRight} alt={linkTitle} />
            </Link>
          )}
        </div>
      </BusinessCardContainer>
    </Col>
  );
}

const StyledHeading = styled(Heading)`
  && {
    margin-bottom: 10px;
  }
`;

const BusinessCardContainer = styled.div`
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 74px 1fr;
    grid-gap: 10px;
  }
  @media ${device.desktopS} {
    display: grid;
    grid-template-columns: 95px 1fr;
    grid-gap: 10px;
  }

  > img {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
    @media ${device.desktopS} {
      width: 100%;
    }
  }
  p {
    @media ${device.mobile} {
      font-size: 14px;
    }
    @media ${device.tablet} {
      font-size: 16px;
    }
    color: var(--text-secondary);
    margin-bottom: 0;
  }
`;

const linkClass = css`
  margin-top: 10px;
  float: left;
  color: var(--brand-primary);
  font-weight: 500;
`;

const ArrowIcon = styled.img`
  margin-left: 8px;
`;
