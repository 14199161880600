import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';

import { device } from 'styles/devices';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';


export function CourseDownloadLink() {
  return (
    <div className={linkClass}>
      <Text>
        Скачайте презентацию, чтобы подробнее ознакомиться со всеми курсами для
        сотрудников.
      </Text>
      <Link to="/">
        <DownloadText>
          Скачать презентацию
          <Icon title="Скачать презентацию" />
        </DownloadText>
      </Link>
    </div>
  );
}

const linkClass = css`
  display: flex;
  border-radius: 8px;
  background: var(--el-hover-2);
  flex-direction: column;
  padding: 12px;
  color: var(--text-primary);
  text-align: center;
  align-items: center;
  margin-top: 15px;
  @media ${device.tablet} {
    margin-top: 0;
    margin-bottom: 24px;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    color: var(--bg-secondary);
    background: var(--brand-primary);
    padding: 0 12px;
  }
  @media ${device.desktopS} {
    padding: 0 20px;
  }
`;

const Text = styled.span`
  padding: 8px 0;
`;

const DownloadText = styled.span`
  padding: 8px 0;
  margin-left: 20px;
  border-left: 1px solid #fff5;
  white-space: nowrap;
  align-items: center;
  display: flex;
  color: var(--brand-primary);
  font-weight: 500;
  :hover{
    color: rgba(255, 255, 255, 0.68);
  }
  @media ${device.tablet} {
    margin-left: 0;
    text-align: end;
    color: var(--bg-secondary);
    border-left: none;
    padding-left: 18px;
  }
  @media ${device.desktopS} {
    margin-left: 20px;
    border-left: 1px solid #fff5;
  }
`;

const Icon = styled(ExportIcon)`
  margin-left: 10px;
  
  @media ${device.desktopS} {
    margin-left: 20px;
  }
  path {
    fill: var(--brand-primary);
    @media ${device.tablet} {
      fill: var(--bg-secondary);
    }
  }
`;
