/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Cone() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(76, 82, 3);
    if (isTablet) return glyphSizes(102, 111, 2);
    if (isDesktop) return glyphSizes(194, 212);

    return glyphSizes(194, 212);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M72.9183 142.858C98.211 164.381 114.086 188.594 112.472 202.046L194 0L8.07227 113.264C20.9877 109.766 47.6257 121.335 72.9183 142.858Z" fill="white" />
      <path d="M73.1867 142.051C51.9301 124.025 28.79 112.457 14.2602 112.457C12.1076 112.457 10.2241 112.726 8.60972 113.264C6.18808 114.071 4.03552 115.147 2.69016 117.03C-6.72731 128.33 9.14786 157.117 39.2838 182.406C60.5404 200.431 83.6805 212 98.2103 212C103.323 212 107.359 210.655 109.78 207.695C111.126 206.081 112.202 203.929 112.471 201.239C113.816 187.249 97.9412 163.036 73.1867 142.051Z" fill="#0071CE" />
    </SVGIcon>
  );
}
