import React from 'react';
import { styled } from '@linaria/react';
import { Progress } from 'antd';

import { LRButtons } from 'components/buttons/LRButtons';
import { Title } from 'components/Title';


type Props = {
  startPage: number
  currentPage: number
  totalPages: number
  onPrevPage: () => void
  onNextPage: () => void
  delay?: number
  className?: string
};

function DigestPagination({
  startPage,
  currentPage,
  totalPages,
  onPrevPage,
  onNextPage,
  delay,
  className,
}: Props) {
  const progress = (currentPage / totalPages) * 100;

  return (
    <Container className={className}>
      <ProgressBar>
        <Count>
          <Title level={1}>{currentPage}</Title>
          <Title level={1} color="thirdly">/</Title>
          <Title level={1} color="thirdly">{totalPages}</Title>
        </Count>
        <Bar>
          <Progress
            percent={progress}
            showInfo={false}
          />
        </Bar>
      </ProgressBar>
      <LRButtons
        leftDisabled={currentPage === startPage}
        rightDisabled={currentPage === totalPages}
        onLeft={onPrevPage}
        onRight={onNextPage}
        delay={delay}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressBar = styled.div`
  display: flex;
  width: 100%;
`;

const Count = styled.div`
  display: flex;
  gap: 4px;
`;

const Bar = styled.div`
  width: inherit;
  margin-left: 16px;
`;

export { DigestPagination, Container };
