import React from 'react';
import { styled } from '@linaria/react';
import { Progress } from 'antd';

import { device } from 'styles/devices';


interface Props {
  current: number;
  max: number;
}

export function CommonProgress({ current, max }: Props) {
  return (
    <Container>
      <div className="counter">
        <span>{current}</span>
        <span>/{max}</span>
      </div>
      <ProgressContainer>
        <Progress
          percent={Math.floor((100 * current) / max)}
          showInfo={false}
          strokeColor="#0071ce"
          strokeWidth={2}
        />
      </ProgressContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  .counter {
    span {
      color: var(--el-lines);
      &:first-child {
        color: var(--brand-primary);
      }
    }
  }
`;

const ProgressContainer = styled.div`
  width: 98px;
  @media ${device.tablet} {
    width: 180px;
  }
`;
