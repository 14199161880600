import React, { useState } from 'react';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';

import { Button, ButtonCSS } from 'components/common/Button';
import { Input as InputC, inputCss } from 'components/common/Input';
// import { device } from 'styles/devices';


type SearchBarProps = {
  setSearchValue: (value: string) => void;
  title?: string;
};


export function SearchBar(props: SearchBarProps) {
  const { setSearchValue, title } = props;

  const [search, setSearch] = useState<string>('');

  return (
    <Container>
      <Input
        placeholder={title || 'Поиск мероприятий'}
        bordered={false}
        value={search}
        onChange={(e): void => setSearch(e.target.value)}
      />
      <Button
        type="primary"
        textColor="white"
        onClick={(): void => setSearchValue(search)}
      >
        <ButtonContent>
          <SearchOutlined size={48} />
          Искать
        </ButtonContent>
      </Button>
    </Container>
  );
}

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px !important;
`;

const Input = styled(InputC)`
  width: 100%;
  padding-left: 24px; 

  .${inputCss} {
    font-size: 18px;
  }
`;

const Container = styled.div`
  width: 800px;
  padding: 8px;
  background-color: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .${ButtonCSS} {
    padding: 16px 20px;
    border-radius: 50px;
    
  }
`;
