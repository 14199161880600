import React from 'react';
import { styled } from '@linaria/react';

import { Title } from 'components/Title';
import { device } from 'styles/devices';

import { ActivitiesList } from './ActivitiesList';
import list from './data.json';


function Activities() {
  return (
    <Container>
      <Title
        level=""
        desktopFont="w800 s40 h48"
        tabletFont="w800 s32 h40"
        mobileFont="w800 s24 h28"
      >
        Направления деятельности
      </Title>
      <Content>
        <ActivitiesList list={list} />
      </Content>
    </Container>
  );
}

const Content = styled.div`
  margin-top: 32px;

  @media ${device.mobile}, ${device.minMobile} {
    margin-top: 20px;
  }
`;

const Container = styled.div`
  position: relative;

  // compensate gap from <ShowCase /> & <AboutPlatform /> absence
  @media ${device.mobile}, ${device.minMobile} {
    gap: 60px;
  }

  @media ${device.tablet} {
    gap: 80px;
  }
  
  @media ${device.desktopS} {
    margin-top: 136px;
  }
`;

export { Activities };
