import React from 'react';
import { styled } from '@linaria/react';
import { Row } from 'antd';

import { Heading } from 'components/Heading';
import BooksIcon from 'assets/icons/books-library-study.svg';
import DiplomaIcon from 'assets/icons/diploma.svg';
import ExamIcon from 'assets/icons/evaluation-exam.svg';
import PhysicsIcon from 'assets/icons/physics.svg';
import RatingIcon from 'assets/icons/rating.svg';
import CapIcon from 'assets/icons/square-academic-cap.svg';

import { BusinessCard } from './BusinessCard';
import data from './data.json';


interface IIconsList {
  [index: string]: string;
}
const iconsList: IIconsList = {
  diplomaIcon: DiplomaIcon,
  examIcon: ExamIcon,
  booksIcon: BooksIcon,
  physicsIcon: PhysicsIcon,
  capIcon: CapIcon,
  ratingIcon: RatingIcon,
};

export function BusinessInfo() {
  return (
    <Container>
      <Heading level={2}>Формат сотрудничества с бизнесом</Heading>
      <Row gutter={[30, 20]}>
        {data.map((card) => (
          <BusinessCard {...card} icon={iconsList[card.icon]} key={card.icon} />
        ))}
      </Row>
    </Container>
  );
}

const Container = styled.div`
  h2.ant-typography {
    padding-bottom: 15px;
  }
`;
