import React from 'react';
import { styled } from '@linaria/react';

import { Button, ButtonCSS } from 'components/common/Button';
import { Title, Title as TitleC } from 'components/Title';
import { device } from 'styles/devices';
import { useSelector } from 'utils/use-selector';


type Props = {
  title: string
  content: string
  image?: string
  link?: string
  decorNumber: number;
};

function EducationCard({
  title,
  content,
  image,
  link,
  decorNumber,
}: Props) {
  const { isMobile } = useSelector((state) => state.common);

  return (
    <Container>
      <Info>
        <Title
          level=""
          desktopFont="w800 s30 h40"
          tabletFont="w800 s26 h34"
          mobileFont="w700 s18 h24"
        >
          {title}
        </Title>
        <SubTitle
          level=""
          desktopFont="w500 s18 h24"
          tabletFont="w500 s16 h24"
          mobileFont="w500 s14 h20"
          color="secondary"
        >
          {content}
        </SubTitle>
        <Footer>
          <Anchor href={link} target="_blank">
            <Button type="ghost" textColor="brand">
              {
                isMobile
                  ? 'Вступить в сотрудничество'
                  : 'Узнать подробнее'
              }
            </Button>
          </Anchor>
        </Footer>
      </Info>
      <Image decorNumber={decorNumber}>
        <img src={image} className="picture" alt="" />
      </Image>
    </Container>
  );
}

const SubTitle = styled(TitleC)`
  @media ${device.mobile}, ${device.minMobile} {
    margin: 8px 0;
  }

  @media ${device.tablet} {
    margin: initial;
  }
`;

const Anchor = styled.a``;

const Footer = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: flex-end;
  
  @media ${device.minMobile}{
     .${ButtonCSS} {
        padding: 10px 12px;
        > span {
          font-size: 14px;
        }
     }
  }
`;

const Info = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  
  @media ${device.mobile}, ${device.minMobile} {
    height: 100%;
    padding: 20px;
    min-width: 285px;
    max-height: 410px;
  }

  @media ${device.tablet} {
    padding: 40px;
    width: 427px;
    gap: 16px;
  }

  @media ${device.desktopS} {
    width: 353px;
    padding: 30px 0 48px 40px;

    .${ButtonCSS} {
      position: relative;
    }
  }
  @media ${device.desktopM}{
    width: 427px;
  }
  
`;

const Image = styled.div<{ decorNumber: number }>`
  display: flex;
  align-items: center;

  background-image: ${
  // eslint-disable-next-line global-require,import/no-dynamic-require
  (props) => `url(${require(`assets/icons/decor-${props.decorNumber}.png`)}
    )`};
  background-repeat: no-repeat;
  background-position: top;
  background-size: inherit;

  overflow: hidden;

  > img {
    transform: matrix(0.97, 0.25, -0.22, 0.97, 0, 0);
    margin-right: 0;
    object-fit: cover;
    border-radius: 112px;
  };

  @media ${device.mobile}, ${device.minMobile} {
    width: 155px;
    height: 180px;
    background-size: contain;
    display: flex;
    justify-content: flex-end;
    > img {
      height: 114px;
      width: 82px;
    }
  }

  @media ${device.tablet} {
    height: 289px;
    width: 287px;
    > img {
      width: 153px;
      height: 212px;
    }
  }

  @media ${device.desktopM} {
    > img {
      height: 260px;
    }
  }

  @media ${device.desktopS} {
    width: 321px;
    height: 340px;

    > img {
      height: 260px;
      width: 197px;
    }
  }
`;

const Container = styled.div`
  border-radius: 24px;
  background-color: white;
  display: flex;
  overflow: hidden;
  justify-content: space-between;

  @media ${device.minMobile}, ${device.mobile} {
    min-width: 92%;
    flex-direction: column-reverse;
    padding-top: 20px;
  }

  @media ${device.tablet}, ${device.desktopS} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    height: 330px;
  }

  @media ${device.desktopS} {
    height: initial;
  }

  @media ${device.desktopM} {
    height: 390px;
  }
`;

export { EducationCard };
