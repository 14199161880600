import React from 'react';
import {
  CalendarFilled,
  ClockCircleFilled,
  WalletOutlined,
  EnvironmentOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';

import { Button, ButtonCSS } from 'components/common/Button';
import { Text as TextC } from 'components/Text';
import { Title as TitleC } from 'components/Title';
import { toHumanDate, toHumanTime } from 'utils/to-human-date';


type Props = {
  pubDate: string
  start_date: string
  end_date: string
  price?: number
  price_visible?: boolean
  place?: string
  link_to_entry:string
};

export function ActivityPlace({
  pubDate,
  start_date,
  end_date,
  price = 0,
  price_visible,
  place = '',
  link_to_entry = '',
}: Props) {
  const isSameDate = dayjs(start_date).isSame(end_date, 'day');

  const checkTime = () => {
    const startTime = toHumanTime(start_date);
    const endTime = toHumanTime(end_date);

    if (startTime !== '00:00' && endTime !== '00:00') {
      return (
        <Item>
          <ClockCircleFilled />
          <Text>{`${startTime} - ${endTime}`}</Text>
        </Item>
      );
    }

    if (startTime !== '00:00' && endTime === '00:00') {
      return (
        <Item>
          <ClockCircleFilled />
          <Text>{startTime}</Text>
        </Item>
      );
    }

    if (startTime === '00:00' && endTime !== '00:00') {
      return (
        <Item>
          <ClockCircleFilled />
          <Text>{endTime}</Text>
        </Item>
      );
    }

    return null;
  };


  return (
    <Container>
      <Header>
        <Title
          color="white"
          level=""
          desktopFont="w800 s24 h30"
        >
          Место проведения и время
        </Title>
        <Date
          color="white"
        >
          {`Дата публикации: ${dayjs(pubDate).format('DD.MM.YY')}`}
        </Date>
      </Header>

      <Divider />

      <Info>
        <Item>
          <CalendarFilled />
          <Text>
            {isSameDate
              ? toHumanDate(start_date)
              : `${toHumanDate(start_date)} - ${toHumanDate(end_date)}`}
          </Text>
        </Item>
        {checkTime()}
        {price_visible && (
          <Item>
            <WalletOutlined />
            <Text>{price ? `${price} ₽` : 'Бесплатно'}</Text>
          </Item>
        )}
        <Item>
          <EnvironmentOutlined />
          <Text>{place}</Text>
        </Item>
      </Info>
      <Submit href={link_to_entry} target="_blank">
        <Button
          textColor="white"
        >
          Записаться на мероприятие
        </Button>
      </Submit>
      <Footer>
        <ArchiveLink href="/activity">
          Перейти в архив мероприятий
        </ArchiveLink>
        <ArrowRightOutlined
          style={{ color: 'var(--brand-primary)' }}
        />
      </Footer>
    </Container>
  );
}

const Title = styled(TitleC)``;

const Date = styled(TextC)`
  && {
    display: block;
    margin-top: 12px;
    opacity: 0.6;
  }
`;

const Header = styled.div``;

const Divider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: white;
  opacity: 0.1;
`;

const ItemText = styled(TitleC)``;

function Text(props: any) {
  return <ItemText {...props} color="white" />;
}

const Item = styled.li`
  list-style-type: none;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Info = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Submit = styled.a`
  margin-top: 32px;

  .${ButtonCSS} {
    width: 100%;
    border-radius: 8px;
    background-color: #7D6FE1;
    color: white;
    display: flex;
    justify-content: center;
  }
`;

const ArchiveLink = styled.a``;
const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div`
  background-color: #3B4168;
  color: white;
  padding: 40px;
  border-radius: 24px;
`;
