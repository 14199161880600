export const cc = (names: any) => {
  if (typeof names === 'string' || typeof names === 'number') {
    return `${names}`;
  }

  let out = '';

  if (Array.isArray(names)) {
    for (let i = 0, tmp; i < names.length; i + 1) {
      // eslint-disable-next-line no-cond-assign
      if ((tmp = cc(names[i])) !== '') {
        out += (out && ' ') + tmp;
      }
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in names) {
      if (names[k]) out += (out && ' ') + k;
    }
  }

  return out;
};
