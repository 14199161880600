import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import type { PopularCoursesBlockProps } from './PopularCoursesBlock';
import { CoursesGroupProps, CoursesTagProps, PopularCoursesBlock } from './PopularCoursesBlock';


type Props = {
  list?: PopularCoursesBlockProps[]
  tags?: CoursesTagProps[]
  groups?: CoursesGroupProps[]
  className?: string
};

type courseTagObject = {
  _id: string
  title: string
};

function PopularCoursesList({ list, tags, className }: Props) {
  const getTagId = (courseTag: courseTagObject) => tags?.find((tag) => tag?.id === courseTag?._id) || null;

  return (
    <List className={className}>
      {
        Array.isArray(list) && list.map((item) => (
          <PopularCoursesBlock key={item._id} {...item} tagId={getTagId(item.tag)} />
        ))
      }
    </List>
  );
}

const List = styled.div`
  @media ${device.mobile}, ${device.minMobile} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media ${device.tablet}, ${device.desktopS} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    gap: 20px;
  }
  
  @media ${device.desktopS} {
    gap: 32px;
  }
`;

export { PopularCoursesList };
