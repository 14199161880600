import React from 'react';
import { styled } from '@linaria/react';
import Title from 'antd/lib/typography/Title';
import cn from 'classnames';

import { device } from 'styles/devices';


declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];
interface HeadingProps {
  level: typeof TITLE_ELE_LIST[number];
  children?: string | number | React.ReactElement | boolean;
  className?: string;
}

export function Heading({ level, children, className }: HeadingProps) {
  return (
    <TitleStyled level={level} className={cn(`h${level}`, className)}>
      {children}
    </TitleStyled>
  );
}

const TitleStyled = styled(Title)`
  &.ant-typography {
    color: var(--text-primary);
    font-family: var(--font-header);
  }
    &.h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      @media ${device.desktopS} {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
      } 
    }
    &.h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 1.153845;
      @media ${device.desktopS} {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
      }
    }
    &.h3 {
      font-weight: 600;
      @media ${device.mobile}{
        font-size: 20px ; 
        line-height: 24.38px ;
      }
      @media ${device.tablet}{
        font-size: 22px;
        line-height: 28px;
      }
      @media ${device.desktopS} {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
      }
    }
    &.h4 {
      font-weight: 500;
      font-size: 20px;
      line-height: 24.38px;
      @media ${device.desktopS} {
        font-weight: 600;
      }
    }
    &.h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.25;
      @media ${device.desktopS} {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.3333333;
      }
    }
  
`;
