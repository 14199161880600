import React from 'react';
import { css } from '@linaria/core';
import { Tag, TagProps } from 'antd';

import { Title, TitleProps, TitleCSS } from 'components/Title';
import { device } from 'styles/devices';
import { cc } from 'utils/classcat';


type Props = TagProps & {
  text?: string
  active?: boolean
  className?: string
  textColor?: TitleProps['color']
  desktopFontLevel?: string
  tabletFontLevel?: string
  mobileFontLevel?: string
  level?: string
  desktopFont?: string
  tabletFont?: string
  mobileFont?: string
};

function EMOPTag({
  children,
  className,
  text,
  active,
  textColor,
  desktopFontLevel = '0',
  tabletFontLevel = '02',
  mobileFontLevel = '01',
  level,
  desktopFont,
  tabletFont,
  mobileFont,
  ...rest
}: Props) {
  const cn = cc({ [container]: true, active });

  return (
    <Tag className={cn} {...rest}>
      <Title
        desktopFontLevel={desktopFontLevel}
        tabletFontLevel={tabletFontLevel}
        mobileFontLevel={mobileFontLevel}
        level={level}
        desktopFont={desktopFont}
        tabletFont={tabletFont}
        mobileFont={mobileFont}
        color={textColor || 'white'}
      >
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{children || text}</>
      </Title>
    </Tag>
  );
}

const container = css`
  &.ant-tag {
    display: inline-block;
    border-radius: 20px;
    color: var(--text-secondary);
    max-width: fit-content;

    &.active {
      background-color: var(--brand-primary);
      border-color: var(--el-active);
      color: white;
    }

    @media ${device.mobile} {
      padding: 1px 14px;
    }

    @media ${device.tablet} {
      padding: 2px 12px;
    }

    @media ${device.desktopS} {
      padding: 6px 20px;
    }
  }
`;

export {
  EMOPTag as Tag,
  container as TagCSS,
  TitleCSS as TagTextCSS,
};
