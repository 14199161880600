import React from 'react';

import { Heading } from 'components/Heading';

import data from './data.json';
import { StagesList } from './StagesList';


export function StagesInfo() {
  return (
    <>
      <Heading level={2}>Этапы работы</Heading>
      {data.map((list) => (<StagesList key={list.id} {...list} />))}
    </>
  );
}
