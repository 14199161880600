import React from 'react';
import { styled } from '@linaria/react';

import { wordDeclension } from 'utils/wordDeclension';

import { Title as TitleD, Title as TitleT } from '../Title';


export type Props = {
  _id: string
  icon?: string
  title: string
  question_count: number
  onClick: (id: string) => void;
  color: string | undefined;
};


const wordDeclensionForm = wordDeclension('ресурс', 'ресурса', 'ресурсов');

export function KnowledgeBaseSystemBlock({
  icon,
  title, question_count, _id, onClick, color,
}: Props) {
  const handleOnClick = () => {
    onClick(_id);
  };

  const changeColor = _id === color ? 'color' : '';

  return (
    <Container className={changeColor} onClick={handleOnClick}>
      <CompanyLogo>
        <img src={icon} alt="icon" />
      </CompanyLogo>
      <Title
        level=""
        desktopFont="w700 s24 h28"
        className={changeColor}
      >
        {title}
      </Title>
      <Description
        level=""
        desktopFont="w400 s16 h20"
        className={changeColor}
      >
        {`${question_count} ${wordDeclensionForm(question_count)}`}
      </Description>
    </Container>
  );
}

const CompanyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(TitleT)`
  margin: 32px 0 8px 0;
  &.color{
    color: #ffffff !important;
  }
`;

const Description = styled(TitleD)`
  overflow-x: hidden;
  &.color{
    color: #6F7C98;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 307px;
  height: 100%;
  border-radius: 20px;
  background-color: #E4E7F2;
  cursor: pointer;
  padding: 50px 30px;
  &.color {
    background-color: #3B4168;
  }
`;
