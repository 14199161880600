/* eslint-disable max-len */

import React from 'react';

import { SVGIcon } from 'components/common/SVGIcon';
import { glyphSizes } from 'utils/glyphSizes';
import { useBreakpoints } from 'utils/useBreakpoints';


export function Eye() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const getSizes = () => {
    if (isMobile) return glyphSizes(80, 45, 3);
    if (isTablet) return glyphSizes(108, 61, 2);
    if (isDesktop) return glyphSizes(222, 125);

    return glyphSizes(222, 125);
  };

  return (
    <SVGIcon {...getSizes()}>
      <path d="M0 62.4577C0 62.4577 49.6612 124.915 110.958 124.915C172.254 124.915 222 62.4577 222 62.4577C222 62.4577 172.339 0 111.042 0C49.7455 0 0 62.4577 0 62.4577Z" fill="#F5F6F8" />
      <path d="M78.8341 30.213C83.2184 25.8122 88.3616 22.427 93.7577 20.2266C99.1538 18.0262 104.972 16.8413 110.705 16.8413H110.958C111.211 16.8413 111.464 16.8413 111.717 16.8413C123.099 17.0106 134.482 21.496 143.166 30.213L124.027 49.3397C120.401 45.7005 115.679 43.9233 110.958 43.9233C106.236 43.9233 101.43 45.7005 97.8891 49.3397C90.6381 56.6179 90.6381 68.3816 97.8891 75.6599C99.2382 77.014 100.756 78.1142 102.358 78.9605C103.707 79.6376 105.14 80.23 106.574 80.5685C106.826 80.6531 107.164 80.7378 107.417 80.7378C108.597 80.9917 109.777 81.0763 110.958 81.0763C112.138 81.0763 113.319 80.9917 114.499 80.7378C114.836 80.6531 115.258 80.5685 115.595 80.4839C116.101 80.3992 116.607 80.23 117.113 80.0607C117.872 79.8068 118.63 79.4683 119.305 79.1298C120.991 78.2835 122.593 77.0986 124.027 75.6599L143.082 94.8711L142.829 95.125C138.529 99.4412 133.47 102.657 128.158 104.858C122.762 107.058 116.944 108.243 111.211 108.243H110.958C110.705 108.243 110.452 108.243 110.199 108.243C98.8166 108.074 87.4341 103.588 78.7497 94.8711C61.0437 77.014 61.0437 47.9856 78.8341 30.213Z" fill="#F7B055" />
      <path d="M78.8333 94.7865L97.9727 75.6599C90.7216 68.3816 90.7216 56.6179 97.9727 49.3396L78.8333 30.1284C61.0429 47.9855 61.0429 77.014 78.8333 94.7865Z" fill="#EB5757" />
      <path d="M78.8341 94.7862C87.5185 103.503 98.9009 107.989 110.283 108.158C110.536 108.158 110.789 108.158 111.042 108.158H111.295C117.029 108.158 122.846 106.973 128.242 104.773C133.554 102.572 138.529 99.3563 142.913 95.0401L143.166 94.7862L124.027 75.6596C122.593 77.0983 120.991 78.2832 119.305 79.1295C118.546 79.468 117.872 79.8065 117.113 80.0604C116.607 80.2297 116.101 80.3989 115.595 80.4836C115.258 80.5682 114.836 80.6528 114.499 80.7375C113.319 80.9914 112.138 81.076 110.958 81.076C109.777 81.076 108.597 80.9914 107.417 80.7375C107.164 80.6528 106.826 80.6528 106.574 80.5682C105.14 80.2297 103.707 79.7219 102.358 78.9602C100.756 78.1139 99.2382 77.0137 97.8891 75.6596L78.8341 94.7862Z" fill="#0071CE" />
      <path d="M111.043 81.0761C100.798 81.0761 92.4934 72.7402 92.4934 62.4573C92.4934 52.1744 100.798 43.8385 111.043 43.8385C121.287 43.8385 129.592 52.1744 129.592 62.4573C129.592 72.7402 121.287 81.0761 111.043 81.0761Z" fill="#1B2C89" />
    </SVGIcon>
  );
}
