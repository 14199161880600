import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { Text } from 'components/Text';
import { Title, Title as TitleC } from 'components/Title';

import { QuestionItems } from '../../models/IQuestion';


type Props = {
  data: QuestionItems[] | any;
  onClick: (value :boolean) => void;
  setSingleSearch: (value: string) => void;
};

export function KnowledgeSearchResult({ data, onClick, setSingleSearch }: Props) {
  const handleGetMoreResult = () => {
    onClick(true);
  };

  const getSingleSearch = (item: string): void => {
    setSingleSearch(item);
    onClick(true);
  };

  return (
    <Container>
      {data && data.length > 1 ? data.map((item: QuestionItems) => (
        <Block onClick={() => getSingleSearch(item.question)}>
          <Title className={title} color="thirdly" desktopFont="w400 s16 h20">
            {item.question}
          </Title>
          <Text color="thirdly" desktopFont="w400 s16 h20">{item.system?.title}</Text>
        </Block>
      ))
        : (
          <TitleC className={notFound} color="thirdly" desktopFont="w400 s16 h20">
            ничего не найдено
          </TitleC>
        )}
      {data && data.length > 1 && (
        <Block onClick={handleGetMoreResult}>
          <Title color="primary" desktopFont="w500 s18 h24">
            Смотреть все результаты поиска
          </Title>
          <RightOutlined />
        </Block>
      )}
    </Container>
  );
}


const Container = styled.div`
    width: 800px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 12px;
    position: absolute;
    top: 200px;
    &.shown{
      display: none;
    }
`;
const title = css`
 font-family: var(--font-content);
`;

const notFound = css`
    text-align: center;
`;

const Block = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 16px;
    border-radius: 10px;
    cursor: pointer;
    border-radius: 10px;
    :hover{
        background: rgba(0, 113, 206, 0.07);
        > div, span {
          color: #0071CE;
        }
    }
`;
