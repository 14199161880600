// @ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { IEvent } from './event.types';

import { PORTAL_API } from '../config/config.models';


export const eventAPI = createApi({
  reducerPath: 'eventAPI',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['Event'],
  endpoints: (build) => ({
    fetchAllEvents: build.query<IEvent[], number>({
      query: (/* limit: number = 5 */) => ({
        url: '/event/all',
        // params: {
        //   _limit: limit
        // }
      }),
      providesTags: () => ['Event'],
    }),
  }),
});
