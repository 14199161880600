import React from 'react';
import {
  RightCircleOutlined,
} from '@ant-design/icons';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';

import { Text as TextC } from 'components/Text';


type Props = {
  path: [string, string] | [string]
  links?: [string, string] | [string]
};

export function Breadcrumbs({
  path, links,
}: Props) {
  return (
    <Container>
      <Label url="/">Портал</Label>
      <RightCircleIcon />
      <Label url={links && links[0]}>{path[0]}</Label>
      {
        path.length > 1 && (
          <>
            <RightCircleIcon />
            <Label url={links && links[1]}>{path[1]}</Label>
          </>
        )
      }
    </Container>
  );
}

function RightCircleIcon() {
  return (
    <RightCircleOutlined
      style={{ color: 'var(--text-gray)' }}
    />
  );
}

const Text = styled(TextC)`
`;

function Label({ url, ...rest }: any) {
  return (
    <Link to={url}>
      <Text {...rest} color="thirdly" />
    </Link>
  );
}

const Container = styled.div`
  padding: 20px 0 40px;
  display: flex;
  align-items: center;
  gap: 12px;
`;
