import React from 'react';
import { styled } from '@linaria/react';

import { Title as TitleC } from 'components/Title';
import warningIcon from 'assets/icons/warning.svg';


export function NearestActivityNotFound() {
  return (
    <Container>
      <Icon src={warningIcon} alt="" />
      <Info>
        <Title
          level=""
          desktopFont="w800 s30 h40"
          color="white"
        >
          К сожалению, пока у нас нет мероприятий по заданным фильтрам.
        </Title>
        <Description
          level=""
          desktopFont="w500 s18 h24"
          color="white"
        >
          <span>Новые мероприятия добавляются регулярно, а пока обратите внимание на следующие предложения:</span>
        </Description>
      </Info>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  border-radius: 24px;
  background-color: var(--text-primary);
  color: white;
  display: flex;
  gap: 28px;
  overflow: hidden;
  align-items: flex-start;
`;

const Info = styled.div`
`;

const Title = styled(TitleC)`
  padding: 16px 0;
`;

const Description = styled(TitleC)`
  margin-bottom: 32px;
  opacity: 0.7;
  white-space: pre-line;
`;

const Icon = styled.img`
  margin-top: 20px;
  width: 60px;
`;
