import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Button, ButtonProps } from 'antd';

import { Text } from 'components/Text';
import { mn } from 'utils/merge-class-names';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';


type Props = ButtonProps & {
  children: string | number | React.ReactElement
  iconNext?: boolean
  textType?: 'buttons' | 'captions'
  textColor?: 'primary' | 'secondary' | 'thirdly' | 'white' | 'brand'
};

// "link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined
function EMOPButton(props: Props) {
  const {
    className: cn,
    children,
    iconNext,
    textType = 'buttons',
    textColor,
    ...rest
  } = props;

  return (
    <Button
      className={mn([button], cn)}
      {...rest}
    >
      <Text type={textType} color={textColor}>{children}</Text>
      {
        iconNext && <Icon><ArrowIcon /></Icon>
      }
    </Button>
  );
}

const Icon = styled.span`
  width: 20px;
  height: 20px;
`;

const button = css`
  &.ant-btn {
    height: initial;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:not(.ant-btn-link) {
    padding: 12px 24px;
    border-radius: 4px;
    border-color: var(--brand-primary);
  }

  &:not(.ant-btn-primary) {
    color: var(--brand-primary);
  }

  &.ant-btn-link {
    padding: 0 0 0 8px;
  }

  &.ant-btn-primary {
    background: linear-gradient(
      94.25deg, #007BE0 -0.43%, #0058A0 54.69%
    );
  }

  &:not(:hover) {
    ${Icon} {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;

    ${Icon} {
      display: inline-block;
    }
  }
`;

export {
  EMOPButton as Button,
  button as ButtonCSS,
};
export type { ButtonProps };
