import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import { CatalogAppBlock } from './CatalogAppBlock';
import type { CatalogAppBlockProps } from './CatalogAppBlock';


type BlockProps = CatalogAppBlockProps & {
  _id?: string
};

function CatalogAppList({ list }: { list?: BlockProps[] }) {
  return (
    <List>
      {
        Array.isArray(list) && (
          list.map((item) => (
            <CatalogAppBlock {...item} key={item._id} />
          ))
        )
      }
    </List>
  );
}

const List = styled.div`
  display: flex;

  @media ${device.mobile}, ${device.minMobile} {
    gap: 16px;
  }

  @media ${device.tablet} {
    gap: 20px;
  }

  @media ${device.desktopS} {
    gap: 32px;
  }
`;

export { CatalogAppList };
