import React from 'react';
import { css, CSSProperties } from '@linaria/core';
import { styled } from '@linaria/react';
import { Input, InputProps } from 'antd';


type Props = InputProps & {
  label?: string
  required?: boolean
  className?: string
  style?: CSSProperties
};

function EMOPInput({
  label, required, children, className, style, ...rest
}: Props) {
  return (
    <Container className={className} style={style}>
      { label && (
        <Label
        // @ts-ignore
          required={required}
        >{label}
        </Label>
      )}
      <Input {...rest} className={inputCss}>{children}</Input>
    </Container>
  );
}

const Label = styled.div`
  &[required] {
    ::after {
      content: '*';
      margin-left: 16px;
    }
  }
`;

const inputCss = css``;

const Container = styled.div``;

export {
  EMOPInput as Input,
  Container as InputStyled,
  inputCss,
};
