import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ISystem } from 'models/ISystem';

import { PORTAL_API } from '../config/config.models';


interface QueryProps {
  limit?: number;
  offset?: number;
  sort?: string;
}

export const systemService = createApi({
  reducerPath: 'systemService',
  baseQuery: fetchBaseQuery({ baseUrl: PORTAL_API }),
  tagTypes: ['System'],
  endpoints: (build) => ({
    fetchAllSystem: build.query<ISystem, QueryProps>({
      query: ({ limit = 100, offset = 0, sort = '-created_at' }) => ({
        url: '/system/all',
        params: {
          limit,
          offset,
          sort,
        },
      }),
      providesTags: () => ['System'],
    }),
  }),
});
