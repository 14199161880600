import React from 'react';
import { styled } from '@linaria/react';

import { device } from 'styles/devices';

import { ViewFooterCredentials } from './ViewFooterCredentials';
import { ViewFooterInfo } from './ViewFooterInfo';


function ViewFooter() {
  return (
    <Wrapper>
      <Container>
        <ViewFooterCredentials />
        <ViewFooterInfo />
      </Container>
      <Floor>
        <Link href="/docs/politicacookies.pdf" target="_blank">
          <Copy>
            Мы используем файлы cookie, для персонализации сервисов{' '}
            и повышения удобства пользования сайтом.{'\n'}
            Если вы не согласны на их использование,{' '}
            поменяйте настройки браузера.
          </Copy>
        </Link>
        <span>
          © Университет Иннополис, 2023
        </span>
      </Floor>
    </Wrapper>
  );
}

const Link = styled.a`
  color: unset;

  :hover {
    color: unset;
  }
`;

const Copy = styled.span`
  white-space: pre-line;
`;

const Floor = styled.div`
  /* display: flex; */
  opacity: 0.32;
  padding-bottom: 30px;
  font-size: 14px;

  @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    display: none;
  }

  @media ${device.desktopM} {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* gap: 375px; */
    gap: 475px;
  }
`;

const Wrapper = styled.div`
  background-color: var(--text-primary);
  color: white;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  /* background-color: var(--text-primary); */
  /* color: white; */
  display: flex;
  overflow-x: hidden;

  /* @media ${device.mobile}, ${device.tablet}, ${device.minMobile} {
    flex-direction: column;
  } */

  @media ${device.mobile}, ${device.minMobile} {
    flex-direction: column;
    padding: 20px 30px 16px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    padding: 30px 60px;
    /* gap: 30px; */
    justify-content: space-between;
  }

  @media ${device.desktopM} {
    /* padding: 68px 9.896vw; */
    flex-direction: row;
    /* gap: 6.667vw; */
    justify-content: center;
    gap: 82px;
  }
`;

export { ViewFooter };
