// const MAX_POS = 70; // TODO: calculate showcase block to pos + screen vw
// const STEP = 2; // vw
// const STEP = 32; // px ok
// const MAX_DISTANCE = block number * width + (-margin-right);

// TODO: refactor to useReducer

const BLOCK_WIDTH_DESKTOP = 518;
// const BLOCK_WIDTH_TABLET = 440;
// const BLOCK_WIDTH_MOBILE = 300;

const BLOCKS_COUNT = 4;
const BLOCKS_TOTAL = (BLOCK_WIDTH_DESKTOP * BLOCKS_COUNT);
const BLOCKS_VISIBLE_ON_VIEWPORT = 3.5;
const BLOCK_GAP = 32;
const GAP_TOTAL = (BLOCK_GAP * BLOCKS_COUNT) - BLOCK_GAP;
const OVERFLOW_BOUNDARY = 190;
// const INITIAL_DISPLAY_COUNTAINER_WIDTH = BLOCKS_TOTAL + GAP_TOTAL;

const INITIAL_DISPLAY_COUNTAINER_WIDTH = BLOCK_WIDTH_DESKTOP
  * BLOCKS_VISIBLE_ON_VIEWPORT
  + GAP_TOTAL;

const MAX_DISTANCE = BLOCKS_TOTAL
  + GAP_TOTAL
  + OVERFLOW_BOUNDARY
  - INITIAL_DISPLAY_COUNTAINER_WIDTH;

// const INITIAL_POS = -30;
const INITIAL_POS = 0;
const SPEED = 40; // px ok

/**
 * calculate slider total width based on items count
 *
 * @param count elements
 */
export function calculateDistance(count = 0) {
  const blocksTotal = (BLOCK_WIDTH_DESKTOP * count);

  const gapTotal = (BLOCK_GAP * count) - BLOCK_GAP;

  const initialDisplayContainerWidth = BLOCK_WIDTH_DESKTOP
    * BLOCKS_VISIBLE_ON_VIEWPORT
    + gapTotal;

  const maxDistance = blocksTotal
    + gapTotal
    + OVERFLOW_BOUNDARY
    - initialDisplayContainerWidth;

  return maxDistance;
}

export {
  INITIAL_POS,
  SPEED,
  MAX_DISTANCE,
};
