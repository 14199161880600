import React, { useState } from 'react';
import { styled } from '@linaria/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { HelpBlock } from 'components/HelpBlock';
import { Layout } from 'components/layout/Layout';
import { UniversityBlock } from 'components/UniversityBlock';
import { device } from 'styles/devices';

import { KnowledgeBaseHeader } from '../components/knowledge-base-header/KnowledgeBaseHeader';
import { KnowledgeBaseSystem } from '../components/knowledge-base-header/KnowledgeBaseSystems';
import { KnowledgeSearchResult } from '../components/knowledge-base-search/KnowledgeSearchResult';
import { questionService } from '../stores/question/questionService';
import { systemService } from '../stores/system/systemService';


export function KnowledgeBase() {
  const [search, setSearch] = useState<string>('');
  const { data: dataSystem, isLoading: isLoadingSystem } = systemService.useFetchAllSystemQuery({});
  const {
    data: dataQuestion,
    isLoading: isLoadingQuestion,
  } = questionService.useFetchAllQuestionQuery({ question: search });
  const [moreResult, setMoreResult] = useState<boolean>(false);

  if (isLoadingSystem) {
    return null;
  }

  if (isLoadingQuestion) {
    return null;
  }

  const seeMoreResult = (value: boolean): void => {
    setMoreResult(value);
  };

  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['База знаний']}
        />
        <Content>
          <SearchBlock>
            <KnowledgeBaseHeader searchValue={search} searchChange={setSearch} />
            {search && !moreResult
              && (
                <KnowledgeSearchResult
                  setSingleSearch={setSearch}
                  onClick={seeMoreResult}
                  data={dataQuestion?.items}
                />
              )}
          </SearchBlock>
          {dataSystem?.items && dataQuestion?.items
          && (
            <KnowledgeBaseSystem
              setSearch={setSearch}
              search={search}
              onClick={setMoreResult}
              moreResult={moreResult}
              dataQuestions={dataQuestion.items}
              defaultId={dataSystem.items[2]._id}
              defaultData={dataSystem.items[2]}
            />
          )}
          <HelpBlock title="Связаться с нами" />
          <UniversityBlock />
        </Content>
      </Container>
    </Layout>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 112px;
`;

const SearchBlock = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   position: relative;
`;

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
  }

  @media ${device.tablet} {
  }
  
  @media ${device.desktopS} {
    padding: 0 190px 148px;
    display: flex;
    flex-direction: column;
    gap: 92px;
  }
`;
