import React from 'react';
import { styled } from '@linaria/react';
import { Space } from 'antd';

import { Button } from 'components/common/Button';
import { Heading } from 'components/Heading';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';
import bgStudy from 'assets/images/general-info/bg-study.svg';

import { GeneralInfoCardList } from './GeneralInfoCardList';


export function GeneralInfo() {
  const { isMobile } = useBreakpoints();

  return (
    <Container>
      <MainCard
        style={!isMobile ? { backgroundImage: `url(${bgStudy})` } : undefined}
      >
        <Heading level={2}>Корпоративные программы обучения</Heading>
        <p>
          Университет Иннополис развивает не только технологии и ИТ-сферу, но и
          бизнес. Мы научим сотрудников идти в ногу со временем, стать сильнее в
          основной профессии и смежных направлениях. также разработаем
          уникальные программы обучения под потребности бизнеса и проведем
          обучение по ним
        </p>
        <Space
          size="middle"
          direction={isMobile ? 'vertical' : 'horizontal'}
          style={isMobile ? { display: 'flex' } : undefined}
        >
          <Button type="primary" block textColor="white">
            Познакомиться с программами
          </Button>
          <Button block textColor="brand">Узнать больше</Button>
        </Space>
      </MainCard>
      <GeneralInfoCardList />
    </Container>
  );
}

const MainCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0, 113, 206, 0.05);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  color: var(--text-primary);
  padding: 16px;
  @media ${device.tablet} {
    padding: 28px;
  }
  @media ${device.desktopS} {
    padding: 32px 100px 32px 32px;
  }
  .ant-btn {
    justify-content: center;
  }
`;

const Container = styled.div`
  gap: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.desktopS} {
    grid-template-areas:
      "top top right"
      "bot-1 bot-2 right";
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    
    div {
      grid-area: top;

      &:nth-child(2) {
        grid-area: right;
      }
      &:nth-child(3) {
        grid-area: bot-1;
      }
      &:nth-child(4) {
        grid-area: bot-2;
      }
    }
  }
`;
