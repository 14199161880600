import React from 'react';
import { styled } from '@linaria/react';
import { useParams } from 'react-router-dom';

import { ActivityData } from 'components/activity/ActivityData';
import { ActivityInfo } from 'components/activity/ActivityInfo';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Layout } from 'components/layout/Layout';
import {
  SimilarActivities as SimilarActivitiesC,
} from 'components/similar-activities/SimilarActivities';
import { IActivity } from 'models/IActivity';
import { activityService } from 'stores/activity/activityService';
import { device } from 'styles/devices';


export function Activity() {
  const { id } = useParams();
  const { data: activity, isLoading } = activityService.useGetActivityByIdQuery(id as string);

  if (isLoading && !activity) {
    return null;
  }

  const {
    title,
    full_description,
    tags,
    start_date,
    end_date,
    price,
    price_visible,
    place,
    speakers,
    files,
    similar_activity,
    desktop_cover,
    created_at,
    link_to_entry,
  } = activity as IActivity;

  return (
    <Layout>
      <Container>
        <Breadcrumbs
          path={['Мероприятия', title]}
          links={['/activity']}
        />
        <Content>
          <ActivityData
            banner={desktop_cover}
            title={title}
            text={full_description}
            tags={tags}
          />
          <ActivityInfo
            pubDate={created_at}
            start_date={start_date}
            end_date={end_date}
            price={price}
            price_visible={price_visible}
            place={place}
            speakers={speakers}
            files={files}
            link_to_entry={link_to_entry}
          />
        </Content>
        {similar_activity.length > 0
          && (
            <SimilarActivities
              title="Похожие события"
              list={similar_activity}
            />
          )}
      </Container>
    </Layout>
  );
}

const Content = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 100px;
`;

const SimilarActivities = styled(SimilarActivitiesC)`
  margin-bottom: 100px;
`;

const Container = styled.div`
  background-color: #F5F6F8;
  
  @media ${device.mobile} {
  }

  @media ${device.tablet} {
  }
  
  @media ${device.desktopS} {
    display: flex;
    flex-direction: column;
    padding: 0 190px;
  }
`;
