import React from 'react';
import { styled } from '@linaria/react';

import Union from 'assets/particles/Union.svg';


type Props = {
  top?: string
  left?: string
  right?: string
  bottom?: string
};

function HollowCross(props: Props) {
  return (
    <Span {...props}>
      <img src={Union} alt="" />
    </Span>
  );
}

export { HollowCross };

const Span = styled.span<Props>`
  display: inline-block;
  position: absolute;
  top: ${({ top }) => top || ''};
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
`;
