import React from 'react';
import { css } from '@linaria/core';

import { CommonCarousel }
  from 'components/common/common-carousel/CommonCarousel';
import { device } from 'styles/devices';
import { useBreakpoints } from 'utils/useBreakpoints';

import data from './data.json';
import { GeneralInfoCard } from './GeneralInfoCard';


export function GeneralInfoCardList() {
  const { isMobile, isDesktop } = useBreakpoints();

  const cards = data.map((item) => (
    <GeneralInfoCard key={item.id} {...item} />
  ));

  return !isDesktop ? (
    <CommonCarousel
      items={cards}
      slidesPerRow={1}
      slidesToShow={isMobile ? 1 : 2.5}
      dots
      className={generalInfoCarousel}
      infinite
      showProgress={false}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{cards}</>
  );
}

const generalInfoCarousel = css`
  padding: 16px;
  @media ${device.tablet} {
    padding: unset;
  }
`;
