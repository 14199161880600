import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { activityService } from './activity/activityService';
import { commonReducer } from './common/commonSlice';
import { courseService } from './course/courseService';
import { eventAPI } from './event/eventService';
import { newsService } from './news/newsService';
import { questionService } from './question/questionService';
import { sectionService } from './section/sectionService';
import { softwareService } from './software/softwareService';
import { systemService } from './system/systemService';


const middlewares: any[] = [
  newsService.middleware,
  activityService.middleware,
  courseService.middleware,
  softwareService.middleware,
  systemService.middleware,
  sectionService.middleware,
  questionService.middleware,
  eventAPI.middleware,
];

/* log only every 10-th action */
// let counterThrottle = 0;
// const throttleTimes = 5;
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed: (getState: any,
      action: any,
      logEntry: { error: any; }) => !logEntry.error,
    diff: true,
    duration: true,
    predicate: () => {
      // counterThrottle += 1;
      // return counterThrottle % throttleTimes === 0;
    },
  });

  middlewares.push(logger);
}


const rootReducer = combineReducers({
  common: commonReducer,
  [newsService.reducerPath]: newsService.reducer,
  [activityService.reducerPath]: activityService.reducer,
  [courseService.reducerPath]: courseService.reducer,
  [softwareService.reducerPath]: softwareService.reducer,
  [systemService.reducerPath]: systemService.reducer,
  [sectionService.reducerPath]: sectionService.reducer,
  [questionService.reducerPath]: questionService.reducer,
  [eventAPI.reducerPath]: eventAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});


export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureStore>;
export type AppDispatch = AppStore['dispatch'];

